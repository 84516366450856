import UserPermission from "@common/access";

export default [
    {
        path: "/product-profiles",
        name: "ProductProfiles",
        component: () =>
            import(/* webpackChunkName: "product-profiles" */ "../views/administration/ProductProfiles/ProductProfiles.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/product-profiles/:id?",
        name: "ProductProfilesId",
        component: () =>
            import(/* webpackChunkName: "product-profiles" */ "../views/administration/ProductProfiles/ProductProfiles.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/product-profiles/edit/:id",
        name: "ProductProfilesEdit",
        component: () =>
            import(/* webpackChunkName: "product-profiles" */ "../views/administration/ProductProfiles/ProductProfilesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/product-profiles/add",
        name: "ProductProfilesInsert",
        component: () =>
            import(/* webpackChunkName: "product-profiles" */ "../views/administration/ProductProfiles/ProductProfilesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/product-attributes-gloss",
        name: "ProductGlossAttributes",
        component: () =>
            import(/* webpackChunkName: "product-attributes" */ "../views/administration/ProductAttributes/ProductGlossAttributes.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/product-attributes-gloss/edit/:id",
        name: "ProductGlossAttributesEdit",
        component: () =>
            import(/* webpackChunkName: "product-attributes" */ "../views/administration/ProductAttributes/ProductGlossAttributesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/product-attributes-gloss/add",
        name: "ProductGlossAttributesInsert",
        component: () =>
            import(/* webpackChunkName: "product-attributes" */ "../views/administration/ProductAttributes/ProductGlossAttributesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/product-attributes-class",
        name: "ProductClassAttributes",
        component: () =>
            import(/* webpackChunkName: "product-attributes" */ "../views/administration/ProductAttributes/ProductClassAttributes.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/product-attributes-class/edit/:id",
        name: "ProductClassAttributesEdit",
        component: () =>
            import(/* webpackChunkName: "product-attributes" */ "../views/administration/ProductAttributes/ProductClassAttributesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/product-attributes-class/add",
        name: "ProductClassAttributesInsert",
        component: () =>
            import(/* webpackChunkName: "product-attributes" */ "../views/administration/ProductAttributes/ProductClassAttributesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },

    {
        path: "/product-attributes",
        name: "ProductAttributes",
        component: () =>
            import(/* webpackChunkName: "product-attributes" */ "../views/administration/ProductAttributes/ProductAttributes.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/product-attributes/edit/:id",
        name: "ProductAttributesEdit",
        component: () =>
            import(/* webpackChunkName: "product-attributes" */ "../views/administration/ProductAttributes/ProductAttributesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/product-attributes/add",
        name: "ProductAttributesInsert",
        component: () =>
            import(/* webpackChunkName: "product-attributes" */ "../views/administration/ProductAttributes/ProductAttributesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },

    {
        path: "/product-states",
        name: "ProductStates",
        component: () =>
            import(/* webpackChunkName: "product-states" */ "../views/administration/Products/ProductStates.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/product-states/edit/:id",
        name: "ProductStatesEdit",
        component: () =>
            import(/* webpackChunkName: "product-states" */ "../views/administration/Products/ProductStatesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/product-states/add",
        name: "ProductStatesInsert",
        component: () =>
            import(/* webpackChunkName: "product-states" */ "../views/administration/Products/ProductStatesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },

    {
        path: "/product-types",
        name: "ProductTypes",
        component: () =>
            import(/* webpackChunkName: "product-types" */ "../views/administration/ProductTypes/ProductTypes.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/product-types/edit/:id",
        name: "ProductTypesEdit",
        component: () =>
            import(/* webpackChunkName: "product-types" */ "../views/administration/ProductTypes/ProductTypesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/product-types/add",
        name: "ProductTypesInsert",
        component: () =>
            import(/* webpackChunkName: "product-types" */ "../views/administration/ProductTypes/ProductTypesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },

    {
        path: "/products",
        name: "Products",
        component: () =>
            import(/* webpackChunkName: "products" */ "../views/administration/Products/Products.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.PRODUCTS_READ,
        }
    },
    {
        path: "/products/edit/:id",
        name: "ProductsEdit",
        component: () =>
            import(/* webpackChunkName: "products" */ "../views/administration/Products/ProductsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.PRODUCTS_WRITE,
        }
    },
    {
        path: "/products/add",
        name: "ProductsInsert",
        component: () =>
            import(/* webpackChunkName: "products" */ "../views/administration/Products/ProductsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.PRODUCTS_WRITE,
        },
    },

    {
        path: "/producers",
        name: "Producers",
        component: () =>
            import(/* webpackChunkName: "producers" */ "../views/administration/Producers/Producers.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/producers/edit/:id",
        name: "ProducersEdit",
        component: () =>
            import(/* webpackChunkName: "producers" */ "../views/administration/Producers/ProducersEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/producers/add",
        name: "ProducersInsert",
        component: () =>
            import(/* webpackChunkName: "producers" */ "../views/administration/Producers/ProducersEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        },
    },

    {
        path: "/product-categories",
        name: "ProductCategories",
        component: () =>
            import(/* webpackChunkName: "product-categories" */ "../views/administration/ProductCategories/ProductCategories.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/product-categories/edit/:id",
        name: "ProductCategoriesEdit",
        component: () =>
            import(/* webpackChunkName: "product-categories" */ "../views/administration/ProductCategories/ProductCategoriesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/product-categories/add",
        name: "ProductCategoriesInsert",
        component: () =>
            import(/* webpackChunkName: "product-categories" */ "../views/administration/ProductCategories/ProductCategoriesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        },
    },
    {
        path: "/profile-groups",
        name: "ProfileGroups",
        component: () =>
            import(/* webpackChunkName: "profile-groups" */ "../views/administration/ProfileGroups/ProfileGroups.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/profile-groups/edit/:id",
        name: "ProfileGroupsEdit",
        component: () =>
            import(/* webpackChunkName: "profile-groups" */ "../views/administration/ProfileGroups/ProfileGroupsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/profile-groups/add",
        name: "ProfileGroupsInsert",
        component: () =>
            import(/* webpackChunkName: "profile-groups" */ "../views/administration/ProfileGroups/ProfileGroupsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
];