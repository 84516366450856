
    import {Component} from "vue-property-decorator";
    import BaseEDITView from "@/views/base/BaseEDITView.vue";
    import CrmEventSelect from "../../../components/select/CrmEventSelect.vue";
    import CustomerSelect from "@/components/select/CustomerSelect.vue";
    import PageEditTitle from "@/components/PageEditTitle.vue";

    @Component({
        components: {
            CustomerSelect,
          PageEditTitle,
            CrmEventSelect
        },
    })
    export default class CrmEdit extends BaseEDITView {
      protected editOptions = {
        editRoute: 'CrmEventsEdit',
        listRoute: 'CrmEvents'
      };

        private fCrm = {} as ICrm;

        public formRules = {
            customerId: [{ required: true, message: this.$t('Crm.ErrInputCustomer').toString(), trigger: 'blur' }],
            crmEventId: [{ required: true, message: this.$t('Crm.ErrInputEvent').toString(), trigger: 'blur' }],
            note: [{ required: false, min: 0, max: 255, message: this.$t('Crm.ErrInputNote').toString(), trigger: 'blur' }],
            date: [{ required: true, message: this.$t('Crm.ErrInputDate').toString(), trigger: 'blur' }],
        };

        public get crm(): ICrm {
            return this.fCrm;
        }

        protected loadData(data: ICrm): void {
            this.fCrm = data;
        }

        protected getDefaultData(): any {
            const defData: ICrm = {
                customerId: this.parentId,
                date: new Date()
            } as ICrm;
            defData.date.setSeconds(0);
            const iMin = defData.date.getMinutes();
            defData.date.setMinutes(Math.trunc(iMin / 15) * 15);
            return defData;
        }

        protected getPostData(): any {
            return {
                customerId: this.parentId ?? this.fCrm.customerId,
                crmEventId: this.fCrm.crmEventId,
                date: this.fCrm.date,
                note: this.fCrm.note,
            };
        }

        public created(): void {
            this.dataEndpoint = 'crm';
            this.pageTitle = this.$t('Crm.InsertPageTitle').toString();
        }

        protected getAfterSaveRoute(): string {
            return 'Crm';
        }
    }

