
    import { Component } from "vue-property-decorator";
    import DashboardBase, {IDashboardRow} from "@/components/dashboards/DashboardBase.vue";

    @Component({
        components: {
        },
    })
    export default class EmployeeDashboard extends DashboardBase {
      protected showChangeLog: boolean = false;

      protected getDefaultStaticLayout(): string[] {
        return ['BoxAlerts', 'BoxDate'];
      }

      protected getDefaultStaticLayoutAfter(): string[] {
        return ['BoxWorkCards'];
      }

      protected getDefaultDynamicLayout(): IDashboardRow[] {
        return [
          { id: 1, elements: [] },
          { id: 2, elements: [] },
          { id: 3, elements: ['BoxStockAlert', 'BoxTodos', 'BoxNotifications'] },
          { id: 4, elements: [] },
        ];
      }
    }
