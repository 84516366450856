
import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class Hint extends Vue {

  @Prop({
    default: 0,
    required: false,
  })
  protected minWidth: number;

  private get textStyles(): any {
    return  this.minWidth > 0 ? { 'min-width': this.minWidth + 'px' } : {};
  }
}

