
    import { Component, Prop, Vue } from "vue-property-decorator";
    import FilterableSelect from "@/components/custom/FilterableSelect.vue";
    import {storageService} from "@/services/service-container";

    @Component({
        components: {
            FilterableSelect
        },
    })
    export default class PlaceSelect extends Vue {
        @Prop({ default: null })
        public value: number;

        @Prop({ default: false })
        public isDisabled: boolean;

        @Prop({ default: true })
        public isClearable: boolean;

        @Prop({ default: false })
        private chooseFirst: boolean;

        @Prop({ default: false })
        public filterMode: boolean;
        public filterModeInt: boolean = false;

        private getFilterMode(): boolean {
          return this.filterModeInt || this.filterMode;
        }

        public dataLoaded(data: ISearchItem[]): void {
          if ((this.value === 0 || this.value === null) && data.length && this.chooseFirst) {
            this.placeChanged(data[0].id);
          }
        }

        public placeChanged(id: number): void {
            this.$emit('input', id);
            this.$emit('change', id);
        }

        public saveFilters(filterKey: string): void {
            if (this.value ?? 0 > 0) {
              storageService.setItem(`${filterKey}_flt_placeId`, this.value.toString());
            } else {
              storageService.removeItem(`${filterKey}_flt_placeId`);
            }
        }

        public loadFilters(filterKey: string): void {
            this.filterModeInt = true;
            const str = storageService.getItem(`${filterKey}_flt_placeId`);
            if (str.length) {
                this.$emit('input', parseInt(str, 10));
            }
        }

        public clearFilter(): void {
            this.$emit('input', null);
        }
    }
