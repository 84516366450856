
    import { Component } from "vue-property-decorator";
    import DashboardBase, {IDashboardRow} from "@/components/dashboards/DashboardBase.vue";

    @Component({
        components: {},
    })
    export default class AdminDashboard extends DashboardBase {

        protected getDefaultStaticLayout(): string[] {
          return ['BoxAlerts'];
        }

        protected getDefaultDynamicLayout(): IDashboardRow[] {
          return [
            { id: 1, elements: ['BoxDate'] },
            { id: 2, elements: [
                'BoxCountersNewOrders',
                'BoxCountersNewOrderItems',
                'BoxCountersInvoiceCount',
                'BoxCountersInvoiceSum'
              ]
            },
            { id: 3, elements: ['BoxCurrencyRate','BoxNewUsers', 'BoxNewOrders', 'BoxStockAlert'] },
            { id: 4, elements: ['BoxOrdersGraph', 'BoxOrdersIncGraph', 'BoxTodos', 'BoxNotifications'] },
            { id: 5, elements: [] },
            { id: 6, elements: [] }
          ];
        }

    }
