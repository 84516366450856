
import {Component, Vue} from "vue-property-decorator";
import {Configuration} from "@/common/config";
import {getImgUrl} from "@/common/functions";
import {dialogService, serviceDataStore} from "@/services/service-container";
import changeData from "@/utils/changelog.json";
import menuConfigJson from "@/utils/menu-config.json";
import MenuItem from "@/components/Menu/MenuItem.vue";
import MenuContainer from "@/components/Menu/MenuContainer.vue";
import MenuSeparator from "@/components/Menu/MenuSeparator.vue";
import MenuFooter from "@/components/Menu/MenuFooter.vue";
import ZebraStatus from "@/components/custom/ZebraStatus.vue";
import UserPermission, {accessModule} from "@common/access";
import ChatStatus from "@/components/custom/ChatStatus.vue";

@Component({
  components: {
    ChatStatus,
    MenuSeparator,
    MenuItem,
    MenuFooter,
    MenuContainer,
    ZebraStatus
  }
})
export default class Menu extends Vue {

  private userBgs: string[] = ['url(/userback01.jpg)'];
  private fMenuItems: IMenuConfig[] = [];
  private menuFilter: string = '';
  private hasSms: boolean = false;

  private get changes(): IChangeLog[] {
    return changeData;
  }

  private get version(): string {
    return this.changes[0].version;
  }

  private get buildNumber(): string {
    const meta = document.querySelector('meta[build-timestamp-utc]');
    const buildDate = meta ? meta.getAttribute('build-timestamp-utc') : '';
    const buildNum = buildDate ? buildDate
            .replaceAll('-', '')
            .replaceAll(':', '')
            .replaceAll('.', '')
            .replaceAll('Z', '')
            .replaceAll('T', '') : '';
    return `v${buildNum}`;
  }

  public get isCustomer(): boolean {
    return serviceDataStore.isCustomer;
  }

  private smsSend(): void {
    if (!this.hasSms || !dialogService.smsDlg) {
      return;
    }
    dialogService.smsDlg.open('', '');
  }

  mounted() {
    this.hasSms = this.$store.state.smsEnabled
        && serviceDataStore.hasRight(UserPermission.SMS_WRITE);
    menuConfigJson.forEach((item: IMenuConfig) => {
      // klienta czy all
      const isType = ((typeof item.isCustomer === 'undefined') || (item.isCustomer === 2) || ((item.isCustomer === 0) && !this.isCustomer) || ((item.isCustomer === 1) && this.isCustomer));
      // czy jest uprawnienie
      item.hasRight = Boolean(isType && ((item.isSubMenu && !item.access) || (item.access && this.hasModuleAccess(item) && this.hasRight(item.access ?? ''))));

      item.langStr = this.$t(item.langId).toString();

      if (item.isSubMenu) {
        item.items?.forEach((subItem: IMenuConfig) => {
          const isType = ((typeof subItem.isCustomer === 'undefined') || (subItem.isCustomer === 2) || ((subItem.isCustomer === 0) && !this.isCustomer) || ((subItem.isCustomer === 1) && this.isCustomer));
          subItem.hasRight = isType && (!subItem.access || this.hasRight(subItem.access ?? ''));
          subItem.langStr = this.$t(subItem.langId).toString();
        });

        item.items = item.items?.filter((subItem: IMenuConfig) => {
          return subItem.hasRight;
        });
        item.hasRight = item.hasRight && (item.items.length > 0);
      }
    });

    const tempItems = menuConfigJson.filter((item) => {
      return item.hasRight;
    });

    this.fMenuItems = tempItems;
  }

  private isMenuContainerVisible(item: IMenuConfig): boolean {
    return item.items && item.items.some((item) => {
      return this.isMenuVisible(item);
    });
  }

  private isMenuVisible(item: IMenuConfig): boolean {
    return (item.hasRight && (!this.menuFilter.trim().length || (item.langStr && item.langStr.toLowerCase()?.includes(this.menuFilter.toLowerCase()))));
  }

  private get userName(): string {
    return serviceDataStore.getUserName();
  }

  private get userBgImage(): string {
    return this.userBgs[0];
  }

  private get avatarImg(): string {
    const user: ILoginUserData | null = serviceDataStore.getUserData();
    if (user && (user.avatar || '' !== '')) {
      return Configuration.serverAddress + user.avatar;
    }
    return getImgUrl('users/avatar-m.jpg');
  }

  public hasRight(right: any): boolean {
    if (Array.isArray(right)) {
      return serviceDataStore.hasAnyRights(right);
    } else {
      return serviceDataStore.hasRight(right);
    }
  }

  private hasModuleAccess(access: IMenuConfig): boolean {
    return (access.modules && serviceDataStore.isAnyModuleEnabled(access.modules))
        || (!access.modules && serviceDataStore.isModuleEnabled(accessModule(access.access)));
  }

}
