enum UserPermission {
    // all access rights
    SUPER_USER = 'SUPER_USER',
    ADMIN_USER = 'ADMIN_USER',

    DATA_IMPORT = 'DATA_IMPORT',

    // uzytkownicy
    USERS_READ = 'USERS_READ',
    USERS_WRITE = 'USERS_WRITE',
    USERS_DELETE = 'USERS_DELETE',
    USERS_EXPORT = 'USERS_EXPORT',

    ROLES_READ = 'ROLES_READ',
    ROLES_WRITE = 'ROLES_WRITE',
    ROLES_DELETE = 'ROLES_DELETE',
    ROLES_EXPORT = 'ROLES_EXPORT',

    // uzytkownicy - notatki
    USERS_NOTES = 'USERS_NOTES',

    // uprawnienia
    USERS_RIGHTS = 'USERS_RIGHTS',

    // logi
    LOGS_READ = 'LOGS_READ',
    LOGS_EXPORT = 'LOGS_EXPORT',

    // maillogs
    MAILLOGS_READ = 'MAILLOGS_READ',
    MAILLOGS_EXPORT = 'MAILLOGS_EXPORT',

    // ustawienia
    SETTINGS_READ_WRITE = 'SETTINGS_READ_WRITE',

    // todos
    TODOS_READ_WRITE = 'TODOS_READ_WRITE',

    // wiadomosci
    MESSAGES_READ_WRITE = 'MESSAGES_READ_WRITE',

    // stock
    STOCK_READ = 'STOCK_READ',
    STOCK_WRITE = 'STOCK_WRITE',
    STOCK_DELETE = 'STOCK_DELETE',
    STOCK_IMPORT = 'STOCK_IMPORT',
    STOCK_EXPORT = 'STOCK_EXPORT',
    STOCK_CHANGE = 'STOCK_CHANGE',

    // stock lend
    STOCK_LEND_READ = 'STOCK_LEND_READ',
    STOCK_LEND_WRITE = 'STOCK_LEND_WRITE',
    STOCK_LEND_DELETE = 'STOCK_LEND_DELETE',
    STOCK_LEND_IMPORT = 'STOCK_LEND_IMPORT',
    STOCK_LEND_EXPORT = 'STOCK_LEND_EXPORT',

    // stock limits
    STOCK_LIMITS_READ = 'STOCK_LIMITS_READ',
    STOCK_LIMITS_WRITE = 'STOCK_LIMITS_WRITE',
    STOCK_LIMITS_DELETE = 'STOCK_LIMITS_DELETE',
    STOCK_LIMITS_EXPORT = 'STOCK_LIMITS_EXPORT',

    // receptions
    RECEPTION_READ = 'RECEPTION_READ',
    RECEPTION_WRITE = 'RECEPTION_WRITE',
    RECEPTION_DELETE = 'RECEPTION_DELETE',
    RECEPTION_EXPORT = 'RECEPTION_EXPORT',

    // invoices
    INVOICES_READ = 'INVOICES_READ',
    INVOICES_WRITE = 'INVOICES_WRITE',
    INVOICES_DELETE = 'INVOICES_DELETE',
    INVOICES_EXPORT = 'INVOICES_EXPORT',

    // customers
    CUSTOMERS_READ = 'CUSTOMERS_READ',
    CUSTOMERS_WRITE = 'CUSTOMERS_WRITE',
    CUSTOMERS_DELETE = 'CUSTOMERS_DELETE',
    CUSTOMERS_EXPORT = 'CUSTOMERS_EXPORT',

    CUSTOMER_CRM_READ = 'CUSTOMER_CRM_READ',
    CUSTOMER_CRM_WRITE = 'CUSTOMER_CRM_WRITE',
    CUSTOMER_CRM_DELETE = 'CUSTOMER_CRM_DELETE',
    CUSTOMER_CRM_EXPORT = 'CUSTOMER_CRM_EXPORT',

    // orders
    ORDERS_READ = 'ORDERS_READ',
    ORDERS_WRITE = 'ORDERS_WRITE',
    ORDERS_DELETE = 'ORDERS_DELETE',
    ORDERS_EXPORT = 'ORDERS_EXPORT',

    // products
    PRODUCTS_READ = 'PRODUCTS_READ',
    PRODUCTS_WRITE = 'PRODUCTS_WRITE',
    PRODUCTS_DELETE = 'PRODUCTS_DELETE',
    PRODUCTS_EXPORT = 'PRODUCTS_EXPORT',

    // price list
    PRICE_LIST_READ = 'PRICE_LIST_READ',
    PRICE_LIST_WRITE = 'PRICE_LIST_WRITE',
    PRICE_LIST_DELETE = 'PRICE_LIST_DELETE',
    PRICE_LIST_EXPORT = 'PRICE_LIST_EXPORT',

    QUOTATIONS_READ = 'QUOTATIONS_READ',
    QUOTATIONS_WRITE = 'QUOTATIONS_WRITE',
    QUOTATIONS_DELETE = 'QUOTATIONS_DELETE',
    QUOTATIONS_EXPORT = 'QUOTATIONS_EXPORT',

    // harmonogram
    SCHEDULER_READ = 'SCHEDULER_READ',
    SCHEDULER_WRITE = 'SCHEDULER_WRITE',

    // karty pracy
    WORK_CARDS_ALL = 'WORK_CARDS_ALL',
    WORK_CARDS_WORK = 'WORK_CARDS_WORK',
    WORK_CARDS_READ = 'WORK_CARDS_READ',
    WORK_CARDS_WRITE = 'WORK_CARDS_WRITE',
    WORK_CARDS_DELETE = 'WORK_CARDS_DELETE',
    WORK_CARDS_EXPORT = 'WORK_CARDS_EXPORT',

    // customer notes
    CUSTOMER_NOTES_READ = 'CUSTOMER_NOTES_READ',
    CUSTOMER_NOTES_WRITE = 'CUSTOMER_NOTES_WRITE',
    CUSTOMER_NOTES_DELETE = 'CUSTOMER_NOTES_DELETE',

    DICTIONARIES_READ = 'DICTIONARIES_READ',
    DICTIONARIES_WRITE = 'DICTIONARIES_WRITE',
    DICTIONARIES_DELETE = 'DICTIONARIES_DELETE',
    DICTIONARIES_EXPORT = 'DICTIONARIES_EXPORT',

    // complaints
    COMPLAINTS_READ = 'COMPLAINTS_READ',
    COMPLAINTS_WRITE = 'COMPLAINTS_WRITE',
    COMPLAINTS_DELETE = 'COMPLAINTS_DELETE',
    COMPLAINTS_EXPORT = 'COMPLAINTS_EXPORT',

    REPORTS = 'REPORTS',
    REPORTS_EXPORT = 'REPORTS_EXPORT',

    // alerts
    ALERTS_READ = 'ALERTS_READ',
    ALERTS_WRITE = 'ALERTS_WRITE',
    ALERTS_DELETE = 'ALERTS_DELETE',
    ALERTS_EXPORT = 'ALERTS_EXPORT',

    // attachments
    ATTACHMENTS_READ = 'ATTACHMENTS_READ',
    ATTACHMENTS_WRITE = 'ATTACHMENTS_WRITE',
    ATTACHMENTS_DELETE = 'ATTACHMENTS_DELETE',
    ATTACHMENTS_EXPORT = 'ATTACHMENTS_EXPORT',

    CARS_READ = 'CARS_READ',
    CARS_WRITE = 'CARS_WRITE',
    CARS_DELETE = 'CARS_DELETE',
    CARS_EXPORT = 'CARS_EXPORT',

    CARS_TRANSIT_READ = 'CARS_TRANSIT_READ',
    CARS_TRANSIT_WRITE = 'CARS_TRANSIT_WRITE',
    CARS_TRANSIT_DELETE = 'CARS_TRANSIT_DELETE',
    CARS_TRANSIT_EXPORT = 'CARS_TRANSIT_EXPORT',

    GUESTS_READ = 'GUESTS_READ',
    GUESTS_WRITE = 'GUESTS_WRITE',
    GUESTS_DELETE = 'GUESTS_DELETE',
    GUESTS_EXPORT = 'GUESTS_EXPORT',
    GUESTS_CHANGE = 'GUESTS_CHANGE',

    BUILDINGS_READ = 'BUILDINGS_READ',
    BUILDINGS_WRITE = 'BUILDINGS_WRITE',
    BUILDINGS_DELETE = 'BUILDINGS_DELETE',
    BUILDINGS_EXPORT = 'BUILDINGS_EXPORT',

    KEYS_READ = 'KEYS_READ',
    KEYS_WRITE = 'KEYS_WRITE',
    KEYS_DELETE = 'KEYS_DELETE',
    KEYS_EXPORT = 'KEYS_EXPORT',
    KEYS_CHANGE = 'KEYS_CHANGE',

    CARD_READ = 'CARD_READ',
    CARD_WRITE = 'CARD_WRITE',
    CARD_DELETE = 'CARD_DELETE',
    CARD_EXPORT = 'CARD_EXPORT',
    CARD_CHANGE = 'CARD_CHANGE',

    USER_WORK_READ = 'USER_WORK_READ',
    USER_WORK_WRITE = 'USER_WORK_WRITE',
    USER_WORK_DELETE = 'USER_WORK_DELETE',
    USER_WORK_EXPORT = 'USER_WORK_EXPORT',

    USER_PAYCHECK_READ = 'USER_PAYCHECK_READ',
    USER_PAYCHECK_WRITE = 'USER_PAYCHECK_WRITE',
    USER_PAYCHECK_DELETE = 'USER_PAYCHECK_DELETE',
    USER_PAYCHECK_EXPORT = 'USER_PAYCHECK_EXPORT',

    LEAVE_CARD_READ = 'LEAVE_CARD_READ',
    LEAVE_CARD_WRITE = 'LEAVE_CARD_WRITE',
    LEAVE_CARD_DELETE = 'LEAVE_CARD_DELETE',
    LEAVE_CARD_EXPORT = 'LEAVE_CARD_EXPORT',
    LEAVE_CARD_CHANGE = 'LEAVE_CARD_CHANGE',

    SMS_READ = 'SMS_READ',
    SMS_WRITE = 'SMS_WRITE',
    SMS_EXPORT = 'SMS_EXPORT',
}

export enum AppModules {
    CORE = 'CORE',
    RECEPTIONS = 'RECEPTIONS',
    STOCK = 'STOCK',
    TODOS = 'TODOS',
    MESSAGES = 'MESSAGES',
    CUSTOMERS = 'CUSTOMERS',
    ORDERS = 'ORDERS',
    PRODUCTS = 'PRODUCTS',
    CRM = 'CRM',
    COMPLAINTS = 'COMPLAINTS',
    QUOTATIONS = 'QUOTATIONS',
    ALERTS = 'ALERTS',
    INVOICES = 'INVOICES',
    ATTACHMENTS = 'ATTACHMENTS',
    ORDER_SCHEDULER = 'ORDER_SCHEDULER',
    CARS = 'CARS',
    GUESTS = 'GUESTS',
    KEYS = 'KEYS',
    CARDS = 'CARDS'
}

export interface IAppModule {
    type: AppModules;
    access: UserPermission[];
}

export const modules: IAppModule[] = [
    {
        type: AppModules.CORE,
        access: [
            UserPermission.DICTIONARIES_READ,
            UserPermission.DICTIONARIES_WRITE,
            UserPermission.DICTIONARIES_DELETE,
            UserPermission.DICTIONARIES_EXPORT,

            UserPermission.SETTINGS_READ_WRITE,

            // all access rights
            UserPermission.SUPER_USER,
            UserPermission.ADMIN_USER,

            UserPermission.DATA_IMPORT,

            // uzytkownicy
            UserPermission.USERS_READ,
            UserPermission.USERS_WRITE,
            UserPermission.USERS_DELETE,
            UserPermission.USERS_EXPORT,

            UserPermission.USER_WORK_READ,
            UserPermission.USER_WORK_WRITE,
            UserPermission.USER_WORK_DELETE,
            UserPermission.USER_WORK_EXPORT,

            UserPermission.USER_PAYCHECK_READ,
            UserPermission.USER_PAYCHECK_WRITE,
            UserPermission.USER_PAYCHECK_DELETE,
            UserPermission.USER_PAYCHECK_EXPORT,

            UserPermission.LEAVE_CARD_READ,
            UserPermission.LEAVE_CARD_WRITE,
            UserPermission.LEAVE_CARD_DELETE,
            UserPermission.LEAVE_CARD_EXPORT,
            UserPermission.LEAVE_CARD_CHANGE,

            UserPermission.ROLES_READ,
            UserPermission.ROLES_WRITE,
            UserPermission.ROLES_DELETE,
            UserPermission.ROLES_EXPORT,

            // uzytkownicy - notatki
            UserPermission.USERS_NOTES,

            // uprawnienia
            UserPermission.USERS_RIGHTS,

            // logi
            UserPermission.LOGS_READ,
            UserPermission.LOGS_EXPORT,

            // maillogs
            UserPermission.MAILLOGS_READ,
            UserPermission.MAILLOGS_EXPORT,

            // reports
            UserPermission.REPORTS,
            UserPermission.REPORTS_EXPORT,

            UserPermission.SMS_READ,
            UserPermission.SMS_WRITE,
            UserPermission.SMS_EXPORT
        ]
    },
    {
        type: AppModules.PRODUCTS,
        access: [
            UserPermission.PRODUCTS_READ,
            UserPermission.PRODUCTS_WRITE,
            UserPermission.PRODUCTS_DELETE,
            UserPermission.PRODUCTS_EXPORT,
        ]
    },
    {
        type: AppModules.ORDER_SCHEDULER,
        access: [
            // harmonogram
            UserPermission.SCHEDULER_READ,
            UserPermission.SCHEDULER_WRITE,

            // karty pracy
            UserPermission.WORK_CARDS_ALL,
            UserPermission.WORK_CARDS_WORK,
            UserPermission.WORK_CARDS_READ,
            UserPermission.WORK_CARDS_WRITE,
            UserPermission.WORK_CARDS_DELETE,
            UserPermission.WORK_CARDS_EXPORT,
        ]
    },
    {
        type: AppModules.ATTACHMENTS,
        access: [
            UserPermission.ATTACHMENTS_READ,
            UserPermission.ATTACHMENTS_WRITE,
            UserPermission.ATTACHMENTS_DELETE,
            UserPermission.ATTACHMENTS_EXPORT,
        ]
    },
    {
        type: AppModules.INVOICES,
        access: [
            UserPermission.INVOICES_READ,
            UserPermission.INVOICES_WRITE,
            UserPermission.INVOICES_DELETE,
            UserPermission.INVOICES_EXPORT,
        ]
    },
    {
        type: AppModules.RECEPTIONS,
        access: [
            UserPermission.RECEPTION_READ,
            UserPermission.RECEPTION_WRITE,
            UserPermission.RECEPTION_DELETE,
            UserPermission.RECEPTION_EXPORT,
        ]
    },
    {
        type: AppModules.STOCK,
        access: [
            // stock
            UserPermission.STOCK_READ,
            UserPermission.STOCK_WRITE,
            UserPermission.STOCK_DELETE,
            UserPermission.STOCK_IMPORT,
            UserPermission.STOCK_EXPORT,
            UserPermission.STOCK_CHANGE,

            // stock lend
            UserPermission.STOCK_LEND_READ,
            UserPermission.STOCK_LEND_WRITE,
            UserPermission.STOCK_LEND_DELETE,
            UserPermission.STOCK_LEND_IMPORT,
            UserPermission.STOCK_LEND_EXPORT,

            // stock limits
            UserPermission.STOCK_LIMITS_READ,
            UserPermission.STOCK_LIMITS_WRITE,
            UserPermission.STOCK_LIMITS_DELETE,
            UserPermission.STOCK_LIMITS_EXPORT,
        ]
    },
    {
        type: AppModules.TODOS,
        access: [
            UserPermission.TODOS_READ_WRITE
        ]
    },
    {
        type: AppModules.MESSAGES,
        access: [
            UserPermission.MESSAGES_READ_WRITE
        ]
    },
    {
        type: AppModules.CUSTOMERS,
        access: [
            UserPermission.CUSTOMERS_READ,
            UserPermission.CUSTOMERS_WRITE,
            UserPermission.CUSTOMERS_DELETE,
            UserPermission.CUSTOMERS_EXPORT,

            // customer notes
            UserPermission.CUSTOMER_NOTES_READ,
            UserPermission.CUSTOMER_NOTES_WRITE,
            UserPermission.CUSTOMER_NOTES_DELETE,
        ]
    },
    {
        type: AppModules.ORDERS,
        access: [
            // orders
            UserPermission.ORDERS_READ,
            UserPermission.ORDERS_WRITE,
            UserPermission.ORDERS_DELETE,
            UserPermission.ORDERS_EXPORT,
        ]
    },
    {
        type: AppModules.CRM,
        access: [
            UserPermission.CUSTOMER_CRM_READ,
            UserPermission.CUSTOMER_CRM_WRITE,
            UserPermission.CUSTOMER_CRM_DELETE,
            UserPermission.CUSTOMER_CRM_EXPORT,
        ]
    },
    {
        type: AppModules.COMPLAINTS,
        access: [
            UserPermission.COMPLAINTS_READ,
            UserPermission.COMPLAINTS_WRITE,
            UserPermission.COMPLAINTS_DELETE,
            UserPermission.COMPLAINTS_EXPORT,
        ]
    },
    {
        type: AppModules.QUOTATIONS,
        access: [
            UserPermission.QUOTATIONS_READ,
            UserPermission.QUOTATIONS_WRITE,
            UserPermission.QUOTATIONS_DELETE,
            UserPermission.QUOTATIONS_EXPORT,

            // price list
            UserPermission.PRICE_LIST_READ,
            UserPermission.PRICE_LIST_WRITE,
            UserPermission.PRICE_LIST_DELETE,
            UserPermission.PRICE_LIST_EXPORT,
        ]
    },
    {
        type: AppModules.ALERTS,
        access: [
            UserPermission.ALERTS_READ,
            UserPermission.ALERTS_WRITE,
            UserPermission.ALERTS_DELETE,
            UserPermission.ALERTS_EXPORT,
        ]
    },
    {
        type: AppModules.CARS,
        access: [
            UserPermission.CARS_READ,
            UserPermission.CARS_WRITE,
            UserPermission.CARS_DELETE,
            UserPermission.CARS_EXPORT,

            UserPermission.CARS_TRANSIT_READ,
            UserPermission.CARS_TRANSIT_WRITE,
            UserPermission.CARS_TRANSIT_DELETE,
            UserPermission.CARS_TRANSIT_EXPORT,
        ]
    },
    {
        type: AppModules.GUESTS,
        access: [
            UserPermission.GUESTS_READ,
            UserPermission.GUESTS_WRITE,
            UserPermission.GUESTS_DELETE,
            UserPermission.GUESTS_EXPORT,
            UserPermission.GUESTS_CHANGE,

            UserPermission.BUILDINGS_READ,
            UserPermission.BUILDINGS_WRITE,
            UserPermission.BUILDINGS_DELETE,
            UserPermission.BUILDINGS_EXPORT,
        ]
    },
    {
        type: AppModules.KEYS,
        access: [
            UserPermission.KEYS_READ,
            UserPermission.KEYS_WRITE,
            UserPermission.KEYS_DELETE,
            UserPermission.KEYS_EXPORT,
            UserPermission.KEYS_CHANGE,

            UserPermission.BUILDINGS_READ,
            UserPermission.BUILDINGS_WRITE,
            UserPermission.BUILDINGS_DELETE,
            UserPermission.BUILDINGS_EXPORT,
        ]
    },
    {
        type: AppModules.CARDS,
        access: [
            UserPermission.CARD_READ,
            UserPermission.CARD_WRITE,
            UserPermission.CARD_DELETE,
            UserPermission.CARD_EXPORT,
            UserPermission.CARD_CHANGE,
        ]
    }
];

export function accessModule(access: UserPermission): AppModules {
    const accModules = modules.filter((mod) => {
        return mod.access.indexOf(access) >= 0;
    });
    return accModules.length ? accModules[0].type : AppModules.CORE;
}

export default UserPermission;