import UserPermission from "@common/access";

export default [
    {
        path: "/invoices",
        name: "Invoices",
        component: () =>
            import(/* webpackChunkName: "invoices" */ "../views/administration/Invoices/Invoices.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.INVOICES_READ,
        }
    },
    {
        path: "/invoices/view/:id",
        name: "InvoicesView",
        component: () =>
            import(/* webpackChunkName: "invoices" */ "../views/administration/Invoices/InvoicesView.vue"),
        meta: {
            requiresAuth: true,
            viewMode: true,
            requiresAccess: UserPermission.INVOICES_READ,
        }
    },
    {
        path: "/invoices/edit/:id",
        name: "InvoicesEdit",
        component: () =>
            import(/* webpackChunkName: "invoices" */ "../views/administration/Invoices/InvoicesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.INVOICES_WRITE,
        }
    },
    {
        path: "/invoices/add",
        name: "InvoicesInsert",
        component: () =>
            import(/* webpackChunkName: "invoices" */ "../views/administration/Invoices/InvoicesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.INVOICES_WRITE,
        },
    },
]