
    import {Component} from "vue-property-decorator";
    import BaseView from "@/views/base/BaseView.vue";
    import PageTitle from "@/components/PageTitle.vue";
    import {searchService} from "@/services/service-container";
    import {ISearchTypeResults} from "@/services/search-service";


    @Component({
        components: {
            PageTitle
        },
    })
    export default class GlobalSearchView extends BaseView {
        private searchStr: string = '';
        private tableLoading: boolean = false;
        private tableIsLoading: boolean = false;
        private searchKey: number = 0;
        private searchData: ISearchTypeResults[] = [];

        private handleSearch(): void {
            this.initSearch();
        }

        private getTitle(key: string): string {
            return this.$t(`GlobalSearch.Type${key.replaceAll('-', '')}`).toString();
        }

        private get pageTitleStr(): string {
            const str = this.$t('GlobalSearch.PageTitle').toString();
            return `${str} ${this.searchStr}`;
        }

        mounted() {
            this.searchStr = this.$route.query.search ?? '';
            this.initSearch();
        }

        private async initSearch(): Promise<void> {
            this.searchData = [];
            this.initLoading();
            try {
              searchService.initSearch(this.searchStr, (data: ISearchTypeResults) => {
                this.searchData.push(data);
                this.searchKey += 1;
              });
            } finally {
              this.finalizeLoading();
            }
        }

        private initLoading(): void {
            this.tableIsLoading = true;
            setInterval(() => {
                this.tableLoading = this.tableIsLoading;
            }, 250);
        }

        private finalizeLoading(): void {
            this.tableIsLoading = false;
            this.tableLoading = false;
        }
    }

