import store from '../stores/counters';
import {AxiosResponse} from "axios";
import {ServiceBase} from "@/services/service-base";
import {serviceDataApi, serviceDataStore} from "@/services/service-container";

export default class Heartbeat extends ServiceBase {

    private heartInterval: number;
    private heartBeatTimer: number = 1000 * 60; // 60 seconds

    constructor() {
        super();
        this.heartInterval = setInterval(() => {
            if (serviceDataStore.isLoggedIn()) {
                this.sendHeartBeat();
            }
        }, this.heartBeatTimer);
    }

    public async sendHeartBeat(): Promise<void> {
        try {
            serviceDataApi.post('refresh', {}).then((response: AxiosResponse) => {
                if (response && response.data.status) {
                    if (response.data.token) {
                        serviceDataStore.setSessionId(response.data.token);
                    }
                    if (response.data.config) {
                        store.dispatch('setConfig', response.data.counters);
                    }
                    if (response.data.counters) {
                        store.dispatch('setCounters', response.data.counters);
                    }
                }
            });
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

}
