import {AxiosResponse} from "axios";
import UserPermission from "@common/access";
import {ServiceBase} from "@/services/service-base";
import {serviceDataApi, serviceDataStore, storageService} from "@/services/service-container";
import store from "@/stores/counters";

export default class DataStorageService extends ServiceBase {

    private fSessionId: string = '';
    private fCompanyData: ICompany = {} as ICompany;
    private fUserData: ILoginUserData = {} as ILoginUserData;
    private fDataCache: ICacheDataMap = {};
    private fUserPermissions: string[] = [];
    private fModules: string[] = [];
    private browserLanguage: string = '';
    private fLanguage: string = '';

    private defaultsLoaded: boolean = false;
    public onLoadDefaults: ICallback[] = [];
    public defaultData: IAppDefaultData = {
        filterableCount: 50,
        settings: {
            minOutline: 0,
            minSurface: 0,
            minSurfaceSum: 0,
            gridVersion: 0,
            stockBarcodeLength: 0,
        }
    } as IAppDefaultData;

    // -----------------------------------------------------------

    public themeSettings: IThemeSettings = {
        allUpper: false,
        fontSize: 12,
    } as IThemeSettings;

    // -----------------------------------------------------------

    constructor() {
        super();
        this.loadInitialData();
        this.loadThemeData();
    }

    public async loadDefaults(): Promise<void> {
        const defaultsStr = (localStorage.getItem(`app_defaults`) || '').toString();
        if (defaultsStr && defaultsStr.length) {
            this.defaultData = JSON.parse(defaultsStr);
        }
        if (!this.defaultsLoaded) {
            const response: AxiosResponse = await serviceDataApi.getSimpleData(`defaults`);
            if (response && (response.status === 200) && response.data) {
                this.defaultData = response.data;
                localStorage.setItem(`app_defaults`, JSON.stringify(this.defaultData));
                this.onLoadDefaults.forEach(callback => callback());
                this.defaultsLoaded = true;
            }
        }
    }

    public get companyId(): number | undefined {
        return this.fCompanyData ? this.fCompanyData.id : undefined;
    }

    private hasRightCheck(right: UserPermission | UserPermission[]): boolean {
        let bHasAccess = false;
        if (Array.isArray(right)) {
            right.forEach((rightItem: UserPermission) => {
                bHasAccess = bHasAccess || this.fUserPermissions.indexOf(rightItem) >= 0;
            });
        } else {
            return (this.fUserPermissions.indexOf(right) >= 0);
        }
        return bHasAccess;
    }

    public hasRight(right: UserPermission | UserPermission[]): boolean {
        const res = this.fUserData && this.fUserPermissions
            && ((this.fUserPermissions.indexOf(UserPermission.SUPER_USER) >= 0) || this.hasRightCheck(right));
        return res;
    }

    public hasAnyRights(rights: UserPermission[]): boolean {
        for (const right of rights) {
            if (this.hasRight(right)) {
                return true;
            }
        }
        return false
    }

    public deleteCache(key: string): void {
        delete this.fDataCache[key];
    }

    public getCache(key: string): AxiosResponse | undefined {
        return this.fDataCache[key];
    }

    public setCache(key: string, data: AxiosResponse): void {
        this.fDataCache[key] = data;
    }

    // eslint-disable-next-line complexity
    private loadInitialData(): void {
        // jezyk usera
        this.browserLanguage = navigator.language;
        this.fLanguage = (localStorage.getItem('user_lang') || this.browserLanguage).toString();
        if ((this.fLanguage !== 'en') && (this.fLanguage !== 'de') && (this.fLanguage !== 'pl')) {
            this.fLanguage = 'en';
        }
        this.fSessionId = (localStorage.getItem('user_session') || '').toString();
        this.fUserData = JSON.parse(localStorage.getItem('user_data') || '{}');
        this.fUserPermissions = JSON.parse(localStorage.getItem('user_access') || '[]');
        this.fCompanyData = JSON.parse(localStorage.getItem('company_data') || '[]');
        this.fModules = JSON.parse(localStorage.getItem('app_modules') || '[]');
        const counters = JSON.parse(localStorage.getItem('app_counters') || '[]');
        store.dispatch('setCounters', counters);
    }

    private loadThemeData(): void {
        const data = localStorage.getItem('theme_data');
        if (data) {
            const themeObj = JSON.parse(data);
            this.themeSettings.allUpper = themeObj.allUpper ?? false;
            this.themeSettings.fontSize = themeObj.fontSize ?? 12;
        }
        if (this.themeSettings.allUpper) {
            // document.body.classList.add('themeAllUpper');
        }
        document.body.style.fontSize = this.themeSettings.fontSize + 'px';
    }

    public get enabledModules(): string[] {
        return this.fModules;
    }

    public setSessionId(id: string): void {
        this.fSessionId = id;
        localStorage.setItem('user_session', id);
        localStorage.setItem('user_session_time', new Date().getTime().toString());
    }

    public setCompanyData(data: ICompany): void {
        this.fCompanyData = data;
        localStorage.setItem('company_data', JSON.stringify(data));
    }

    public setUserData(data: ILoginUserData): void {
        this.fUserData = data;
        localStorage.setItem('user_data', JSON.stringify(data));
    }

    public setModules(modules: IModule[]): void {
        this.fModules = modules.map(mod => mod.code);
        localStorage.setItem('app_modules', JSON.stringify(this.fModules));
    }

    public setUserPermissions(permissions: string[]): void {
        this.fUserPermissions = permissions;
        localStorage.setItem('user_access', JSON.stringify(permissions));
    }

    public getCompany(): ICompany {
        return this.fCompanyData;
    }

    public isModuleEnabled(code: string): boolean {
        return this.fModules.includes(code);
    }

    public isAnyModuleEnabled(codes: string[]): boolean {
        return codes.some(code => this.fModules.includes(code));
    }

    public isLoggedIn(): boolean {
        return (this.fSessionId.length > 0);
    }

    public getUserData(): ILoginUserData {
        return this.fUserData;
    }

    public get customerId(): number|null {
        if (this.fUserData) {
            return this.fUserData.customerId;
        }
        return null;
    }

    public get isCustomer(): boolean {
        if (this.fUserData) {
            return Boolean(this.fUserData.isCustomer);
        }
        return false;
    }

    public get userId(): number {
        if (this.fUserData) {
            return this.fUserData.id;
        }
        return 0;
    }

    public get dashboardType(): number {
        return this.fUserData.dashboardType ?? 0;
    }

    public getUserName(): string {
        if (this.fUserData) {
            return `${this.fUserData.surname} ${this.fUserData.name}`;
        }
        return '';
    }

    public getSessionId(): string {
        // if (this.fSessionId === '') {
        // const ident = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
        // this.setSessionId(ident);
        // }
        return this.fSessionId;
    }

    public getLang(): string {
        return this.fLanguage;
    }

    public setLang(lang: string): void {
        this.fLanguage = lang;
        localStorage.setItem('user_lang', lang);
    }

    public logOut(): void {
        this.fSessionId = '';
        localStorage.removeItem('user_session');
        localStorage.removeItem('user_session_time');
        localStorage.removeItem('user_access');
        localStorage.removeItem('user_data');
        localStorage.removeItem('app_modules');
        localStorage.removeItem('app_counters');
        this.removeFilters();
    }

    private removeFilters(): void {
        const userId = serviceDataStore.userId;
        const keys = storageService.getUserKeys();
        keys.forEach((key) => {
            if (key.startsWith(`${userId}__flt_`)) {
                storageService.removeItem(key);
            }
        })
    }
}
