
    import {Component, Prop} from "vue-property-decorator";
    import BaseVIEWView from "@/views/base/BaseVIEWView.vue";
    import {AxiosError, AxiosResponse} from "axios";
    import {ElForm} from "element-ui/types/form";
    import {errorService, serviceDataApi} from "@/services/service-container";

    @Component({
        components: {
        },
    })
    export default class BaseEDITView extends BaseVIEWView {
        @Prop({})
        protected parentId: number;

        @Prop({ default: null })
        private onAfterSave: AfterSaveCallbackMethod;

        @Prop({ default: true })
        private saveFormEnabled: boolean;

        // pola ktore z wartoscią NULL, nie będą czyszczone przed wysylka do api
        protected notNullableField: string[] = [];

        // opcje edycji
        protected editOptions = {
          // route edycji
          editRoute: '',
          // route listy - dla zapisz i wyjdz
          listRoute: '',
          // czy zaladowac dane z POSTA zapisu
          loadDataUponSave: false
        };

        protected getPostData(): any {
            return null;
        }

        public closeModalForm(): void {
            if (this.isModal) {
                if (this.onCloseModal !== null) {
                    this.onCloseModal();
                }
            } else {
                let routeName: string = this.editOptions.listRoute;
                if (!routeName.length) {
                  routeName = this.getAfterSaveRoute();
                }
                if (routeName.length) {
                    this.$router.push({ name: routeName });
                }
            }
        }

        protected getAfterSaveRoute(response?: AxiosResponse): string {
            return this.editOptions.listRoute;
        }

        public doSave(response: AxiosResponse): void {
          //
        }

        protected doAfterSave(response: AxiosResponse, saveMode: number = 0): void {
            if (this.onAfterSave !== null) {
                this.onAfterSave(this.modalViewMode, response, saveMode);
            }
        }

        public get saveEnabled(): boolean {
            return !this.formDisabled && this.saveFormEnabled;
        }

        public canSaveData(): boolean {
          return true;
        }

        protected saveSuccessfull(response: AxiosResponse, saveMode: number): void {
          this.$notify({
            title: '',
            type: 'success',
            duration: 5000,
            message: this.$t('common.DataSavedSuccessfully').toString(),
            position: 'bottom-right',
          });

          if (this.modalViewMode !== '') {
            //
          } else if ((saveMode === 0) && response.data && response.data.id) {
            const route = this.editOptions.editRoute ?? this.$route.name;
            this.$router.push({ name: route, params: { id: response.data.id } }).catch(()=>{});
          } else {
            if (saveMode === 1) { // zapisz i zamknij
              let routeName: string = this.editOptions.listRoute;
              if (!routeName.length) {
                routeName = this.getAfterSaveRoute(response);
              }
              if (routeName.length) {
                this.$router.push({ name: routeName });
              }
            }
          }
        }

        public async responseErrorHandle(response: AxiosResponse): Promise<void> {
          errorService.responseHandle(response);
        }

        public async errorHandle(error: AxiosError): Promise<void> {
          errorService.errorHandle(error);
        }

        public async submitDataForm(saveMode: number = 0): Promise<void> {
          if (!this.dataEndpoint.length) {
            return;
          }
          if (!this.$refs.dataForm) {
            return;
          }
          const ref: ElForm = this.$refs.dataForm as ElForm;
          if (!ref) {
            return;
          }
          if (this.dataLoading) {
            return;
          }
          if (!this.canSaveData()) {
            return;
          }

          ref.validate().then((result: boolean) => {
            if (result) {
              this.dataLoading = true;
              const saveEndpoint = (this.objectId > 0) ? this.dataEndpoint + '\\' + this.objectId : this.dataEndpoint;
              const postData: any = this.getPostData();

              Object.keys(postData).forEach((k) => postData[k] == null && !this.notNullableField.includes(k) && delete postData[k]);
              serviceDataApi.saveData(saveEndpoint, postData, (this.objectId > 0)).then((response: AxiosResponse) => {
                this.doSave(response);
                if (response && (response.status == 200)) {
                  if (response.data && this.editOptions.loadDataUponSave && (saveMode === 0)) {
                    this.loadData(response.data);
                  }
                  this.doAfterSave(response, saveMode);
                  this.saveSuccessfull(response, saveMode);
                } else {
                  this.responseErrorHandle(response);
                }
              }).catch((err) => {
                this.errorHandle(err);
              }).finally(() => {
                this.dataLoading = false;
              });
            }
          });
        }
    }
