
import {Component} from "vue-property-decorator";
import BaseView from "@/views/base/BaseView.vue";
import {ElForm} from "element-ui/types/form";
import moment from "moment/moment";

@Component({
  components: {
  },
})
export default class RecordInfoDialog extends BaseView {

  $refs!: {
    dataForm: ElForm;
  }

  private dialog: boolean = false;
  private dialogResolve: any;
  private dialogReject: any;
  private recordData: IRecordInfo|null = null;

  public formRules = {};

  private dateValue(field: string, format: string = "YYYY-MM-DD HH:mm"): string {
    return field ? moment(field).format(format) : '';
  }

  public openDialog(data: IRecordInfo): Promise<void> {
    this.dialog = true;
    this.recordData = data;

    return new Promise((resolve, reject) => {
      this.dialogResolve = resolve;
      this.dialogReject = reject;
    });
  }

  confirm(): void {
    this.dialogResolve();
    this.dialog = false;
  }
}
