import DataStorageService from "@/services/data-storage";
import Heartbeat from "@/services/heartbeat";
import DataApiService from "@/services/data-api";
import Socket from "@/services/socket";
import PrintersService from "@/services/printer-service";
import SearchService from "@/services/search-service";
import ErrorService from "@/services/error-service";
import DialogService from "@/services/dialog-service";
import StorageService from "@/services/storage-service";

export const storageService: StorageService = new StorageService();

export const serviceDataStore: DataStorageService = new DataStorageService();

export const serviceHeartbeat: Heartbeat = new Heartbeat();

export const serviceDataApi: DataApiService = new DataApiService();

export const servicePrinter: PrintersService = new PrintersService();

export const serviceSocket: Socket = new Socket();

export const searchService: SearchService = new SearchService();

export const errorService: ErrorService = new ErrorService();

export const dialogService: DialogService = new DialogService();