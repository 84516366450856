
    import {Component} from "vue-property-decorator";
    import BaseEDITView from "@/views/base/BaseEDITView.vue";
    import {replaceAll} from "@/common/functions";
    import CustomerNotes from "@/components/grids/customer/CustomerNotes.vue";
    import UserPermission from "@common/access";
    import CustomerCategoriesGrid from "@/components/grids/customer/CustomerCategoriesGrid.vue";
    import {dialogService, serviceDataApi, serviceDataStore} from "@/services/service-container";
    import CustomerCrmEvents from "@/components/grids/customer/CustomerCrmEvents.vue";
    import {ElInputTag} from 'el-input-tag';
    import CustomerUsers from "@/components/grids/customer/CustomerUsers.vue";
    import StorageSelect from "@/components/select/StorageSelect.vue";
    import {TemplateKind} from "@/common/enums";
    import CurrencySelect from "@/components/select/CurrencySelect.vue";
    import VatRateSelect from "@/components/select/VatRateSelect.vue";
    import PageEditTitle from "@/components/PageEditTitle.vue";
    import PaymentMethodsSelect from "@/components/select/PaymentMethodsSelect.vue";
    import {AxiosResponse} from "axios";

    @Component({
        components: {
            PaymentMethodsSelect,
            VatRateSelect,
            CurrencySelect,
            CustomerUsers,
            PageEditTitle,
            CustomerNotes,
            CustomerCategoriesGrid,
            CustomerCrmEvents,
            ElInputTag,
            StorageSelect,
        },
    })
    export default class CustomersEdit extends BaseEDITView {
      protected editOptions = {
        editRoute: 'CustomersEdit',
        listRoute: 'Customers'
      };

        private fCustomer = {} as ICustomerType;
        private notesAccess: boolean = false;
        private crmAccess: boolean = false;
        private usersAccess: boolean = false;
        private categoriesAccess: boolean = false;
        private groupMode: number|null = null;
        private hasSms: boolean = false;

        public formRules = {
            code: [{ required: false, min: 0, max: 25, message: this.$t('Customers.ErrInputCode').toString(), trigger: 'blur' }],
            name: [{ required: true, min: 3, max: 255, message: this.$t('Customers.ErrInputName').toString(), trigger: 'blur' }],
            ownName: [{ required: false, min: 0, max: 255, message: this.$t('Customers.ErrInputOwnName').toString(), trigger: 'blur' }],
            nip: [{ required: false, min: 0, max: 15, message: this.$t('Customers.ErrInputNip').toString(), trigger: 'blur' }],
            city: [{ required: false, min: 0, max: 100, message: this.$t('Customers.ErrInputAddress').toString(), trigger: 'blur' }],
            postCode: [{ required: false, min: 0, max: 15, message: this.$t('Customers.ErrInputAddress').toString(), trigger: 'blur' }],
            address: [{ required: false, min: 0, max: 255, message: this.$t('Customers.ErrInputAddress').toString(), trigger: 'blur' }],
            phone: [{ required: false, min: 0, max: 25, message: this.$t('Customers.ErrInputPhone').toString(), trigger: 'blur' }],
            externalId: [{ required: false, min: 0, max: 50, message: this.$t('Customers.ErrInputExternalId').toString(), trigger: 'blur' }],
            currencyId: [{ type: 'number', required: false, min: 0, message: this.$t('Customers.ErrInputCurrency').toString(), trigger: 'blur' }],
            vatRateId: [{ type: 'number', required: false, min: 0, message: this.$t('Customers.ErrInputVatRate').toString(), trigger: 'blur' }],
            paymentMethodId: [{ type: 'number', required: false, min: 0, message: this.$t('Customers.ErrInputPaymentMethod').toString(), trigger: 'blur' }],
            fax: [{ required: false, min: 0, max: 25, message: this.$t('Customers.ErrInputFax').toString(), trigger: 'blur' }],
            email: [{ type: 'email', required: false, min: 0, max: 25, message: this.$t('Customers.ErrInputEmail').toString(), trigger: 'blur' }],
        };
        public fNotifyKinds: any[] = [];
        private fOrderRealisation: number = 0;

        mounted() {
            this.hasSms = this.$store.state
              && serviceDataStore.hasRight(UserPermission.SMS_WRITE);
            this.notesAccess = (this.objectId > 0) && serviceDataStore.hasRight(UserPermission.CUSTOMER_NOTES_READ);
            this.crmAccess = (this.objectId > 0) && serviceDataStore.hasRight(UserPermission.CUSTOMER_CRM_READ);
            this.usersAccess = (this.objectId > 0) && serviceDataStore.hasRight(UserPermission.USERS_READ);
            this.categoriesAccess = true;
        }

        private get canSendSms(): boolean {
          return (this.objectId > 0) && this.hasSms
              && dialogService.smsDlg && [9,12].includes(this.fCustomer.phone?.length ?? 0);
        }

        private sendSms(): void {
          if (!this.hasSms || !dialogService.smsDlg) {
            return;
          }
          dialogService.smsDlg.open(this.fCustomer.phone, '', 0, this.fCustomer.id);
        }

        private templateKind(kind: number): string {
          return this.$t(`Templates.FieldKind_${kind}`).toString();
        }

        protected getDefaultData(): any {
            return {
                code: '',
                name: '',
                ownName: '',
                postCode: '',
                city: '',
                regon: '',
                address: '',
                tags: [],
                ownProductsOnly: false,
                discountPercent: 0,
            }
        }

        public get customer(): ICustomerType {
            return this.fCustomer;
        }

        private async searchGusNip(): Promise<void> {
          this.initLoading();
          try {
            serviceDataApi.getSimpleData(`gus/nip/${this.fCustomer.nip}`).then((response: AxiosResponse) => {
              if (response && (response.status == 200)) {
                const custData: IGusCustomerData = response.data;

                if (!this.fCustomer.name.length) {
                  this.fCustomer.name = custData.nazwa;
                }
                this.fCustomer.ownName = custData.nazwa;
                this.fCustomer.postCode = custData.kodPocztowy;
                this.fCustomer.city = custData.miejscowosc;
                this.fCustomer.regon = custData.regon;
                this.fCustomer.address = `${custData.ulica} ${custData.nrNieruchomosci}`;
                if (custData.nrLokalu.length) {
                  this.fCustomer.address += `/${custData.nrLokalu}`
                }

              } else {
                this.$notify({
                  title: this.$t('DataRequest.ErrorTitle').toString(),
                  type: 'error',
                  duration: 5000,
                  message: response && response.data.error ? response.data.error : this.$t('DataRequest.UnknownError').toString(),
                  position: 'bottom-right',
                });
              }
            });
          } finally {
            this.finalizeLoading();
          }
        }

        protected loadData(data: ICustomerType): void {
            this.fCustomer = data;
            this.fCustomer.discountPercent = this.fCustomer.discountPercent / 100 || 0;

            const values = Object.values(TemplateKind).filter((v) => !isNaN(Number(v)));
            values.forEach((key) => {
              const sett = data.settings?.find(item => item.config === `notification_${key}`);
              this.fNotifyKinds.push({
                config: `notification_${key}`,
                value: !sett || (sett.value === '1'),
                label: this.templateKind(parseInt((key as string), 10))
              });
            });

            // metoda grupowania
            const sett = data.settings?.find(item => item.config === `groupMode`);
            this.groupMode = sett && sett.value.length ? parseInt(sett.value, 10) : null;

            // dni do realizacji zlecenia
            const sett2 = data.settings?.find(item => item.config === `order_realisation_days`);
            this.fOrderRealisation = sett2 && sett2.value.length ? parseInt(sett2.value, 10) : 0;
        }

        protected getPostData(): any {
            const settingsArr = this.fNotifyKinds.map((item) => {
              return {
                config: item.config,
                value: item.value ? '1' : '0',
              }
            });

            settingsArr.push({
              config: `groupMode`,
              value: this.groupMode ? this.groupMode.toString() : ''
            });

            settingsArr.push({
              config: `order_realisation_days`,
              value: this.fOrderRealisation ? this.fOrderRealisation.toString() : '0'
            });

            return {
                code: this.fCustomer.code,
                name: this.fCustomer.name,
                ownName: this.fCustomer.ownName,
                nip: replaceAll(this.fCustomer.nip ?? '', ' ', ''),
                regon: replaceAll(this.fCustomer.regon ?? '', ' ', ''),
                city: this.fCustomer.city,
                postCode: this.fCustomer.postCode,
                address: this.fCustomer.address,
                phone: this.fCustomer.phone,
                fax: this.fCustomer.fax,
                email: this.fCustomer.email,
                externalId: this.fCustomer.externalId,
                tags: this.fCustomer.tags.join(','),
                ownProductsOnly: this.fCustomer.ownProductsOnly,
                storageId: this.fCustomer.storageId || null,
                currencyId: this.fCustomer.currencyId || null,
                vatRateId: this.fCustomer.vatRateId || null,
                paymentDays: this.fCustomer.paymentDays,
                discountPercent: this.fCustomer.discountPercent * 100,
                paymentMethodId: this.fCustomer.paymentMethodId || null,
                settings: settingsArr,
            };
        }

        public created(): void {
            this.dataEndpoint = 'customers';
            this.pageTitle = this.$t('Customers.InsertPageTitle').toString();
            this.notNullableField = ['currencyId', 'vatRateId'];
        }

        protected getAfterSaveRoute(): string {
            return 'Customers';
        }

        public async submitCustomerForm(saveMode: number = 0): Promise<void> {
            // we need this data for check, if they are not filled then just go with form validation
            if (!this.fCustomer.nip?.length) {
                this.submitDataForm(saveMode);
                return;
            }

            const data = { nip: this.fCustomer.nip };
            const url = `customers/check`;
            const response: any = await serviceDataApi.postData(url, data);

            // no stock so we are free to go
            if (!response || !response.data) {
                this.submitDataForm(saveMode);
                return;
            }

            // we are editing this stock so that is ok
            if (this.objectId == response.data.id) {
                this.submitDataForm(saveMode);
                return;
            }

            const isConfirmed: number = await dialogService.confirmDlg.open(
                this.$t('common.Confirm').toString(),
                this.$t('Customers.EditConfirmationMessage').toString(),
                [{
                        id: 1,
                        label: this.$t('Customers.SaveCustomer').toString(),
                        icon: 'fa fa-save',
                    }, {
                        id: 2,
                        label: this.$t('Customers.EditCustomer').toString(),
                        icon: 'fa fa-pencil',
                    }, {
                        id: 3,
                        label: this.$t('common.CancelBtn').toString(),
                        icon: 'fa fa-times',
                    }
                ]
            );

            if (isConfirmed === 1) {
                this.submitDataForm(saveMode);
            } else if (isConfirmed === 2) {
                this.$router.push({ name: "CustomersEdit", params: {id: response.data.id}})
            }
        }
    }

