
import {Component, Prop, Vue} from "vue-property-decorator";
import MenuItem from "@/components/Menu/MenuItem.vue";
import MenuSeparator from "@/components/Menu/MenuSeparator.vue";

@Component({
  components: {
    MenuItem,
    MenuSeparator
  }
})
export default class MenuContainer extends Vue {
  @Prop()
  item: IMenuConfig;

  @Prop()
  menuFilter: string;

  private fIsMenuCollapsed: boolean = true;

  private get hasCounter(): boolean {
    return Boolean(this.item && this.item.counterName && (this.item.counterName.length > 0) && (this.counterValue > 0));
  }

  private get counterValue(): number {
    if (!this.item.counterName) {
      return 0;
    }
    if (Array.isArray(this.item.counterName)) {
      let sum = 0;
      this.item.counterName.forEach((item) => {
        sum += this.$store.getters.getCounter(item);
      });
      return sum;
    } else {
      return this.$store.getters.getCounter(this.item.counterName);
    }
  }

  private get isMenuCollapsed(): boolean {
    return this.fIsMenuCollapsed && (this.menuFilter.trim().length === 0);
  }

  private isSeparatorVisible(): boolean {
    return (!this.menuFilter.trim().length);
  }

  private isMenuContainerVisible(item: IMenuConfig): boolean {
    return item.items && item.items.some((item) => {
      return this.isMenuVisible(item);
    });
  }

  private get menuIcon(): string {
    return this.isMenuCollapsed ? 'fa-angle-down' : 'fa-angle-up';
  }

  private isMenuVisible(item: IMenuConfig): boolean {
    return (!this.menuFilter.trim().length || (item.langStr && item.langStr.toLowerCase()?.includes(this.menuFilter.toLowerCase())));
  }

}
