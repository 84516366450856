import UserPermission from "@common/access";

export default [

    {
        path: "/complaint-statuses",
        name: "ComplaintStatus",
        component: () =>
            import(/* webpackChunkName: "complaint-statuses" */ "../views/administration/Complaints/ComplaintStatus.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/complaint-statuses/edit/:id",
        name: "ComplaintStatusEdit",
        component: () =>
            import(/* webpackChunkName: "complaint-statuses" */ "../views/administration/Complaints/ComplaintStatusEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/complaint-statuses/add",
        name: "ComplaintStatusInsert",
        component: () =>
            import(/* webpackChunkName: "complaint-statuses" */ "../views/administration/Complaints/ComplaintStatusEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/complaints",
        name: "Complaints",
        component: () =>
            import(/* webpackChunkName: "complaints" */ "../views/administration/Complaints/Complaints.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.COMPLAINTS_READ,
        }
    },
    {
        path: "/complaints/edit/:id",
        name: "ComplaintsEdit",
        component: () =>
            import(/* webpackChunkName: "complaints" */ "../views/administration/Complaints/ComplaintsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.COMPLAINTS_WRITE,
        }
    },
    {
        path: "/complaints/add",
        name: "ComplaintsInsert",
        component: () =>
            import(/* webpackChunkName: "complaints" */ "../views/administration/Complaints/ComplaintsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.COMPLAINTS_WRITE,
        }
    },
    {
        path: "/complaint-reasons",
        name: "ComplaintReason",
        component: () =>
            import(/* webpackChunkName: "complaint-reasons" */ "../views/administration/Complaints/ComplaintReason.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/complaint-reasons/edit/:id",
        name: "ComplaintReasonEdit",
        component: () =>
            import(/* webpackChunkName: "complaint-reasons" */ "../views/administration/Complaints/ComplaintReasonEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/complaint-reasons/add",
        name: "ComplaintReasonInsert",
        component: () =>
            import(/* webpackChunkName: "complaint-reasons" */ "../views/administration/Complaints/ComplaintReasonEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
];