import Vue from 'vue';
import Vuex from 'vuex'

export interface State {
    messageCount: number
    leaveCardsCount: number;
    socketConnected: number;
    ordersCount: number;
    alertsCount: number;
    smsEnabled: boolean
}

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        messageCount: 0,
        leaveCardsCount: 0,
        socketConnected: 0,
        ordersCount: 0,
        alertsCount: 0,
        smsEnabled: false,
    },
    mutations: {
        smsEnabled (state: State, params: { status: boolean }) {
            state.smsEnabled = params.status;
        },
        alertCount (state: State, params: { count: number }) {
            state.alertsCount = params.count;
        },
        ordersCount (state: State, params: { count: number }) {
            state.ordersCount = params.count;
        },
        messageCount (state: State, params: { count: number }) {
            state.messageCount = params.count;
        },
        leaveCardsCount (state: State, params: { count: number }) {
            state.leaveCardsCount = params.count;
        },
        socketConnected (state: State, params: { status: number }) {
            state.socketConnected = params.status;
        },
    },
    actions: {
        setConfig(context: any, payload: any) {
            if (typeof payload.smsEnabled !== undefined) {
                context.commit('smsEnabled', {
                    status: payload.smsEnabled
                })
            }
        },
        incMessages(context: any) {
            context.commit('messageCount', {
                count: context.state.messageCount + 1
            })
        },
        setMessages(context: any, newCount: number) {
            context.commit('messageCount', {
                count: newCount
            })
        },
        setCounters(context: any, payload: any) {
            localStorage.setItem('app_counters', JSON.stringify(payload));
            if (typeof payload.messages !== undefined) {
                context.commit('messageCount', {
                    count: payload.messages
                })
            }
            if (typeof payload.leaveCards !== undefined) {
                context.commit('leaveCardsCount', {
                    count: payload.leaveCards
                })
            }
            if (typeof payload.orders !== undefined) {
                context.commit('ordersCount', {
                    count: payload.orders
                })
            }
            if (typeof payload.alerts !== undefined) {
                context.commit('alertCount', {
                    count: payload.alerts
                })
            }
        }
    },
    getters: {
        getCounter: (state: any) => (id: string) => {
            if (id === 'messages') {
                return state.messageCount
            } else if (id === 'leaveCards') {
                return state.leaveCardsCount
            } else if (id === 'orders') {
                return state.ordersCount
            } else if (id === 'alerts') {
                return state.alertsCount
            } else {
                return 0;
            }
        }
    }
})