import UserPermission from "@common/access";

export default [
    {
        path: "/banks",
        name: "Banks",
        component: () =>
            import(/* webpackChunkName: "banks" */ "../views/banks/Banks.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [],
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/banks/edit/:id",
        name: "BanksEdit",
        component: () =>
            import(/* webpackChunkName: "banks" */ "../views/banks/BanksEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [],
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/banks/add",
        name: "BanksInsert",
        component: () =>
            import(/* webpackChunkName: "banks" */ "../views/banks/BanksEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [],
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/banks-departments",
        name: "BanksDepartments",
        component: () =>
            import(/* webpackChunkName: "banks" */ "../views/banks/BanksDepartments.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [],
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/banks-departments/edit/:id",
        name: "BanksDepartmentsEdit",
        component: () =>
            import(/* webpackChunkName: "banks" */ "../views/banks/BanksDepartmentsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [],
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/banks-departments/add",
        name: "BanksDepartmentsInsert",
        component: () =>
            import(/* webpackChunkName: "banks" */ "../views/banks/BanksDepartmentsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [],
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
];