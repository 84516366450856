import UserPermission from "@common/access";

export default [
    {
        path: "/stock-state-report",
        name: "StockStateReport",
        component: () =>
            import(/* webpackChunkName: "reports" */ "../views/reports/StockStateReport.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.REPORTS,
        }
    },
];