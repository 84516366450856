
    import {Component} from "vue-property-decorator";
    import DataTable from "@/components/custom/DataTable.vue";
    import UserPermission from "@common/access";
    import {DataTableFormat} from "@/common/enums";
    import GridEditPartial from "@/components/custom/GridEditPartial.vue";
    import CrmEdit from "@/views/administration/Crm/CrmEdit.vue";

    @Component({
        components: {
            DataTable,
            CrmEdit
        },
    })
    export default class CustomerCrmEvents extends GridEditPartial {

        protected getFilters(filters: ITableDataFilter): IUnknownObject {
            delete filters.search;
            return {
                customerId: this.parentId,
                ...filters,
            };
        }

        private get getDataTableOptions(): IDataTableOptions {
            return {
                canInsert: true,
                canDelete: true,
                canEdit: true,
                canExport: true,
                multiSelectable: false,
                filterKey: 'CustomerCrmEventsGrid',

                actionsWidth: 80,

                viewAccess: UserPermission.CUSTOMER_CRM_READ,
                editAccess: UserPermission.CUSTOMER_CRM_WRITE,
                deleteAccess: UserPermission.CUSTOMER_CRM_DELETE,
                exportAccess: UserPermission.CUSTOMER_CRM_READ,

                tableEndpoint: 'crm',

                defaultfilterEnabled: false,

                columns: [
                    {
                        name: "id",
                        label: this.$t('Crm.FieldId').toString(),
                        exported: true,
                        visible: false,
                        width: 100,
                    },
                    {
                        name: "event",
                        label: this.$t('Crm.FieldEvent').toString(),
                        visible: true,
                        format: (data: ICrmEvent) => data?.name ?? '',
                        orderBy: 'event.name',
                        processor: (data) => data?.name ?? ''
                    },
                    {
                        name: "date",
                        label: this.$t('Crm.FieldDate').toString(),
                        width: 160,
                        format: DataTableFormat.DATETIME,
                        visible: true
                    },
                    {
                        name: "note",
                        label: this.$t('Crm.FieldNote').toString(),
                        visible: false
                    },
                ]
            };
        }
    }
