import UserPermission from "@common/access";

export default [
    {
        path: "/keys",
        name: "Keys",
        component: () =>
            import(/* webpackChunkName: "keys" */ "../views/structure/Keys/Keys.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.KEYS_READ,
        }
    },
    {
        path: "/keys/edit/:id",
        name: "KeysEdit",
        component: () =>
            import(/* webpackChunkName: "keys" */ "../views/structure/Keys/KeysEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.KEYS_WRITE,
        }
    },
    {
        path: "/keys/add",
        name: "KeysInsert",
        component: () =>
            import(/* webpackChunkName: "keys" */ "../views/structure/Keys/KeysEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.KEYS_WRITE,
        }
    },
    {
        path: "/keys-logs",
        name: "KeysLogs",
        component: () =>
            import(/* webpackChunkName: "keys" */ "../views/structure/Keys/KeyLogs.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.KEYS_READ,
        }
    }
];