
    import { Component, Prop, Vue } from "vue-property-decorator";
    import FilterableSelect from "@/components/custom/FilterableSelect.vue";
    import {serviceDataStore, storageService} from "@/services/service-container";
    import UserPermission from "@common/access";
    import CustomersEdit from "@/views/administration/Customers/CustomersEdit.vue";

    @Component({
        components: {
            FilterableSelect,
            CustomersEdit
        },
    })
    export default class CustomerSelect extends Vue {

        $refs!: {
          filterElem: FilterableSelect;
        }

        @Prop({ default: null })
        public value: number;

        @Prop({ default: false })
        public isDisabled: boolean;

        @Prop({ default: true })
        public isClearable: boolean;

        @Prop({ default: false })
        private fullLoad: boolean;

        @Prop({ default: 'select' })
        private mode: string;

        private fCanView: boolean = false;
        private editMode: string = '';
        public filterMode: boolean = false;

        private getFilters(filter: ITableDataFilter): IUnknownObject {
          return {
            id: filter.id ?? undefined,
            search: filter.search ? filter.search : undefined,
            includeData: true,
          };
        }

        private get btnVisible(): boolean {
          return this.fCanView && (this.value > 0);
        }

        protected onEditClose(): void {
          this.editMode = '';
        }

        public focus(): void {
          this.$refs.filterElem.focus();
        }

        private showCustEdit(): void {
          if (this.value) {
            this.editMode = 'EDIT';
          }
        }

        public customerChanged(id: number): void {
            this.$emit('input', id);
        }

        public changeObject(data: ISearchItem): void {
          this.$emit('changeData', data?.data);
        }

        public saveFilters(filterKey: string): void {
            if (this.value ?? 0 > 0) {
              storageService.setItem(`${filterKey}_flt_customerId`, this.value.toString());
            } else {
              storageService.removeItem(`${filterKey}_flt_customerId`);
            }
        }

        public loadFilters(filterKey: string): void {
          this.filterMode = true;
            const str = storageService.getItem(`${filterKey}_flt_customerId`);
            if (str.length) {
                this.$emit('input', parseInt(str, 10));
            }
        }

        public clearFilter(): void {
            this.$emit('input', null);
        }

        mounted() {
          this.fCanView = (this.mode !== 'filter') && serviceDataStore.hasRight(UserPermission.CUSTOMERS_WRITE);
        }
    }
