import UserPermission from "@common/access";

export default [
    {
        path: "/locations",
        name: "Locations",
        component: () =>
            import(/* webpackChunkName: "locations" */ "../views/administration/Locations/Locations.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/locations/edit/:id",
        name: "LocationsEdit",
        component: () =>
            import(/* webpackChunkName: "locations" */ "../views/administration/Locations/LocationsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/locations/add",
        name: "LocationsInsert",
        component: () =>
            import(/* webpackChunkName: "locations" */ "../views/administration/Locations/LocationsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/companies",
        name: "Companies",
        component: () =>
            import(/* webpackChunkName: "companies" */ "../views/administration/Companies/Companies.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/companies/edit/:id",
        name: "CompaniesEdit",
        component: () =>
            import(/* webpackChunkName: "companies" */ "../views/administration/Companies/CompaniesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/companies/add",
        name: "CompaniesInsert",
        component: () =>
            import(/* webpackChunkName: "companies" */ "../views/administration/Companies/CompaniesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/buildings",
        name: "Buildings",
        component: () =>
            import(/* webpackChunkName: "buildings" */ "../views/structure/Buildings/Buildings.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/buildings/edit/:id",
        name: "BuildingsEdit",
        component: () =>
            import(/* webpackChunkName: "buildings" */ "../views/structure/Buildings/BuildingsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/buildings/add",
        name: "BuildingsInsert",
        component: () =>
            import(/* webpackChunkName: "buildings" */ "../views/structure/Buildings/BuildingsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/places",
        name: "Places",
        component: () =>
            import(/* webpackChunkName: "places" */ "../views/administration/Places/Places.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/places/edit/:id",
        name: "PlacesEdit",
        component: () =>
            import(/* webpackChunkName: "places" */ "../views/administration/Places/PlacesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/places/add",
        name: "PlacesInsert",
        component: () =>
            import(/* webpackChunkName: "places" */ "../views/administration/Places/PlacesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/place-types",
        name: "PlaceTypes",
        component: () =>
            import(/* webpackChunkName: "roles" */ "../views/administration/PlaceTypes/PlaceTypes.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/place-types/edit/:id",
        name: "PlaceTypesEdit",
        component: () =>
            import(/* webpackChunkName: "roles" */ "../views/administration/PlaceTypes/PlaceTypesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/place-types/add",
        name: "PlaceTypesInsert",
        component: () =>
            import(/* webpackChunkName: "roles" */ "../views/administration/PlaceTypes/PlaceTypesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
];