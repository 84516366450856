
    import {Component} from "vue-property-decorator";
    import BaseEDITView from "@/views/base/BaseEDITView.vue";
    import DatePicker from "@/components/custom/DatePicker.vue";
    import TextEditor from "@/components/custom/TextEditor.vue";
    import UserSelect from "@/components/select/UserSelect.vue";
    import PageEditTitle from "@/components/PageEditTitle.vue";

    @Component({
        components: {
            UserSelect,
            DatePicker,
            PageEditTitle,
            TextEditor
        },
    })
    export default class CustomerUserEdit extends BaseEDITView {

        private fCustomerUser: ICustomerUser = {} as ICustomerUser;

        public formRules = {
            userId: [{ type: 'number', required: true, min: 1, message: this.$t('CustomerUsers.ErrInputUser').toString(), trigger: 'blur' }],
        };

        private changeUser(id: number): void {
            this.fCustomerUser.userId = id ?? 0;
        }

        public get customerUser(): ICustomerUser {
            return this.fCustomerUser;
        }

        protected loadData(data: ICustomerUser): void {
            this.fCustomerUser = data;
        }

        protected getPostData(): any {
            return {
                userId: this.fCustomerUser.userId,
                customerId: this.parentId ?? this.fCustomerUser.customerId,
                isNotified: this.fCustomerUser.isNotified,
            };
        }

        public created(): void {
            this.dataEndpoint = 'customer-users';
            this.pageTitle = this.$t('CustomerUsers.InsertPageTitle').toString();
        }

        protected getAfterSaveRoute(): string {
            return 'Customers';
        }
    }

