
    import { Component, Vue } from "vue-property-decorator";

    interface IDialogButton {
        id: number;
        label: string;
        icon?: string;
        color?: string;
    }

    @Component({
        components: {
        },
    })
    export default class ConfirmDialog extends Vue {

        private dialog: boolean = false;
        private dialogTitle: string = "";
        private dialogMessage: string = "";
        private dialogConfirmButton = "";
        private dialogIcon: string = "";
        private dialogbuttons: IDialogButton[] = [];
        private dialogResolve: any;
        private dialogReject: any;

        resetWindow() {
          this.dialogConfirmButton = this.$t("common.Confirm").toString();
          this.dialogTitle = "";
          this.dialogMessage = "";
          this.dialogbuttons = [];
          this.dialogIcon = "";
        }

        mounted(): void {
            this.resetWindow();
        }

        created() {
            window.addEventListener("keyup", this.keyListener);
        }

        destroyed() {
            window.removeEventListener("keyup", this.keyListener);
        }

        private keyListener(event: KeyboardEvent) {
            if (!this.dialog) {
                return;
            }
            if (event.key === "Enter") {
                this.agree();
            } else if (event.key === "Escape") {
                this.cancel();
            }
        }

        public openDeleteDialog(title: string = '', text: string = '', btnCaption: string = ''): Promise<boolean> {
            this.dialogConfirmButton = btnCaption.length ? btnCaption : this.$t("common.ConfirmDeleteBtn").toString();
            const msgTitle = title.length ? title : this.$t('Table.DeleteTitle').toString();
            const msgText = text.length ? text : this.$t('Table.DeleteMessage').toString();
            return this.open(msgTitle, msgText);
        }

        public open(title: string, message: string, buttons: IDialogButton[] = [], dialogIcon: string = ''): Promise<any> {
          this.dialog = true;
          this.dialogTitle = title;
          this.dialogMessage = message;
          this.dialogbuttons = buttons;
          this.dialogIcon = dialogIcon;
          return new Promise((resolve, reject) => {
            this.dialogResolve = resolve;
            this.dialogReject = reject;
          });
        }

        private handleClose(): void {
            this.dialogResolve(false);
            this.dialog = false;
            this.resetWindow();
        }

        private agree(): void {
            this.dialogResolve(true);
            this.dialog = false;
        }

        private cancel(): void {
            this.handleClose();
        }

        private btnClick(btn: IDialogButton): void {
            this.dialogResolve(btn.id);
            this.dialog = false;
        }
    }

