
    import {Component} from "vue-property-decorator";
    import DatePicker from "@/components/custom/DatePicker.vue";
    import DataTable from "@/components/custom/DataTable.vue";
    import BaseView from "@/views/base/BaseView.vue";
    import PageTitle from "@/components/PageTitle.vue";
    import {serviceDataApi, storageService} from "@/services/service-container";
    import {AxiosResponse} from "axios";
    import Scheduler from "@/components/Scheduler.vue";
    import PlaceSelect from "@/components/select/PlaceSelect.vue";

    @Component({
        components: {
            DataTable,
            DatePicker,
            PageTitle,
            Scheduler,
            PlaceSelect
        },
    })
    export default class ShiftScheduler extends BaseView {

        private fCurrentPlaceId = 0;
        private fCurrentDate = new Date();
        private fCurrentYear = new Date().getFullYear();
        private fCurrentMonth = new Date().getMonth()+1;

        private monthArray: any[] = [];
        private yearsArray: number[] = [];

        private fPlaces: IPlace[] = [];
        private fShifts: IShift[] = [];
        private fShiftUsers: IShiftUser[] = [];

        private tableIsLoading: boolean = false;
        private tableLoading: boolean = false;

        private get rowsMap(): ISchedulerRow[] {
            return this.fPlaces.map((place) => {
                return {
                    id: place.id ?? 0,
                    name: place.name
                };
            });
        }

        private get dataMap(): ISchedulerItem[] {
            return this.fShiftUsers.map((item) => {
                return {
                    id: item.id ?? 0,
                    rowId: item.placeId,
                    name: item.user ? `${item.user.surname} ${item.user.name}` : '',
                    timeFrom: item.timeFrom,
                    timeTo: item.timeTo,
                };
            });
        }

        private initLoading(): void {
            this.tableIsLoading = true;
            setInterval(() => {
                this.tableLoading = this.tableIsLoading;
            }, 250);
        }

        private finalizeLoading(): void {
            this.tableIsLoading = false;
            this.tableLoading = false;
        }

        private get canShowScheduler(): boolean {
            return (this.fCurrentPlaceId > 0);
        }

        public mounted(): void {
            this.fCurrentYear = new Date().getFullYear();
            this.yearsArray.push(this.fCurrentYear-1);
            this.yearsArray.push(this.fCurrentYear);
            this.yearsArray.push(this.fCurrentYear+1);

            for(let ii=1; ii<=12; ii++) {
                this.monthArray.push({
                    idx: ii,
                    name: this.getMonth(ii)
                })
            }

            this.initLoading();
            try {
                this.loadShifts();
                this.loadPlaces();
                this.loadShiftUsers();
            } finally {
                this.finalizeLoading();
            }
        }

        private getMonth(idx: number) {
            const objDate = new Date();
            objDate.setDate(1);
            objDate.setMonth(idx-1);

            let locale;
            let language = storageService.getItem('user_lang', 'pl');
            if (language === 'pl') {
                locale = 'pl-PL';
            }  else {
                locale = "en-US";
            }

            const  month = objDate.toLocaleString(locale, { month: "long" });

            return month.charAt(0).toUpperCase() + month.slice(1);
        }

        private loadPlaces(): void {
            const filterObj: ITableDataFilter = {
                search: ''
            };

            const reqData: IDataRequest = {
                page: 0,
                itemsPerPage: 0,
                filter: JSON.stringify(filterObj)
            };

            serviceDataApi.getList('places', reqData).then((response: AxiosResponse) => {
                if (response && (response.status == 200) && (response.data.items)) {
                    this.fPlaces = response.data.items;
                }
            });
        }

        private loadShiftUsers(): void {
            const filterObj: ITableDataFilter = {
                search: ''
            };

            const reqData: IDataRequest = {
                page: 0,
                itemsPerPage: 0,
                filter: JSON.stringify(filterObj)
            };

            serviceDataApi.getList('shift-users', reqData).then((response: AxiosResponse) => {
                if (response && (response.status == 200) && (response.data.items)) {
                    this.fShiftUsers = response.data.items;
                }
            });
        }

        private loadShifts(): void {
            const filterObj: ITableDataFilter = {
                search: ''
            };

            const reqData: IDataRequest = {
                page: 0,
                itemsPerPage: 0,
                filter: JSON.stringify(filterObj)
            };

            serviceDataApi.getList('shifts', reqData).then((response: AxiosResponse) => {
                if (response && (response.status == 200) && (response.data.items)) {
                    this.fShifts = response.data.items;
                }
            });
        }
    }

