
    import {Component, Vue} from "vue-property-decorator";
    import {serviceDataStore, storageService} from "../../services/service-container";
    import moment from "moment";

    @Component({
        components: {
        },
    })
    export default class BoxDate extends Vue {
        private get userName(): string {
            return serviceDataStore.getUserName();
        }
        private get dateStr(): string {
            const date = new Date();
            const language = storageService.getItem('user_lang', 'pl');
            const mDate = moment(date);
            mDate.locale(language);
            const today = mDate.format('LL');
            const weekDay = this.$t('days.day' + date.getDay()).toString();
            return `${weekDay}, ${today}`;
        }
    }
