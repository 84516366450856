
import { Component, Vue } from "vue-property-decorator";
import AdminDashboard from "@/components/dashboards/AdminDashboard.vue";
import {serviceDataStore, serviceSocket} from "@/services/service-container";
import CustomerDashboard from "@/components/dashboards/CustomerDashboard.vue";
import EmployeeDashboard from "@/components/dashboards/EmployeeDashboard.vue";

@Component({
  components: {
    AdminDashboard,
    CustomerDashboard,
    EmployeeDashboard,
  },
})
export default class AdminMain extends Vue {
  public get dashboardType(): number {
    return serviceDataStore.dashboardType;
  }

  mounted() {
    serviceSocket.appHook = this;
    this.$nextTick(() => {
      serviceSocket.initSocket();
    });
  }
}
