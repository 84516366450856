
import { Component, Vue } from "vue-property-decorator";
import draggable from 'vuedraggable'
import contextMenu from "vue-context-menu";
import ChangeLogDialog from "@/components/dialogs/ChangeLogDialog.vue";
import {storageService} from "@/services/service-container";

export interface IDashboardRow {
  id: number;
  elements: string[];
}

@Component({
  components: {
    ChangeLogDialog,
    draggable,
    contextMenu
  },
})
export default class DashboardBase extends Vue {

  $refs!: {
    ctxMenu: contextMenu;
  }

  protected showChangeLog: boolean = true;

  private drag: boolean = false;

  private fRows: IDashboardRow[] = [];
  private fStaticRows: string[] = [];
  private fStaticRowsAfter: string[] = [];

  private resetDashboard(): void {
    storageService.removeItem(`dash_layout`);
    this.loadLayout();
  }

  private openContext(): void {
    this.$refs.ctxMenu.open();
  }

  private doDragEnd(): void {
    this.drag = false;
    this.saveLayout();
  }

  mounted(): void {
    this.loadLayout();
  }

  protected getDefaultStaticLayout(): string[] {
    return [];
  }

  protected getDefaultStaticLayoutAfter(): string[] {
    return [];
  }

  protected getDefaultDynamicLayout(): IDashboardRow[] {
    return [];
  }

  protected loadLayout(): void {
    const laySettings = storageService.getItem(`dash_layout`);
    if (laySettings.length) {
      this.fRows = JSON.parse(laySettings);
    } else {
      this.fRows = this.getDefaultDynamicLayout();
    }

    this.fStaticRows = this.getDefaultStaticLayout();
    this.fStaticRowsAfter = this.getDefaultStaticLayoutAfter();
  }

  private handleClose(row: IDashboardRow, element: string): void {
    const ind = row.elements.indexOf(element);
    if (ind > -1) {
      row.elements.splice(ind, 1);
      this.saveLayout();
    }
  }

  private saveLayout(): void {
    const laySettings = JSON.stringify(this.fRows);
    storageService.setItem(`dash_layout`, laySettings);
  }
}
