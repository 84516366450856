
    import {Component} from "vue-property-decorator";
    import BaseEDITView from "@/views/base/BaseEDITView.vue";
    import DatePicker from "@/components/custom/DatePicker.vue";
    import TextEditor from "@/components/custom/TextEditor.vue";
    import PageEditTitle from "@/components/PageEditTitle.vue";

    @Component({
        components: {
            DatePicker,
            PageEditTitle,
            TextEditor
        },
    })
    export default class CustomerNoteEdit extends BaseEDITView {

        protected focusOnStartRefName: string = 'noteInput';
        private fCustomerNote: ICustomerNote = {} as ICustomerNote;

        public formRules = {
            note: [{ required: true, min: 1, max: 3000, message: this.$t('CustomerNotes.ErrInputNote').toString(), trigger: 'blur' }],
            dateTo: [{ required: false, message: this.$t('CustomerNotes.ErrInputDateTo').toString(), trigger: 'blur' }],
        };

        public get customerNote(): ICustomerNote {
            return this.fCustomerNote;
        }

        protected loadData(data: ICustomerNote): void {
            this.fCustomerNote = data;
        }

        protected getPostData(): any {
            return {
                customerId: this.parentId ?? this.fCustomerNote.customerId,
                note: this.fCustomerNote.note,
                dateTo: this.fCustomerNote.dateTo,
            };
        }

        public created(): void {
            this.dataEndpoint = 'customer-notes';
            this.pageTitle = this.$t('CustomerNotes.InsertPageTitle').toString();
        }

        protected getAfterSaveRoute(): string {
            return 'Customers';
        }
    }

