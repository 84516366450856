import UserPermission, {AppModules} from "@common/access";

export default [
    {
        path: "/card-rights/edit/:id",
        name: "CardRightsEdit",
        component: () =>
            import(/* webpackChunkName: "cards" */ "../views/cards/CardRightEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARDS],
            requiresAccess: UserPermission.CARD_WRITE,
        }
    },
    {
        path: "/card-rights/add",
        name: "CardRightsInsert",
        component: () =>
            import(/* webpackChunkName: "cards" */ "../views/cards/CardRightEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARDS],
            requiresAccess: UserPermission.CARD_WRITE,
        }
    },
    {
        path: "/cards",
        name: "Cards",
        component: () =>
            import(/* webpackChunkName: "cards" */ "../views/cards/Cards.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARDS],
            requiresAccess: UserPermission.CARD_READ,
        }
    },
    {
        path: "/cards/edit/:id",
        name: "CardsEdit",
        component: () =>
            import(/* webpackChunkName: "cards" */ "../views/cards/CardsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARDS],
            requiresAccess: UserPermission.CARD_WRITE,
        }
    },
    {
        path: "/cards/add",
        name: "CardsInsert",
        component: () =>
            import(/* webpackChunkName: "cards" */ "../views/cards/CardsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARDS],
            requiresAccess: UserPermission.CARD_WRITE,
        }
    },
    {
        path: "/card-types",
        name: "CardTypes",
        component: () =>
            import(/* webpackChunkName: "card-types" */ "../views/cards/CardTypes.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARDS],
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/card-types/edit/:id",
        name: "CardTypesEdit",
        component: () =>
            import(/* webpackChunkName: "card-types" */ "../views/cards/CardTypesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARDS],
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/card-types/add",
        name: "CardTypesInsert",
        component: () =>
            import(/* webpackChunkName: "card-types" */ "../views/cards/CardTypesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARDS],
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
];