
    import {Component} from "vue-property-decorator";
    import BaseView from "@/views/base/BaseView.vue";
    import {ElForm} from "element-ui/types/form";
    import {AxiosResponse} from "axios";
    import {Configuration} from "@/common/config";
    import PageTitle from "@/components/PageTitle.vue";
    import {errorService, serviceDataApi, serviceDataStore, storageService} from "@/services/service-container";
    import {saveAs} from "file-saver";

    @Component({
        components: {
            PageTitle
        },
    })
    export default class Profile extends BaseView {
        $refs!: {
          inpFile: HTMLInputElement;
          passForm: ElForm;
          profileForm: ElForm;
        }

        private imgKey: number = 0;
        private avatarPlaceholder = `${process.env.BASE_URL}profile.png`;

        private passFormData: IUserPasswordFormData = {
            password: '',
            repeatPassword: ''
        };
        private emailFormData: IUserProfileFormData = {
            name: '',
            surname: ''
        };

        private loading: boolean = false;
        private userData: ILoginUserData | null = {} as ILoginUserData;

        private themeSettings: IThemeSettings = {
          allUpper: false,
          fontSize: 12,
        } as IThemeSettings;

        private emailRules = {
            name: [{ required: true, message: this.$t('Profile.ErrInputName').toString(), trigger: 'blur' }],
            surname: [{ required: true, message: this.$t('Profile.ErrInputSurname').toString(), trigger: 'blur' }]
        };

        private rules = {
            password: [{ required: false, trigger: 'blur', validator: this.validatePass }],
            repeatPassword: [{ required: false, trigger: 'blur', validator: this.validatePass2 }],
        };

        private clearSettingsBtn(): void {
          this.clearSettings();
          this.$notify({
            title: '',
            type: 'success',
            duration: 5000,
            message: this.$t('common.SettingsCleared').toString(),
            position: 'bottom-right',
          });
        }

        public requestUploadFile(): void {
          if (this.$refs.inpFile.files) {
          const reader = new FileReader();
            reader.onload = ev => {
              try {
                const str = String(ev.target?.result);
                const data = JSON.parse(decodeURI(str));
                if (data && data.settings) {
                  this.clearSettings();
                  const keys = Object.keys(data.settings);
                  for (const key of keys) {
                    storageService.setItem(key, data.settings[key]);
                  }
                  this.$notify({
                    title: '',
                    type: 'success',
                    duration: 5000,
                    message: this.$t('common.SettingsLoaded').toString(),
                    position: 'bottom-right',
                  });
                }
              } catch (e) {
                console.error(e);
              }
            }
            reader.readAsBinaryString(this.$refs.inpFile.files[0]);
          }
        }

        private clearSettings(): void {
          const userId = serviceDataStore.userId;
          const keys = storageService.getUserKeys();
          keys.forEach((key) => {
            if (![`${userId}__user_session`, `${userId}__user_data`, `${userId}__user_access`].includes(key)) {
              storageService.removeItem(key);
            }
          })
        }

        public onSuccess(response: { filename: string }): void {
            if (this.userData) {
                this.userData.avatar = response.filename;
            }
            this.imgKey += 1;
        }

        private clearAvatar(): void {
          try {
            serviceDataApi.delete('files/avatar', this.userData?.id ?? 0).then(() => {
              if (this.userData) {
                this.userData.avatar = '';
                serviceDataStore.setUserData(this.userData);
              }
              this.imgKey += 1;
            });
          } catch (err) {
            throw err;
          }
        }

        private avatar(): string {
            if (this.userData && (this.userData.avatar || '' !== '')) {
                return Configuration.serverAddress + this.userData.avatar + '?' + new Date().getTime();
            }
            return this.avatarPlaceholder;
        }

        mounted(): void {
            this.userData = serviceDataStore.getUserData();
            this.themeSettings = serviceDataStore.themeSettings;
            this.emailFormData.name = this.userData?.name || '';
            this.emailFormData.surname = this.userData?.surname || '';
        }

        private get uploadUri(): string {
            return Configuration.apiEndpoint + 'files/avatar';
        }

        private get getHeaders() {
            return {
                "access-token": serviceDataStore.getSessionId()
            }
        }

        private updateTheme(): void {
          serviceDataStore.themeSettings.allUpper = this.themeSettings.allUpper;
          serviceDataStore.themeSettings.fontSize = this.themeSettings.fontSize;
          storageService.setItem('theme_data', JSON.stringify(this.themeSettings));

          if (serviceDataStore.themeSettings.allUpper) {
            document.body.classList.add('themeAllUpper');
          } else {
            document.body.classList.remove('themeAllUpper');
          }

          document.body.style.fontSize = serviceDataStore.themeSettings.fontSize + 'px';
        }

        private submitDataForm(): void {
            const ref: ElForm = this.$refs.profileForm as ElForm;
            ref.validate().then((result: boolean) => {
                if (result) {
                    this.loading = true;

                    serviceDataApi.saveUserProfile(this.emailFormData).then((response: AxiosResponse) => {
                        if (response && response.data.status) {

                            this.userData = serviceDataStore.getUserData();
                            this.userData.name = this.emailFormData.name;
                            this.userData.surname = this.emailFormData.surname;
                            serviceDataStore.setUserData(this.userData);

                            this.$notify({
                                title: '',
                                type: 'success',
                                duration: 5000,
                                message: this.$t('common.DataSavedSuccessfully').toString(),
                                position: 'bottom-right',
                            });

                            this.loading = false;
                        } else {
                            this.$notify({
                                title: '',
                                duration: 5000,
                                message: this.$t('common.DataSaveError').toString(),
                                position: 'bottom-right',
                                type: 'error'
                            });
                            this.loading = false;
                        }
                    }).catch(() => {
                        this.loading = false;
                    });
                }
            });
        }

        private importSettings(): void {
          this.$refs.inpFile.click();
        }

        private exportSettings(): void {
          const sett: Record<string, string> = {};

          const userId = serviceDataStore.userId;
          const keys = storageService.getUserKeys();
          keys.forEach((key) => {
            if (![`${userId}__user_session`, `${userId}__user_data`, `${userId}__user_access`].includes(key)) {
              sett[key] = storageService.getItem(key) ?? '';
            }
          })

          const settData = JSON.stringify({
            settings: sett
          });
          saveAs(new Blob([encodeURI(settData)], { type: 'application/octet-stream' }), 'userSettings.json');
          this.$notify({
            title: '',
            type: 'success',
            duration: 5000,
            message: this.$t('common.SettingsSaved').toString(),
            position: 'bottom-right',
          });
        }

        private submitPasswordForm(): void {
            const ref: ElForm = this.$refs.passForm as ElForm;
            ref.validate().then((result: boolean) => {
                if (result) {
                    const userId: number = this.userData ? this.userData?.id : 0;
                    serviceDataApi.setNewPassword(this.passFormData.password, userId).then((response: AxiosResponse) => {
                        if (response && response.data.status) {
                            this.$notify({
                                title: '',
                                type: 'success',
                                duration: 5000,
                                message: this.$t('Profile.PasswordChangedMsg').toString(),
                                position: 'bottom-right',
                            });

                            this.passFormData.password = '';
                            this.passFormData.repeatPassword = '';

                            this.loading = false;
                        } else {
                            this.$notify({
                                title: '',
                                type: 'error',
                                duration: 5000,
                                message: this.$t('Profile.PasswordChangeError').toString(),
                                position: 'bottom-right',
                            });
                            this.loading = false;
                        }
                    }).catch((err) => {
                      errorService.errorHandle(err.response);
                        this.loading = false;
                    });
                } else {
                    this.$notify({
                        title: '',
                        duration: 5000,
                        message: this.$t('Profile.PasswordInvalid').toString(),
                        position: 'bottom-right',
                        type: 'error'
                    })
                }
            });
        }

        // eslint-disable-next-line @typescript-eslint/ban-types
        private validatePass(rule: any, value: string, callback: Function): void {
            if (value.length < 8) {
                callback(new Error(this.$t('Profile.InputPassword').toString()));
            } else {
                if (this.passFormData.repeatPassword !== '') {
                    const ref: ElForm = this.$refs.passForm as ElForm;
                    ref.validateField('repeatPassword');
                }
                callback();
            }
        }

        // eslint-disable-next-line @typescript-eslint/ban-types
        private validatePass2(rule: any, value: string, callback: Function): void {
            if (value === '') {
                callback(new Error(this.$t('Profile.RetypePassword').toString()));
            } else if (value !== this.passFormData.password) {
                callback(new Error(this.$t('Profile.PasswordsDoNotMatch').toString()));
            } else {
                callback();
            }
        }

        private beforeAvatarUpload(file: any) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$notify({
                    title: '',
                    duration: 5000,
                    message: this.$t('Profile.AvatarErrExt').toString(),
                    position: 'bottom-right',
                    type: 'error'
                });
            }
            if (!isLt2M) {
                this.$notify({
                    title: '',
                    duration: 5000,
                    message: this.$t('Profile.AvatarErrSize').toString(),
                    position: 'bottom-right',
                    type: 'error'
                });
            }
            return isJPG && isLt2M;
        }
    }

