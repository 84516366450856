import ApiClientService from '@/services/api-client';
import {AxiosResponse} from 'axios';

export default class DataApiService extends ApiClientService {

    public setNewPassword(password: string, userId: number, loginReset: boolean = false): Promise<AxiosResponse> {
        try {
            return this.post('password/change', { password, id: userId, loginReset });
        } catch (err) {
            throw err;
        }
    }

    public saveUserProfile(emailFormData: IUserProfileFormData): Promise<AxiosResponse> {
        try {
            return this.post('password/profile', { ...emailFormData });
        } catch (err) {
            throw err;
        }
    }

    //method to get a single object by its id
    public async getData(uri: string, request: ISingleDataRequest): Promise<AxiosResponse> {
        try {
            return await this.getObjectData<ISingleDataRequest>(uri, request);
        } catch (err) {
            throw err;
        }
    }

    // method to collect a list of data objects
    public async getList(uri: string, request: IDataRequest): Promise<AxiosResponse> {
        try {
            return await this.getTableData<IDataResponse>(uri, request);
        } catch (err) {
            throw err;
        }
    }

    // method to collet a list of data objects
    public async getExportList(uri: string, request: IDataRequest): Promise<AxiosResponse> {
        try {
            return await this.getTableExportData<IDataResponse>(uri, request);
        } catch (err) {
            throw err;
        }
    }

    public patchData(uri: string, data: any): Promise<AxiosResponse> {
        try {
            return this.patch(uri, { ...data });
        } catch (err) {
            throw err;
        }
    }

    public postData(uri: string, data: any): Promise<AxiosResponse> {
        try {
            return this.post(uri, { ...data });
        } catch (err) {
            throw err;
        }
    }

    public putData(uri: string, data: any): Promise<AxiosResponse> {
        try {
            return this.put(uri, { ...data });
        } catch (err) {
            throw err;
        }
    }

    public registerUser(data: INewUserRegister): Promise<AxiosResponse> {
        try {
            return this.post('register', data);
        } catch (err) {
            throw err;
        }
    }

    public tryLogin(email: string, password: string ): Promise<AxiosResponse> {
        try {
            return this.post('login', { email, password });
        } catch (err) {
            throw err;
        }
    }

    public async saveData(uri: string, data: any, isUpdate: boolean): Promise<AxiosResponse> {
        try {
            if (isUpdate) {
                return this.put(uri, { ...data });
            } else {
                return this.post(uri, { ...data });
            }
        } catch (err) {
            throw err;
        }
    }

}
