
import {Component, Vue} from "vue-property-decorator";
import {ElForm} from "element-ui/types/form";
import {errorService, serviceDataApi} from "@/services/service-container";
import {AxiosResponse} from "axios";
import {ElInput} from "element-ui/types/input";

@Component({
  components: {},
})
export default class PasswordChangeDialog extends Vue {

  private dialog: boolean = false;
  private dialogResolve: any;
  private dialogReject: any;
  private fInputTitle: string = '';
  private userId: number = 0;
  private dataLoading: boolean = false;
  private passwordType: string = 'password';
  private passwordRepeatType: string = 'password';

  private passFormData: IUserPasswordFormData = {
    password: '',
    repeatPassword: ''
  };

  private rules = {
    password: [{ required: false, trigger: 'blur', validator: this.validatePass }],
    repeatPassword: [{ required: false, trigger: 'blur', validator: this.validatePass2 }],
  };

  $refs!: {
    inputValField: ElInput;
    passForm: ElForm;
  }

  private passwordTypeClick(): void {
    this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
  }

  private passwordRepeatTypeClick(): void {
    this.passwordRepeatType = this.passwordRepeatType === 'password' ? 'text' : 'password';
  }

  public openDialog(userId: number): Promise<boolean> {
    this.dialog = true;
    this.userId = userId;
    this.fInputTitle = this.$t("Users.PasswordChangeNecessary").toString();
    this.focusInput();
    return new Promise((resolve, reject) => {
      this.dialogResolve = resolve;
      this.dialogReject = reject;
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private validatePass(rule: any, value: string, callback: Function): void {
    if (value.length < 8) {
      callback(new Error(this.$t('Users.InputPassword').toString()));
    } else {
      if (this.passFormData.repeatPassword !== '') {
        const ref: ElForm = this.$refs.passForm as ElForm;
        ref.validateField('repeatPassword');
      }
      callback();
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private validatePass2(rule: any, value: string, callback: Function): void {
    if (value === '') {
      callback(new Error(this.$t('Users.RetypePassword').toString()));
    } else if (value !== this.passFormData.password) {
      callback(new Error(this.$t('Users.PasswordsDoNotMatch').toString()));
    } else {
      callback();
    }
  }

  private submitPasswordForm(): void {
    if (this.dataLoading) {
      return;
    }
    const ref: ElForm = this.$refs.passForm as ElForm;
    ref.validate().then((result: boolean) => {
      if (result) {
        serviceDataApi.setNewPassword(this.passFormData.password, this.userId, false).then((response: AxiosResponse) => {
          if (response && response.data.status) {
            this.$notify({
              title: '',
              type: 'success',
              duration: 5000,
              message: this.$t('Users.PasswordChangedMsg').toString(),
              position: 'bottom-right',
            });

            this.passFormData.password = '';
            this.passFormData.repeatPassword = '';

            this.dataLoading = false;
            this.handleAccept();
          } else {
            this.$notify({
              title: '',
              type: 'error',
              duration: 5000,
              message: this.$t('Users.PasswordChangeError').toString(),
              position: 'bottom-right',
            });
            this.dataLoading = false;
          }
        }).catch((err) => {
          errorService.errorHandle(err);
          this.dataLoading = false;
        });
      } else {
        this.$notify({
          title: '',
          duration: 5000,
          message: this.$t('Users.PasswordInvalid').toString(),
          position: 'bottom-right',
          type: 'error'
        })
      }
    });
  }

  private handleClose(): void {
    this.dialogReject();
    this.dialog = false;
  }

  private handleAccept(): void {
    this.dialogResolve();
    this.dialog = false;
  }

  public focusInput(): void {
    this.$nextTick(() => {
      const input = this.$refs.inputValField.$el.querySelector<HTMLInputElement>('input');
      if (input) {
        input.focus();
      }
    });
  }
}

