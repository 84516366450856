
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import { Debounce } from "vue-debounce-decorator";
  import { searchService } from "@/services/service-container";
  import { ISearchTypeResults } from "@/services/search-service";
  import { ElInput } from "element-ui/types/input";

    @Component
    export default class GlobalSearch extends Vue {
        $refs!: {
          searchResults: HTMLElement;
          searchInput: ElInput;
        }

        private selectedTypeIndex: number = -1;
        private selectedItemIndex: number = -1;

        private searchTxt: string = '';
        private searchData: ISearchTypeResults[] = [];

        @Watch('searchTxt')
        @Debounce(350)
        private onSearchPropertyChanged(): void {
          this.searchData = [];
          if (this.searchTxt.length < 2) {
            return;
          }
          this.selectedTypeIndex = -1;
          this.selectedItemIndex = -1;
          searchService.initSearch(this.searchTxt, (data: ISearchTypeResults) => {
            if (data.type === 'orders-search') {
              // details
              data.data.forEach((item) => {
                const order: IOrderItem = item.data as IOrderItem;
                const strPaint = `${order.product1?.code??''} - ${order.product1?.producer?.name??''} - ${order.product1?.productGloss?.name??''} `
                item.details = [ order.product?.name??'', strPaint ];
              })
            } else if (data.type === 'quotations-search') {
              // details
              data.data.forEach((item) => {
                const order: IQuotation = item.data as IQuotation;
                const strPaint = `${order.orderItem?.product1?.code??''} - ${order.orderItem?.product1?.producer?.name??''} - ${order.orderItem?.product1?.productGloss?.name??''} `
                item.details = [ order.orderItem?.product?.name??'', strPaint ];
              })
            }
            this.selectedTypeIndex = 0;
            this.selectedItemIndex = 0;
            this.searchData.push(data);
          }, 5);
        }

      private keyListener(event: KeyboardEvent) {
        if (event.key === "F1") {
          this.$refs.searchInput.focus();
        }
      }

      created() {
        window.addEventListener("keyup", this.keyListener);
      }

      destroyed() {
        window.removeEventListener("keyup", this.keyListener);
      }

        private getSelectedClass(typeIndex: number, itemIndex: number): string {
          return (this.selectedTypeIndex === typeIndex) && (this.selectedItemIndex === itemIndex) ? 'is-selected' : '';
        }

        private getTitle(key: string): string {
          return this.$t(`GlobalSearch.Type${key.replaceAll('-', '')}`).toString();
        }

        private handleKey(inc: number): void {
          if (this.selectedTypeIndex < 0) return;
          if (this.selectedItemIndex < 0) return;
          if (inc > 0) {
            const typeRes = this.searchData[this.selectedTypeIndex];
            if (typeRes.data.length-1 > this.selectedItemIndex) {
              this.selectedItemIndex += 1;
            } else if (this.searchData.length-1 > this.selectedTypeIndex) {
              this.selectedTypeIndex += 1;
              this.selectedItemIndex = 0;
            }
          } else {
            if (this.selectedItemIndex > 0) {
              this.selectedItemIndex -= 1;
            } else if (this.selectedTypeIndex > 0) {
              this.selectedTypeIndex -= 1;
              const typeRes = this.searchData[this.selectedTypeIndex];
              this.selectedItemIndex = typeRes.data.length-1;
            }
          }

          const htmlList = this.$refs.searchResults.getElementsByClassName('is-selected');
          if (htmlList.length) {
            this.$refs.searchResults.scrollTo({
              top: Math.max((htmlList[0] as HTMLElement).offsetTop - 100, 0),
              behavior: "smooth"
            });
          }
        }

        private clearSearch(): void {
          this.selectedTypeIndex = -1;
          this.selectedItemIndex = -1;
          this.searchTxt = '';
        }

        private handleSearch(): void {
          if (this.selectedTypeIndex < 0) return;
          if (this.selectedItemIndex < 0) return;
          const typeRes = this.searchData[this.selectedTypeIndex];
          const itemRes = typeRes.data[this.selectedItemIndex];
          const route = `/${typeRes.route}/edit/${itemRes.id}`;
          this.clearSearch();
          this.$router.push(route);
          // this.$router.push({ name: 'GlobalSearch', params: {}, query: { search: this.searchTxt } });
        }
    }
