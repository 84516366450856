
import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {},
})
export default class InputDialog extends Vue {

  private dialog: boolean = false;
  private dialogResolve: any;
  private dialogReject: any;

  public fInputValue: string = '';
  public fInputTitle: string = '';

  $refs!: {
    inputValField: Vue;
  }

  public openDialog(title: string = ''): Promise<string> {
    this.dialog = true;
    this.fInputTitle = title;
    this.fInputValue = '';
    this.focusInput();
    return new Promise((resolve, reject) => {
      this.dialogResolve = resolve;
      this.dialogReject = reject;
    });
  }

  private handleReset(): void {
    this.dialogResolve(null);
    this.dialog = false;
  }

  private handleClose(): void {
    this.dialogReject();
    this.dialog = false;
  }

  private handleAccept(): void {
    this.dialogResolve(this.fInputValue);
    this.dialog = false;
  }

  public focusInput(): void {
    this.$nextTick(() => {
      const input = (this.$refs.inputValField as Vue).$el.querySelector<HTMLInputElement>('input');
      if (input) {
        input.focus();
      }
    });
  }
}

