import UserPermission from "@common/access";

export default [
    {
        path: "/stocks-lend",
        name: "StocksLend",
        component: () =>
            import(/* webpackChunkName: "stocks" */ "../views/administration/Stocks/StocksLend.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.STOCK_LEND_READ,
        }
    },
    {
        path: "/stocks-lend/view/:id",
        name: "StocksLendView",
        component: () =>
            import(/* webpackChunkName: "stocks" */ "../views/administration/Stocks/StocksLendEdit.vue"),
        meta: {
            requiresAuth: true,
            viewMode: true,
            requiresAccess: UserPermission.STOCK_LEND_READ,
        }
    },
    {
        path: "/stocks-lend/edit/:id",
        name: "StocksLendEdit",
        component: () =>
            import(/* webpackChunkName: "stocks" */ "../views/administration/Stocks/StocksLendEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.STOCK_LEND_WRITE,
        }
    },
    {
        path: "/stocks-lend/add",
        name: "StocksLendInsert",
        component: () =>
            import(/* webpackChunkName: "stocks" */ "../views/administration/Stocks/StocksLendInsert.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.STOCK_LEND_WRITE,
        },
    },
    {
        path: "/stock-log/:mode/:id",
        name: "StockLog",
        component: () =>
            import(/* webpackChunkName: "stocks" */ "../views/administration/Stocks/StockLog.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.STOCK_CHANGE,
        }
    },
    {
        path: "/stock-log",
        name: "StockLogFull",
        component: () =>
            import(/* webpackChunkName: "stocks" */ "../views/administration/Stocks/StockLog.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.STOCK_CHANGE,
        }
    },

    {
        path: "/stocks",
        name: "Stocks",
        component: () =>
            import(/* webpackChunkName: "stocks" */ "../views/administration/Stocks/Stocks.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.STOCK_READ,
        }
    },
    {
        path: "/stocks-grouped",
        name: "StocksGrouped",
        component: () =>
            import(/* webpackChunkName: "stocks" */ "../views/administration/Stocks/StocksGrouped.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.STOCK_READ,
        }
    },
    {
        path: "/stocks-grouped/view/:productId/:storageId",
        name: "StocksGroupedView",
        component: () =>
            import(/* webpackChunkName: "stocks" */ "../views/administration/Stocks/StocksGroupedEdit.vue"),
        meta: {
            requiresAuth: true,
            viewMode: true,
            requiresAccess: UserPermission.STOCK_READ,
        }
    },
    {
        path: "/stocks/view/:id",
        name: "StocksView",
        component: () =>
            import(/* webpackChunkName: "stocks" */ "../views/administration/Stocks/StocksEdit.vue"),
        meta: {
            requiresAuth: true,
            viewMode: true,
            requiresAccess: UserPermission.STOCK_READ,
        }
    },
    {
        path: "/stocks/edit/:id",
        name: "StocksEdit",
        component: () =>
            import(/* webpackChunkName: "stocks" */ "../views/administration/Stocks/StocksEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.STOCK_WRITE,
        }
    },
    {
        path: "/stocks/add",
        name: "StocksInsert",
        component: () =>
            import(/* webpackChunkName: "stocks" */ "../views/administration/Stocks/StocksInsert.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.STOCK_WRITE,
        },
    },

    {
        path: "/storages",
        name: "Storages",
        component: () =>
            import(/* webpackChunkName: "storages" */ "../views/administration/Storages/Storages.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/storages/edit/:id",
        name: "StoragesEdit",
        component: () =>
            import(/* webpackChunkName: "storages" */ "../views/administration/Storages/StoragesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/storages/add",
        name: "StoragesInsert",
        component: () =>
            import(/* webpackChunkName: "storages" */ "../views/administration/Storages/StoragesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        },
    },
    {
        path: "/stocks/add-multi",
        name: "StockInsertMulti",
        component: () =>
            import(/* webpackChunkName: "stocks" */ "../views/administration/Stocks/StockInsertMulti.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.STOCK_WRITE,
        },
    },
];