import UserPermission from "@common/access";
import Profile from "@/views/Users/Profile.vue";

export default [
    {
        path: "/users",
        name: "Users",
        component: () =>
            import(/* webpackChunkName: "users" */ "../views/Users/Users.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.USERS_READ,
        }
    },
    {
        path: "/users/edit/:id",
        name: "UsersEdit",
        component: () =>
            import(/* webpackChunkName: "users" */ "../views/Users/UsersEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.USERS_WRITE,
        }
    },
    {
        path: "/users/add",
        name: "UsersInsert",
        component: () =>
            import(/* webpackChunkName: "users" */ "../views/Users/UsersEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.USERS_WRITE,
        }
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.USERS_WRITE,
        }
    },
    {
        path: "/roles",
        name: "Roles",
        component: () =>
            import(/* webpackChunkName: "roles" */ "../views/administration/Roles/Roles.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.ROLES_READ,
        }
    },
    {
        path: "/roles/edit/:id",
        name: "RolesEdit",
        component: () =>
            import(/* webpackChunkName: "roles" */ "../views/administration/Roles/RolesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.ROLES_WRITE,
        }
    },
    {
        path: "/roles/add",
        name: "RolesInsert",
        component: () =>
            import(/* webpackChunkName: "roles" */ "../views/administration/Roles/RolesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.ROLES_WRITE,
        }
    },
    {
        path: "/user-work",
        name: "UserWork",
        component: () =>
            import(/* webpackChunkName: "user-work" */ "../views/Users/UserWork.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.USER_WORK_READ,
        }
    },
    {
        path: "/user-work/edit/:id",
        name: "UserWorkEdit",
        component: () =>
            import(/* webpackChunkName: "user-work" */ "../views/Users/UserWorkEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.USER_WORK_WRITE,
        }
    },
    {
        path: "/user-work/add",
        name: "UserWorkInsert",
        component: () =>
            import(/* webpackChunkName: "user-work" */ "../views/Users/UserWorkEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.USER_WORK_WRITE,
        }
    },
];