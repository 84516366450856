import UserPermission from "@common/access";

export default [
    {
        path: "/sms-sent",
        name: "SmsSent",
        component: () =>
            import(/* webpackChunkName: "sms" */ "../views/administration/Sms/SmsSent.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.SMS_READ,
        }
    },
    {
        path: "/sms-sent/edit/:id",
        name: "SmsSentEdit",
        component: () =>
            import(/* webpackChunkName: "sms" */ "../views/administration/Sms/SmsSentEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.SMS_READ,
        }
    },
    {
        path: "/sms-gateway",
        name: "SmsGateway",
        component: () =>
            import(/* webpackChunkName: "sms" */ "../views/administration/Sms/SmsGateway.vue"),
        meta: {
            viewMode: true,
            requiresAuth: true,
            requiresAccess: UserPermission.SMS_WRITE,
        }
    },
];