import {Configuration} from "@/common/config";
import Vue from "vue";
import {ServiceBase} from "@/services/service-base";
import {errorService, serviceDataStore} from "@/services/service-container";

interface INoteConfig {
    icon: string;
    title: string;
    type: string;
    message: string;
    route: string;
    userId: number;
}

export default class Socket extends ServiceBase {

    public appHook: Vue;
    private fSocket: WebSocket;
    public messageListenerInd = 0;
    public messageListener: any[] = [];

    public addListener(listener: MessageCallback): number {
        this.messageListenerInd += 1;
        this.messageListener.push({
            id: this.messageListenerInd,
            listener
        });
        return this.messageListenerInd;
    }

    public removeListener(ind: number): void {
        const index = this.messageListener.findIndex((elem) => {
            return (elem.id === ind);
        });
        if (index > -1) {
            this.messageListener.splice(index, 1);
        }
    }

    private adminLog(log: string, ...data: any): void {
        errorService.adminLog(`SOCKET: ${log}`, data);
    }

    public get connectionStatus(): number {
        return this.fSocket ? this.fSocket.readyState : -1;
    }

    public async disconnect(): Promise<void> {
        if (this.fSocket) {
            this.fSocket.close();
        }
    }

    public async initSocket(): Promise<void> {
        const addr = Configuration.socketAddress;
        this.fSocket = new WebSocket(addr);

        this.appHook.$store.commit('socketConnected', {
            status: this.connectionStatus
        })
        this.fSocket.onopen = () => {
            this.appHook.$store.commit('socketConnected', {
                status: this.connectionStatus
            })
        }
        this.fSocket.onclose = () => {
            this.appHook.$store.commit('socketConnected', {
                status: this.connectionStatus
            })
        }
        this.fSocket.onerror = (data) => {
            this.appHook.$store.commit('socketConnected', {
                status: this.connectionStatus
            })
            console.error('SOCKET ERROR', data);
        }
        this.fSocket.addEventListener('message', (event) => {
            this.processMessage(event);
        });
    }

    private sendData(data: any): void {
        if (this.fSocket && (this.fSocket.readyState === WebSocket.OPEN)) {
            this.adminLog('SEND', data);
            this.fSocket.send(JSON.stringify(data));
        }
    }

    private showNote(noteData: INoteConfig): void {
        const elNote = this.appHook.$notify({
            iconClass: noteData.icon,
            title: noteData.title, //this.appHook.$t('Socket.NewMessage').toString(),
            duration: 0,
            dangerouslyUseHTMLString: true,
            message: noteData.message,
            position: 'bottom-right',
            onClick: () => {
                if (noteData.type === 'message') {
                    this.appHook.$router.push({ name: 'MessagesView', params: { userId: noteData.userId.toString() } }).catch(()=>{});
                } else if (noteData.route.length) {
                    this.appHook.$router.push(noteData.route).catch(()=>{});
                }
                elNote.close();
            },
        });
        const audio = new Audio('/notification.wav');
        audio.play();
    }

    private processAuthorize(event: MessageEvent): void {
        this.sendData({
            msg: 'authorize',
            data: {
                token: serviceDataStore?.getSessionId()
            }
        });
    }

    private async processMessage(event: MessageEvent): Promise<void> {
        try {
            const obj = JSON.parse(event.data);
            this.adminLog('Message from server ', obj);

            if (obj && obj.msg === 'authorize') {
                this.processAuthorize(event);
            } else if (obj && obj.msg === 'message') {
                let res = false;
                if (this.messageListener.length) {
                    for (const listenerObj of this.messageListener) {
                        const bRes = await listenerObj.listener(obj);
                        res = res || bRes;
                    }
                }
                if (!res) {
                    const messageType = obj.messageType ?? 'message';
                    const routeStr = obj.route ?? '';
                    let msgIcon = 'fa fa-envelope-o';
                    if (messageType === 'complaint') {
                        msgIcon = 'fa fa-exclamation-triangle';
                    } else if (messageType === 'leave-card') {
                        msgIcon = 'fa fa-id-badge';
                    }

                    let msgStr = obj.message ?? '';
                    if (obj.messageStr) {
                        msgStr = this.appHook.$t(obj.messageStr).toString();
                    }

                    this.appHook.$store.dispatch('incMessages');

                    this.showNote({
                        icon: msgIcon,
                        title: obj.user,
                        type: messageType,
                        message: msgStr,
                        route: routeStr,
                        userId: obj.userId,
                    });
                }
            } else {
                this.adminLog('Unknown message from server ', event.data);
            }
        } catch (e) {
            this.adminLog('Exception processing message from server ', event.data, e);
        }
    }

}
