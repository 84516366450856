var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('editor',{attrs:{"apiKey":_vm.editorToken,"init":{
            height: 250,
            menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | link image | code | help'
            }},model:{value:(_vm.editorContent),callback:function ($$v) {_vm.editorContent=$$v},expression:"editorContent"}})
}
var staticRenderFns = []

export { render, staticRenderFns }