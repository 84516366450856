
    import {Component} from "vue-property-decorator";
    import BaseEDITView from "@/views/base/BaseEDITView.vue";
    import DatePicker from "@/components/custom/DatePicker.vue";
    import TextEditor from "@/components/custom/TextEditor.vue";
    import CustomerCategorySelect from "@/components/select/CustomerCategorySelect.vue";
    import PageEditTitle from "@/components/PageEditTitle.vue";

    @Component({
        components: {
            CustomerCategorySelect,
            DatePicker,
            PageEditTitle,
            TextEditor
        },
    })
    export default class CustomerCategoriesListEdit extends BaseEDITView {

        private fCustomerCategory: ICustomerCategoryList = {} as ICustomerCategoryList;

        public formRules = {
            customerCategoryId: [{ type: 'number', required: true, min: 1, message: this.$t('CustomerCategoryList.ErrInputCategory').toString(), trigger: 'blur' }],
        };

        private changeCategory(id: number): void {
            this.fCustomerCategory.customerCategoryId = id ?? 0;
        }

        public get customerCategory(): ICustomerCategoryList {
            return this.fCustomerCategory;
        }

        protected loadData(data: ICustomerCategoryList): void {
            this.fCustomerCategory = data;
        }

        protected getPostData(): any {
            return {
                customerId: this.parentId ?? this.fCustomerCategory.customerId,
                customerCategoryId: this.fCustomerCategory.customerCategoryId,
            };
        }

        public created(): void {
            this.dataEndpoint = 'customer-categories-list';
            this.pageTitle = this.$t('CustomerCategories.InsertPageTitle').toString();
        }

        protected getAfterSaveRoute(): string {
            return 'Customers';
        }
    }

