
    import { Component, Prop, Vue } from "vue-property-decorator";
    import FilterableSelect from "@/components/custom/FilterableSelect.vue";
    import {storageService} from "@/services/service-container";

    @Component({
        components: {
            FilterableSelect
        },
    })
    export default class StorageSelect extends Vue {
        @Prop({ default: null })
        public value: number;

        @Prop({ default: false })
        public isDisabled: boolean;

        @Prop({ default: true })
        public isClearable: boolean;

        @Prop({ default: false })
        private chooseFirst: boolean;

        public filterMode: boolean = false;

        public storageChanged(id: number): void {
            this.$emit('input', id);
        }

        public dataLoaded(data: ISearchItem[]): void {
            if ((this.value === 0 || this.value === null) && data.length && this.chooseFirst) {
                this.storageChanged(data[0].id);
            }
        }

        public saveFilters(filterKey: string): void {
            if (this.value ?? 0 > 0) {
              storageService.setItem(`${filterKey}_flt_storageId`, this.value.toString());
            } else {
              storageService.removeItem(`${filterKey}_flt_storageId`);
            }
        }

        public loadFilters(filterKey: string): void {
            this.filterMode = true;
            const str = storageService.getItem(`${filterKey}_flt_storageId`);
            if (str.length) {
                this.$emit('input', parseInt(str, 10));
            }
        }

        public clearFilter(): void {
            this.$emit('input', null);
        }
    }
