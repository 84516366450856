
import { Component, Prop, Vue } from "vue-property-decorator";
import FilterableSelect from "@/components/custom/FilterableSelect.vue";
import {storageService} from "@/services/service-container";

@Component({
  components: {
    FilterableSelect
  },
})
export default class PaymentMethodsSelect extends Vue {
  @Prop({ default: null })
  public value: number;

  @Prop({ default: false })
  public isDisabled: boolean;

  @Prop({ default: true })
  public isClearable: boolean;

  @Prop({ default: true })
  private fullLoad: boolean;

  public filterMode: boolean = false;

  public placeChanged(id: number): void {
    this.$emit('input', id);
  }

  public saveFilters(filterKey: string): void {
    if (this.value ?? 0 > 0) {
      storageService.setItem(`${filterKey}_flt_paymentMethodId`, this.value.toString());
    } else {
      storageService.removeItem(`${filterKey}_flt_paymentMethodId`);
    }
  }

  public loadFilters(filterKey: string): void {
    this.filterMode = true;
    const str = storageService.getItem(`${filterKey}_flt_paymentMethodId`);
    if (str.length) {
      this.$emit('input', parseInt(str, 10));
    }
  }

  public clearFilter(): void {
    this.$emit('input', null);
  }
}
