
    import {Component, Prop, Vue} from "vue-property-decorator";
    import {Configuration} from "@/common/config";
    import Editor from '@tinymce/tinymce-vue'

    @Component({
        components: {
            'editor': Editor,
        },
    })
    export default class TextEditor extends Vue {
        @Prop({ default: ''})
        private value: string;
        private editorValue: string;

        private get editorContent(): string {
            return this.editorValue ?? this.value;
        }

        private set editorContent(value: string) {
            this.editorValue = value;
            this.$emit('input', value);
        }

        private get editorToken(): string {
            return Configuration.editorToken;
        }
    }
