

import {Component, Prop, Vue} from "vue-property-decorator";
import {serviceDataApi, serviceDataStore, serviceSocket} from "@/services/service-container";
import {AxiosResponse} from "axios";
import {Configuration} from "@common/config";
import {getImgUrl} from "@common/functions";
import moment from "moment/moment";
import Loader from "@/components/custom/Loader.vue";
import MessageItem from "@/views/Messages/MessageItem.vue";

@Component({
  components: {
    Loader,
    MessageItem
  },
})
export default class MessageWindow extends Vue {

  $refs!: {
    msgList: HTMLElement;
  }

  @Prop({ default: null })
  public contact: IMsgPerson;

  @Prop({ default: false })
  public isModal: boolean;

  private routeUserId: number = 0;
  private selfUserId: number = 0;

  private isMinimized: boolean = false;
  private isBlinking: boolean = false;
  private newMsg: string = '';
  private fMessages: IMessage[] = [];
  private tableIsLoading: boolean = false;
  private tableLoading: boolean = false;
  private removeListenerId: number = 0;

  mounted() {
    this.selfUserId = serviceDataStore.getUserData().id;
    this.routeUserId = parseInt(this.$route.params.userId, 10);
    this.loadMessages();
    this.removeListenerId = serviceSocket.addListener(this.msgListener);
    this.isBlinking = this.contact.isBlinking ?? false;
    this.isMinimized = this.contact.isMinimized ?? false;
  }

  private async msgListener(obj: any): Promise<boolean> {
    if (obj.msg !== 'message') return false;
    if (this.contact && obj.userId && (this.contact.id === obj.userId)) {
      console.log('blinked?');
      this.loadMessages();
      if (this.isMinimized) {
        console.log('blinked');
        this.isBlinking = true;
      }
      return true;
    }
    return false;
  }

  destroyed(){
    serviceSocket.removeListener(this.removeListenerId);
  }

  private toggleWindow(event: MouseEvent): void  {
    event.stopPropagation();
    if (!this.isModal) return;
    this.isMinimized = !this.isMinimized;
    this.isBlinking = false;
    this.scrollToEnd();
  }

  private closeWindow(event: MouseEvent): void  {
    event.stopPropagation();
    this.$emit('close');
  }

  private addMessage(newMsg: IMessage): void {
    this.fMessages.push(newMsg);
    this.scrollToEnd();
  }

  private scrollToEnd(): void {
    this.$nextTick(() => {
      if (this.$refs.msgList) {
        this.$refs.msgList.scrollBy({left: 0, top: 10000});
      }
    });
  }

  private sendMsgKey(): void {
    if (this.newMsg.length && this.contact && this.contact.id) {
      this.sendMessage();
    }
  }

  private sendMessage(): void {
    this.initLoading();
    const msg = this.newMsg.trim().slice(0, 255);
    this.newMsg = '';
    serviceDataApi.postData('messages', {
      message: msg,
      targetUserId: this.contact.id,
    }).then((response: AxiosResponse) => {
      if (response && (response.status == 200) && response.data) {
        this.addMessage(response.data);
      }
    }).catch(() => {
      this.finalizeLoading();
      this.$notify({
        title: this.$t('DataRequest.ErrorTitle').toString(),
        type: 'error',
        duration: 5000,
        message: this.$t('Messages.UnknownError').toString(),
        position: 'bottom-right',
      });
    }).finally(() => {
      this.finalizeLoading();
    });
  }

  private getDatePart(msg: IMessage): string {
    return moment(msg.sendDate).format("YYYY-MM-DD").trim();
  }

  private showDate(ind: number): boolean {
    if (ind === 0) {
      return true;
    } else {
      if (this.getDatePart(this.fMessages[ind]) !== this.getDatePart(this.fMessages[ind-1])) {
        return true;
      }
    }
    return false;
  }

  private getName(person: IMsgPerson): string {
    if (!person) {
      return '';
    }
    let res = `${person.surname} ${person.name}`;
    if (!res.trim().length) {
      return `Unknown person [${person.id}]`;
    }
    return res.trim();
  }

  private getAvatar(person: IMsgPerson): string {
    if (person && (person.avatar || '' !== '')) {
      return Configuration.serverAddress + person.avatar;
    }
    return getImgUrl('users/avatar-m.jpg');
  }

  private initLoading(): void {
    this.tableIsLoading = true;
    setInterval(() => {
      this.tableLoading = this.tableIsLoading;
    }, 250);
  }

  private finalizeLoading(): void {
    this.tableIsLoading = false;
    this.tableLoading = false;
  }

  private loadMessages(): void {
    if (!this.contact) {
      return;
    }
    const filterObj: ITableDataFilter = {
      search: ''
    };

    const reqData: IDataRequest = {
      page: 0,
      itemsPerPage: 0,
      filter: JSON.stringify(filterObj)
    };

    this.initLoading();
    serviceDataApi.getList(`messages/${this.contact.id}`, reqData).then((response: AxiosResponse) => {
      if (response && (response.status == 200) && (response.data.items)) {
        this.fMessages = response.data.items.map((elem) => {
          return {
            ...elem,
            isTimeVisible: false,
          }
        });
        this.contact.unread = 0;
        this.scrollToEnd();
      }
    }).catch(() => {
      this.finalizeLoading();
    }).finally(() => {
      this.finalizeLoading();
    });
  }
}

