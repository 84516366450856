
import {Component, Vue} from "vue-property-decorator";
import Menu from "@/components/Menu/Menu.vue";
import AdminAppBar from "@/components/AdminAppBar.vue";
import AppBrandFooter from "@/components/AppBrandFooter.vue";
import {dialogService, serviceDataStore, serviceHeartbeat, storageService} from "@/services/service-container";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import SmsGatewayDialog from "@/views/administration/Sms/SmsGateway.vue";
import UserPermission from "@common/access";
import PasswordChangeDialog from "@/components/dialogs/PasswordChangeDialog.vue";
import MessagesConversations from "@/views/Messages/MessagesConversations.vue";

@Component({
        components: {
            PasswordChangeDialog,
            Menu,
            AdminAppBar,
            AppBrandFooter,
            ConfirmDialog,
            SmsGatewayDialog,
            MessagesConversations
        },
    })
    export default class Layout extends Vue {
        $refs!: {
          smsDlg: SmsGatewayDialog;
          confirmDlg: ConfirmDialog;
          passwordDlg: PasswordChangeDialog;
        }

        private menuClosed: boolean = false;

        private get smsEnabled(): boolean {
          return this.$store.state.smsEnabled
              && serviceDataStore.hasRight(UserPermission.SMS_WRITE);
        }

        mounted() {
            this.menuClosed = (storageService.getItem('menuClosed', '0') === '1');
            serviceDataStore.loadDefaults();
            serviceHeartbeat.sendHeartBeat();

            this.$nextTick(() => {
              dialogService.confirmDlg = this.$refs.confirmDlg;
              dialogService.smsDlg = this.$refs.smsDlg;
              dialogService.passwordDlg = this.$refs.passwordDlg;
            });
        }

        private get sideWidth(): string {
            return this.menuClosed ? '75px' : '300px'
        }

        private menuToggle(): void {
            this.menuClosed = !this.menuClosed;
          storageService.setItem('menuClosed', this.menuClosed ? '1' : '0');
        }
    }
