
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DataTable from "@/components/custom/DataTable.vue";

    @Component({
        components: {
        },
    })
    export default class GridEditPartial extends Vue {
        @Prop({})
        protected parentId: number;

        @Prop({ default: false })
        protected withoutParent: boolean;

        @Prop({ default: false })
        protected disabled: boolean;

        private editId: number = 0;
        private editMode: string = '';

        protected onParentChanged(parentId: number): void {
          //
        }

        @Watch('parentId')
        onParentChange(): void {
          this.onParentChanged(this.parentId);
        }

        protected canLoadData(): boolean {
          return this.parentId > 0;
        }

        protected addClick(): void {
            this.editId = 0;
            this.editMode = 'INSERT';
        }

        protected editClick(id: number): void {
            this.editId = id;
            this.editMode = 'EDIT';
        }

        protected viewClick(id: number): void {
          this.editId = id;
          this.editMode = 'VIEW';
        }

        protected onEditClose(): void {
            this.editMode = '';
            this.editId = 0;
        }

        public refreshDataTable() {
          const cmp: DataTable = (this.$refs.dataTableComponent as DataTable);
          cmp.loadTableData();
        }

        protected afterSave(mode: string, response: any, saveMode: number = 0): void {
          if (saveMode === 1) {
            this.onEditClose();
          }
          this.refreshDataTable();
        }

    }
