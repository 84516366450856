
import {Component, Prop, Vue} from 'vue-property-decorator'
import router from "@/router";
import {serviceDataStore} from "@/services/service-container";

@Component
export default class LogoutTimer extends Vue {

  @Prop({ default: 0 })
  public timeMinutes: number;

  private timerInt: any;
  private currentTime: number = 0;

  private get isLastMinute(): boolean {
    return this.currentTime <= 60;
  }

  private get timeFormatted(): string {
    const iM = Math.floor(this.currentTime / 60);
    const mM = iM > 9 ? `${iM}` : `0${iM}`;
    const iS = this.currentTime - (iM * 60);
    const sS = iS > 9 ? `${iS}` : `0${iS}`;
    return `${mM}:${sS}`;
  }

  private refreshTimer(): void {
    this.currentTime = this.timeMinutes * 60;
  }

  private soundEffect(): void {
    const audio = new Audio('/notification2.wav');
    audio.play();
  }

  mounted() {
    this.currentTime = this.timeMinutes * 60;
    this.timerInt = setInterval(() => {
      this.currentTime--;

      if ((this.currentTime > 0) && (this.currentTime <= 20) && ((this.currentTime % 5) === 0)) {
        this.soundEffect();
      }

      if (this.currentTime <= 0) {
        clearInterval(this.timerInt)
        router.push({ name: 'Login' });
        serviceDataStore.logOut();
      }
    }, 1000);
  }
}
