
import {Component, Vue} from "vue-property-decorator";

@Component
export default class ChatStatus extends Vue {
  WebSocket: any = WebSocket;

  private get chatStatus(): number {
    return this.$store.state.socketConnected;
  }
}
