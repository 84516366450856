import UserPermission from "@common/access";
import {serviceDataStore} from "@/services/service-container";

export default [

    {
        path: "/order-priorities",
        name: "OrderPriorities",
        component: () =>
            import(/* webpackChunkName: "order-priorities" */ "../views/administration/OrderPriorities/OrderPriorities.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/order-priorities/edit/:id",
        name: "OrderPrioritiesEdit",
        component: () =>
            import(/* webpackChunkName: "order-priorities" */ "../views/administration/OrderPriorities/OrderPrioritiesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/order-priorities/add",
        name: "OrderPrioritiesInsert",
        component: () =>
            import(/* webpackChunkName: "order-priorities" */ "../views/administration/OrderPriorities/OrderPrioritiesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },

    {
        path: "/errornous-reasons",
        name: "ErrornousReasons",
        component: () =>
            import(/* webpackChunkName: "dictionaries" */ "../views/administration/ErrornousReasons/ErrornousReasons.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/errornous-reasons/edit/:id",
        name: "ErrornousReasonsEdit",
        component: () =>
            import(/* webpackChunkName: "dictionaries" */ "../views/administration/ErrornousReasons/ErrornousReasonsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/errornous-reasons/add",
        name: "ErrornousReasonsInsert",
        component: () =>
            import(/* webpackChunkName: "dictionaries" */ "../views/administration/ErrornousReasons/ErrornousReasonsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        },
    },

    {
        path: "/order-items",
        name: "OrderItems",
        component: () => {
            if (serviceDataStore.isCustomer) {
                return import(/* webpackChunkName: "orders" */ "../views/administration/Orders/OrderItemsCustomer.vue");
            } else {
                return import(/* webpackChunkName: "orders" */ "../views/administration/Orders/OrderItems.vue");
            }
        },
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.ORDERS_READ,
        }
    },
    {
        path: "/order-items/edit/:id",
        name: "OrderItemsEdit",
        component: () =>
            import(/* webpackChunkName: "orders" */ "../views/administration/Orders/OrderItemEditSimple.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.ORDERS_WRITE,
        }
    },
    {
        path: "/order-items/view/:id",
        name: "OrderItemsView",
        component: () =>
            import(/* webpackChunkName: "orders" */ "../views/administration/Orders/OrderItemEditCustomer.vue"),
        meta: {
            requiresAuth: true,
            viewMode: true,
            requiresAccess: UserPermission.ORDERS_READ,
        }
    },

    {
        path: "/receptions",
        name: "Receptions",
        component: () =>
            import(/* webpackChunkName: "receptions" */ "../views/administration/Receptions/Receptions.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.RECEPTION_READ,
        }
    },
    {
        path: "/receptions/edit/:id",
        name: "ReceptionsEdit",
        component: () =>
            import(/* webpackChunkName: "receptions" */ "../views/administration/Receptions/ReceptionEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.RECEPTION_WRITE,
        }
    },
    {
        path: "/receptions/add",
        name: "ReceptionsInsert",
        component: () =>
            import(/* webpackChunkName: "receptions" */ "../views/administration/Receptions/ReceptionEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.RECEPTION_WRITE,
        }
    },
    {
        path: "/receptions/view/:id",
        name: "ReceptionsView",
        component: () =>
            import(/* webpackChunkName: "receptions" */ "../views/administration/Receptions/ReceptionEdit.vue"),
        meta: {
            requiresAuth: true,
            viewMode: true,
            requiresAccess: UserPermission.RECEPTION_WRITE,
        }
    },
    {
        path: "/order-item-error-report",
        name: "OrderItemErrorReport",
        component: () =>
            import(/* webpackChunkName: "reports" */ "../views/reports/OrderItemErrorReport.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.REPORTS,
        },
    },
    {
        path: "/amount-diff-report",
        name: "AmountDiffReport",
        component: () =>
            import(/* webpackChunkName: "reports" */ "../views/reports/AmountDiffReport.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.REPORTS,
        },
    },

    {
        path: "/quotations",
        name: "Quotations",
        component: () =>
            import(/* webpackChunkName: "invoices" */ "../views/administration/Invoices/Quotations.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.QUOTATIONS_READ,
        }
    },
    {
        path: "/quotations/edit/:id",
        name: "QuotationsEdit",
        component: () =>
            import(/* webpackChunkName: "invoices" */ "../views/administration/Invoices/QuotationsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.QUOTATIONS_WRITE,
        }
    },
    {
        path: "/quotations/view/:id",
        name: "QuotationsView",
        component: () =>
            import(/* webpackChunkName: "invoices" */ "../views/administration/Invoices/QuotationsView.vue"),
        meta: {
            requiresAuth: true,
            viewMode: true,
            requiresAccess: UserPermission.QUOTATIONS_READ,
        }
    },
    {
        path: "/quotations/add",
        name: "QuotationsInsert",
        component: () =>
            import(/* webpackChunkName: "invoices" */ "../views/administration/Invoices/QuotationsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.QUOTATIONS_WRITE,
        },
    },
];