
export interface IConfiguration {
    baseUrl: string;
    apiEndpoint: string;
    serverAddress: string;
    socketAddress: string;
    editorToken: string;
}

export function getConfiguration(): IConfiguration {
    // produkcyjne api dziala na proxy. Subdomena api przekierowana jest na odpowiedni port po stronie serwera nginx
    const hostN = window.location.hostname.replace('www.', '');
    if (process.env.NODE_ENV === 'production') {
        return {
            baseUrl: `http://${hostN}/`,
            apiEndpoint: `http://api.${hostN}/api/`,
            serverAddress: `http://api.${hostN}/`,
            socketAddress: `ws://${hostN}:3307/ws`,
            editorToken: ''
        }
    } else {
        // w development korzystamy bezposrednio z portu
        return {
            baseUrl: `http://${hostN}/`,
            apiEndpoint: `http://${hostN}:3000/api/`,
            serverAddress: `http://${hostN}:3000/`,
            socketAddress: `ws://${hostN}:3307/ws`,
            editorToken: ''
        }
    }
}

export const Configuration: IConfiguration = getConfiguration();
