
import {Component, Vue} from "vue-property-decorator";
import {servicePrinter} from "@/services/service-container";

@Component
export default class ZebraStatus extends Vue {
  private statusKey: number = 0;
  private fStatus: IPrinterStatus = {
    printerConfigured: false,
    isReadyToPrint: false,
    errors: '',
  };

  mounted() {
    servicePrinter.printerStatus().then((status) => {
      this.fStatus = status;
      this.statusKey += 1;
    });
  }

  private zebraClick(): void {
    this.$router.push({ name: 'ZebraStatus', params: {}} );
  }
}
