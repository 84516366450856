
    import {Component, Prop, Vue} from 'vue-property-decorator'

    @Component
    export default class Loader extends Vue {

        @Prop({ default: false})
        private isFixed: boolean;

        private get isFixedLoader(): boolean {
            return this.isFixed;
        }
    }
