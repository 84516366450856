
    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import moment from "moment";
    import {storageService} from "@/services/service-container";

    @Component
    export default class DatePicker extends Vue {
        @Prop({ default: null })
        private value: string[];

        @Watch('value')
        onValueChange(): void {
            if (this.value && this.value.length) {
                this.pickerDates = this.value;
            }
        }

        @Prop({ default: 'daterange' })
        public type: 'daterange' | 'datetimerange';

        @Prop({ default: 'none' })
        public defaultScope: 'last-month' | 'month' | 'week' | 'day' | 'none';

        @Prop({ default: 'yyyy-MM-dd' })
        public format: string;

        private pickerDates: string[] = [];

        mounted() {
            if (this.value && this.value.length) {
                this.pickerDates = this.value;
            }
        }

        private get fltClass(): string {
            return (this.value && this.value.length) ? 'isFiltered dates-filter' : 'dates-filter';
        }

        private datesChanged(newDates: string[]): void {
            this.$emit('input', newDates);
            this.$emit('change', newDates);
        }

        public saveFilters(filterKey: string): void {
            if (this.value && this.value.length) {
              storageService.setItem(`${filterKey}_datesFilter`, JSON.stringify(this.value));
            } else {
              storageService.removeItem(`${filterKey}_datesFilter`);
            }
        }

        public initScope(): void {
          if (this.defaultScope === 'day') {
            const currentDate: string = moment().format("YYYY-MM-DD");
            const pastDate: string = moment().subtract(1, 'days').format("YYYY-MM-DD");
            this.$emit('input', [pastDate, currentDate]);
          } else if (this.defaultScope === 'week') {
            const fromDate: string = moment().startOf('week').format("YYYY-MM-DD");
            const toDate: string = moment().endOf('week').format("YYYY-MM-DD");
            this.$emit('input', [fromDate, toDate]);
          } else if (this.defaultScope === 'month') {
            const fromDate: string = moment().startOf('month').format("YYYY-MM-DD");
            const toDate: string = moment().endOf('month').format("YYYY-MM-DD");
            this.$emit('input', [fromDate, toDate]);
          } else if (this.defaultScope === 'last-month') {
            const fromDate: string = moment().subtract(1, 'months').format("YYYY-MM-01");
            const toDate: string = moment().format("YYYY-MM-DD");
            this.$emit('input', [fromDate, toDate]);
          } else {
            this.$emit('input', []);
          }
        }

        public loadFilters(filterKey: string): void {
            const str = storageService.getItem(`${filterKey}_datesFilter`);
            if (str.length) {
                this.$emit('input', JSON.parse(str));
            } else {
              this.initScope();
            }
        }

        public clearFilter(): void {
            this.pickerDates = [];
            this.$emit('input', null);
        }
    }
