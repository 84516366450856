/*
<template>
        <div :class="{ 'modal-edit': isModal }" @click.self="clickModalBg">
            <div class="edit-content">
                <el-card elevation="1">
                    <div slot="header" class="clearfix">
                        <span>{{ pageTitle }}</span>
                    </div>
                    <v-card-text>
                        ALERT: COPY BASE VIEW TEMPLATE TO ITS INHERITANT AND FILL IN INPUTS
                    </v-card-text>
                </el-card>
            </div>
        </div>
</template>
*/
import {Component, Prop, Watch} from "vue-property-decorator";
import {AxiosResponse} from "axios";
import BaseView from "@/views/base/BaseView.vue";
import { Loading } from 'element-ui';
import {ElLoadingComponent} from "element-ui/types/loading";
import {serviceDataApi} from "@/services/service-container";

    @Component({
        components: {
        },
    })
    export default class BaseVIEWView extends BaseView {
        @Prop({ default: 0 })
        protected modalViewId: number;

        @Prop({ default: '' })
        protected modalViewMode: string;

        @Prop({ default: null })
        private onDefaultData: DefaultDataCallbackMethod;

        @Prop({ default: null })
        private onDefaultDataEx: DefaultDataCallbackMethod;

        @Watch('modalViewId')
        onModalViewIdChanged(): void {
            this.getDataFromApi();
        }

        @Prop({ default: null })
        public onCloseModal: ClickCallbackMethod;

        public pageTitle: string = '';
        protected dataEndpoint: string = '';
        protected focusOnStartRefName: string = '';

        protected loadedData: any;
        private fLoadingInstance: ElLoadingComponent;
        public fDataIsLoading: boolean = false;
        private fDataLoading: boolean = false;

        public get isModal(): boolean {
            return (this.modalViewMode !== '');
        }

        public get dataLoading(): boolean {
            return this.fDataLoading;
        }
        public set dataLoading(value: boolean) {
            this.fDataLoading = value;
            if (this.fDataLoading && !this.fLoadingInstance) {
                this.fLoadingInstance = Loading.service({ target: '#app', fullscreen: false });
            } else if (this.fLoadingInstance) {
                this.fLoadingInstance.close();
            }
        }

        public initLoading(): void {
            this.fDataIsLoading = true;
            // setTimeout(() => {
                this.dataLoading = this.fDataIsLoading;
            // }, 250);
        }

        public finalizeLoading(): void {
            this.fDataIsLoading = false;
            this.dataLoading = false;
        }

        public get formDisabled(): boolean {
            return Boolean((this.modalViewMode === 'VIEW')
                || (!this.isModal && this.$route.meta && this.$route.meta.viewMode))
                || (!this.canEditData);
        }

        public get canEditData(): boolean {
          return true;
        }

        public get closeBtnVisible(): boolean {
            return true; // (this.modalViewMode !== '');
        }

        public get closeBtnLabel(): string {
            if (this.formDisabled || (this.modalViewMode === 'VIEW')) {
                return this.$t('common.Close').toString();
            } else {
                return this.$t('common.Cancel').toString();
            }
        }

        public closeModalForm(): void {
            if (this.onCloseModal !== null) {
                this.onCloseModal();
            }
        }

        public clickModalBg(event: MouseEvent): void {
            if (!event.target || (!(event.target as HTMLElement).classList.contains('modal-edit'))) {
                return;
            }
            if (this.onCloseModal !== null) {
                this.onCloseModal();
            }
            event.stopPropagation();
        }

        public mounted(): void {
            this.getDataFromApi();
            this.$nextTick(() => {
              this.focusOnStart();
            });
        }

        created() {
          window.addEventListener("keyup", this.keyListener);
        }

        destroyed() {
          window.removeEventListener("keyup", this.keyListener);
        }

        private keyListener(event: KeyboardEvent) {
          if (!this.isModal) {
            return;
          }
          if (event.key === "Escape") {
            if (this.onCloseModal !== null) {
              this.onCloseModal();
            }
          }
        }

        private focusOnStart(): void {
            if (!this.focusOnStartRefName) return;

            const element = (this.$refs[this.focusOnStartRefName] as Vue);
            if (!element) return;

            const input = element.$el.querySelector<HTMLInputElement>('input');
            if (input) {
                input.focus();
                return;
            }

            const txtArea = element.$el.querySelector<HTMLInputElement>('textarea');
            if (txtArea) {
              txtArea.focus();
            }
        }

        private isValidId(id: string): boolean {
            const num: number = parseInt(id, 10);
            return !isNaN(num);
        }

        public get objectId(): number {
            if (this.modalViewMode !== '') {
                return this.modalViewId;
            } else if (this.$route.params.id && this.isValidId(this.$route.params.id)) {
                return parseInt(this.$route.params.id, 10);
            } else {
                return 0;
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        protected loadData(data: any): void {
            //
        }

        protected getDefaultData(): any {
            if (this.onDefaultData) {
                return this.onDefaultData();
            }
            return {}
        }

        protected getDataFromApi(): void {
            if ((this.dataEndpoint !== '') && (this.objectId > 0)) {
                this.initLoading();
                const reqData: ISingleDataRequest = {
                    id: this.objectId.toString()
                };
                serviceDataApi.getData(this.dataEndpoint, reqData).then((response: AxiosResponse) => {
                    if (response && (response.status == 200)) {
                        this.loadedData = response.data;
                        this.loadData(response.data);
                    } else {
                        this.$notify({
                            title: this.$t('DataRequest.ErrorTitle').toString(),
                            type: 'error',
                            duration: 5000,
                            message: response && response.data.error ? response.data.error : this.$t('DataRequest.UnknownError').toString(),
                            position: 'bottom-right',
                        });
                    }
                }).catch(() => {
                    this.finalizeLoading();
                }).finally(() => {
                    this.finalizeLoading();
                });
            } else {
                let defData = {};
                if (this.onDefaultDataEx) {
                  defData = this.onDefaultDataEx();
                } else {
                  defData = this.getDefaultData();
                }
                this.loadData(defData);
            }
        }

    }

