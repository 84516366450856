
    import { Component, Vue } from "vue-property-decorator";
    import AppBrandFooter from "@/components/AppBrandFooter.vue";

    @Component({
        components: {
            AppBrandFooter
        },
    })
    export default class PlainLayout extends Vue {

    }
