import {
    InvoiceStatus,
    InvoiceStatusColor,
    OrderStatus,
    OrderStatusColors,
    QuotationStatus,
    QuotationStatusColor
} from "@/common/enums";

export function isNullOrUndefined(value: any): boolean {
    return Object.is(value, null) || (typeof value === 'undefined');
}

export function isExternal(path: string): boolean {
    return /^(https?:|mailto:|tel:)/.test(path)
}

export function isValidUsername(str: string): boolean {
  return (str.trim().length >= 5) && validateEmail(str);
}

export function validatePhone(phone: string): boolean {
    const re = /^[+]?[0-9]{7,12}$/im;
    return re.test(String(phone).toLowerCase());
}

export function validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validateNameSurname(name: string): boolean {
    const re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýśżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŚŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
    return (name.trim().length >= 3) && re.test(name.toLowerCase());
}

export function getImgUrl(img: string): string {
    return require('@/assets/' + img);
}

export function colorMap(value: number): string {
    switch(value) {
        case 0: return '#000000';
        case 1: return '#000080';
        case 2: return '#FF0000';
        case 3: return '#00FF00';
        case 4: return '#0000FF';
        case 5: return '#FFFF00';
        case 6: return '#00FFFF';
        case 7: return '#FF00FF';
        case 8: return '#C0C0C0';
        case 9: return '#808080';
        case 10: return '#800000';
        case 11: return '#808000';
        case 12: return '#008000';
        case 13: return '#800080';
        case 14: return '#008080';
        default:
            return '#000000';
    }
}

export default function openWindow(url: string, title: string, w: number, h: number): void {
    // Fixes dual-screen position                            Most browsers       Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : 0;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : 0;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const left = ((width / 2) - (w / 2)) + dualScreenLeft;
    const top = ((height / 2) - (h / 2)) + dualScreenTop;
    const newWindow = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (newWindow) {
        newWindow.focus();
    }
}

export function parseTime(
    time?: string | number | null,
    cFormat?: string
): string | null {
    if (time === undefined || !time) {
        return null;
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date: Date;
    if (typeof time === 'object') {
        date = time as Date;
    } else {
        if (typeof time === 'string') {
            if (/^[0-9]+$/.test(time)) {
                // support "1548221490638"
                time = parseInt(time);
            } else {
                // support safari
                // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
                time = time.replace(/-/gm, '/');
            }
        }
        if (typeof time === 'number' && time.toString().length === 10) {
            time = time * 1000;
        }
        date = new Date(time);
    }
    const formatObj: { [key: string]: number } = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    };
    const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value];
        }
        return value.toString().padStart(2, '0');
    });
    return timeStr;
}

export function formatJson(filterKeys: string[], jsonData: any): any {
    return jsonData.map((data: any) => filterKeys.map((key: string) => {
        if (key === 'timestamp') {
            return parseTime(data[key]);
        } else {
            return data[key];
        }
    }));
}

export function getRandomNumString(length: number): string {
    const randomChars = '0123456789';
    let result = '';
    for (let i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
}
export function getRandomString(length: number): string {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
}
function escapeRegExp(data: string) {
    return data.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
export function replaceAll(str: string, find: string, replace: string): string {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export function escapeDiacritics(value: string): string {
        return value.replace(/ą/g, 'a').replace(/Ą/g, 'A')
            .replace(/ć/g, 'c').replace(/Ć/g, 'C')
            .replace(/ę/g, 'e').replace(/Ę/g, 'E')
            .replace(/ł/g, 'l').replace(/Ł/g, 'L')
            .replace(/ń/g, 'n').replace(/Ń/g, 'N')
            .replace(/ó/g, 'o').replace(/Ó/g, 'O')
            .replace(/ś/g, 's').replace(/Ś/g, 'S')
            .replace(/ż/g, 'z').replace(/Ż/g, 'Z')
            .replace(/ź/g, 'z').replace(/Ź/g, 'Z');
}

export function invoiceStatusColor(value: number): string {
    switch (value) {
        case InvoiceStatus.NEW: return InvoiceStatusColor.NEW;
        case InvoiceStatus.CONFIRMED: return InvoiceStatusColor.CONFIRMED;
        case InvoiceStatus.INVOICED: return InvoiceStatusColor.INVOICED;
        case InvoiceStatus.CANCELLED: return InvoiceStatusColor.CANCELLED;
        default:
            return '#000000';
    }
}

export function quotationStatusColor(value: number): string {
    switch (value) {
        case QuotationStatus.NONE: return QuotationStatusColor.NONE;
        case QuotationStatus.NEW: return QuotationStatusColor.NEW;
        case QuotationStatus.CONFIRMED: return QuotationStatusColor.CONFIRMED;
        case QuotationStatus.INVOICED: return QuotationStatusColor.INVOICED;
        case QuotationStatus.CANCELLED: return QuotationStatusColor.CANCELLED;
        default:
            return '#000000';
    }
}

export function leaveCardStatusColor(value: number): string {
    switch (value) {
        case 0: return '#000000';
        case 5: return '#122ea2';
        case 7: return '#46d300';
        case 9: return '#FF0000';
        default:
            return '#000000';
    }
}

export function orderStatusColor(value: number): string {
    switch(value) {
        case OrderStatus.NONE: return OrderStatusColors.NONE;
        case OrderStatus.NEW: return OrderStatusColors.NEW;
        case OrderStatus.CONFIRMED: return OrderStatusColors.CONFIRMED;
        case OrderStatus.PLANNED: return OrderStatusColors.PLANNED;
        case OrderStatus.IN_PROGRESS: return OrderStatusColors.IN_PROGRESS;
        case OrderStatus.DONE: return OrderStatusColors.DONE;
        case OrderStatus.READY_TO_BE_PICK_UP: return OrderStatusColors.READY_TO_BE_PICK_UP;
        case OrderStatus.PICKED_UP: return OrderStatusColors.PICKED_UP;
        case OrderStatus.INVOICED: return OrderStatusColors.INVOICED;
        case OrderStatus.PAID: return OrderStatusColors.PAID;
        case OrderStatus.CANCELED: return OrderStatusColors.CANCELED;
        default:
            return '#000000';
    }
}

const fEmotes: Record<string, string> = {
    ':)': '<span class="emote">&#128512;</span>',
    ':(': '<span class="emote">&#128543;</span>',
    ':D': '<span class="emote">&#128513;</span>',
    ';)': '<span class="emote">&#128521;</span>',
    ':p': '<span class="emote">&#128523;</span>',
    ':P': '<span class="emote">&#128523;</span>',
    '-_-': '<span class="emote">&#128529;</span>',
    ':/': '<span class="emote">&#128533;</span>',
    ':*': '<span class="emote">&#128535;</span>',
    ':thumb': '<span class="emote">&#128077;</span>',
    ':beers': '<span class="emote">&#127867;</span>',
    ':beer': '<span class="emote">&#127866;</span>',
    ':cake': '<span class="emote">&#127874;</span>',
    ':fuck': '<span class="emote">&#128405;</span>',
    ':fear': '<span class="emote">&#128561;</span>',
    ':angry': '<span class="emote">&#129324;</span>'
};

export function replaceEmotesDef(msg: string): string {
    for (const emote in fEmotes) {
        msg = replaceAll(msg, emote, fEmotes[emote]);
    }
    while (msg.indexOf(':icon-') >= 0) {
        const index = msg.indexOf(':icon-');
        const emoteStr = msg.substring(index, index+12);
        const emoteNum = emoteStr.substring(6);
        msg = replaceAll(msg, emoteStr, `<span class="emote">&#${emoteNum};</span>`);
    }
    return msg;
}

export function genBarcode(prefix: string = ''): string {
    const currDate = new Date();
    const currYear = currDate.getFullYear();
    const currMonth = currDate.getMonth() + 1;
    const currDay = currDate.getDate();

    const randomChars = '0123456789';
    let result = '';
    for (let i = 0; i < 8; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    let monStr = currMonth.toString();
    if (monStr.length === 1) {
        monStr = '0' + monStr;
    }
    let dayStr = currDay.toString();
    if (dayStr.length === 1) {
        dayStr = '0' + dayStr;
    }
    return `${prefix}${currYear}${monStr}${dayStr}${result}`; // ${this.fStock.stockRal}
}