
    import { Component, Vue } from 'vue-property-decorator'
    import { serviceDataStore } from "@/services/service-container";
    import UserPermission from "@common/access";

    @Component
    export default class Shortcuts extends Vue {

        UserPermission: any = UserPermission;

        public hasRight(right: UserPermission): boolean {
            return serviceDataStore.hasRight(right);
        }

        private get newOrders(): number {
          return this.$store.state.ordersCount;
        }

    }
