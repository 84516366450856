
    import { Component, Vue } from "vue-property-decorator";
    import BoxDate from "@/components/main/BoxDate.vue";

    @Component({
        components: {
            BoxDate,
        },
    })
    export default class CustomerDashboard extends Vue {}
