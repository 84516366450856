
  import {Component, Vue} from "vue-property-decorator";
  import Layout from "@/components/Layout.vue";
  import PlainLayout from "@/components/PlainLayout.vue";
  import {dialogService, errorService, serviceSocket, storageService} from "@/services/service-container";

  @Component({
    components: {
      Layout,
      PlainLayout,
    },
  })
  export default class App extends Vue {

    private isSimpleLayout(): boolean {
      return this.$route.meta && this.$route.meta.simpleLayout;
    }

    mounted() {
      errorService.appHook = this;
      serviceSocket.appHook = this;
      dialogService.appHook = this;
      storageService.appHook = this;
      this.$nextTick(() => {
        serviceSocket.initSocket();
      });
    }
  }
