import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueI18n from 'vue-i18n'
import '@/assets/styles.scss' // global css
import {messages, defaultLocale, currentLocale} from "@/i18n/index";
import ElementUI from 'element-ui';
import localeEN from './i18n/element/en'
import localePL from './i18n/element/pl'
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/global.scss' // global css
import { ElInput } from "element-ui/types/input";
import '@/styles/element-variables.scss'

Vue.config.productionTip = false;
Vue.use(VueI18n);

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

let locale;
const language = (localStorage.getItem('user_lang') || 'pl').toString();
if (language === 'pl') {
  locale = localePL;
}  else {
  locale = localeEN;
}

const i18n = new VueI18n({
  messages,
  locale: currentLocale,
  fallbackLocale: defaultLocale
});

// GLOBALLY REGISTERED COMPONENTS
require('./components.ts');

Vue.use(ElementUI, { locale });

Vue.directive('uppercase', {
	update(el, binding, vnode) {
        const elInput = vnode.componentInstance as ElInput;
        if (elInput.value && typeof elInput.value === 'string') {
          elInput.$emit('input', elInput.value.toUpperCase());
        }
	},
});

import store from './stores/counters';

new Vue({
  i18n,
  router,
  render: (h) => h(App),
  store
}).$mount("#app");
