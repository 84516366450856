
import { Component, Prop, Vue } from "vue-property-decorator";
import FilterableSelect from "@/components/custom/FilterableSelect.vue";
import {storageService} from "@/services/service-container";

@Component({
  components: {
    FilterableSelect
  },
})
export default class VatRateSelect extends Vue {
  $refs!: {
    filterElem: FilterableSelect;
  }

  @Prop({ default: null })
  public value: number;

  @Prop({ default: false })
  public isDisabled: boolean;

  @Prop({ default: true })
  public isClearable: boolean;

  public filterMode: boolean = false;

  public getSelectedObject(): ISearchItem|undefined {
    return this.$refs.filterElem.getSelectedObject();
  }

  public statusChanged(id: number): void {
    this.$emit('input', id);
  }

  public statusObjChanged(data: ISearchItem): void {
    this.$emit('changeObject', data.data);
  }

  public saveFilters(filterKey: string): void {
    if (this.value ?? 0 > 0) {
      storageService.setItem(`${filterKey}_flt_vatRateId`, this.value.toString());
    } else {
      storageService.removeItem(`${filterKey}_flt_vatRateId`);
    }
  }

  public loadFilters(filterKey: string): void {
    this.filterMode = true;
    const str = storageService.getItem(`${filterKey}_flt_vatRateId`);
    if (str.length) {
      this.$emit('input', parseInt(str, 10));
    }
  }

  public clearFilter(): void {
    this.$emit('input', null);
  }
}
