
    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import {AxiosResponse} from "axios";
    import {serviceDataApi, serviceDataStore} from "@/services/service-container";
    import {ElSelect} from "element-ui/types/select";
    import UserPermission from "@common/access";

    @Component
    export default class FilterableSelect extends Vue {

        $refs!: {
          filterSelect: ElSelect;
        }

        @Prop({ default: undefined})
        private optionClass: string;

        @Prop({ default: ''})
        private dataEndpoint: string;

        @Prop({ default: null })
        private addon: string;

        @Prop({ default: 'name' })
        private nameField: string;

        @Prop({ default: false })
        private enableAdd: boolean;

        @Prop({ default: false })
        private enableEmpty: boolean;

        @Prop({ default: 'common.SearchKeyword'})
        private placeholderLangId: string;

        @Prop({ default: null })
        private viewAccess: UserPermission;

        @Prop({ default: null })
        private viewRoute: string;

        @Prop({ default: null })
        private listRoute: string;

        @Prop({ default: null })
        private listIcon: string;

        @Prop({ default: null })
        private listParams: any;

        @Prop({ default: 'select' })
        private mode: string;

        @Prop({ default: null })
        private placeholder: string;

        @Prop({ default: null })
        private initialValue: number | null;

        @Prop({ default: false })
        private isDisabled: boolean;

        @Prop({ default: true })
        public isClearable: boolean;

        @Prop({ default: true })
        private fullLoad: boolean;

        @Prop({ default: false })
        private includeData: boolean;

        @Prop({ default: null })
        private onModalChoice: ICallback;

        @Prop({})
        private filters: FilterCallbackMethod;

        @Watch('initialValue')
        onInitialValueChanged(): void {
            this.fSelectedId = this.initialValue;
            if (!this.fullLoad) {
                this.loadDataById();
            }
        }

        public focus(): void {
          this.$refs.filterSelect.focus();
        }

        private fSelectedId: number | null = null;

        private placeHolderStr: string = '';
        private addNewLabel: string = '';
        private emptyLabel: string = '';

        private fSuggestedItem: ISearchItem|null = null;
        private fFilteredItems: ISearchItem[] = [];
        private fAvailableItems: ISearchItem[] = [];

        private tableIsLoading: boolean = false;
        private tableLoading: boolean = false;
        private fCanView: boolean = false;
        private fCanLink: boolean = false;

        mounted(): void {
            this.fCanView = (this.mode !== 'filter') && Boolean(this.viewRoute) && Boolean(this.viewAccess) && serviceDataStore.hasRight(this.viewAccess);
            this.fCanLink = (this.mode !== 'filter') && Boolean(this.listRoute) && Boolean(this.viewAccess) && serviceDataStore.hasRight(this.viewAccess);
            this.fSelectedId = this.initialValue;
            this.placeHolderStr = this.placeholder ?? this.$t(this.placeholderLangId).toString();
            this.addNewLabel = this.$t('common.SelectAddNew').toString();
            this.emptyLabel = this.$t('common.SelectEmptyFilter').toString();
            if (this.fullLoad) {
                this.loadData('');
            } else if (this.fSelectedId??0 > 0) {
                this.loadDataById();
            }
        }

        private get hasButton(): boolean {
          return this.fCanLink || (this.onModalChoice !== null);
        }

        private get canView(): boolean {
          return this.fCanView && Boolean(this.fSelectedId);
        }

        private handleClear(): void {
            this.fSelectedId = null;
            this.$emit('change', this.selectedId);
            this.$emit('changeObject', null);
        }

        private initLoading(): void {
            this.tableIsLoading = true;
            setInterval(() => {
                this.tableLoading = this.tableIsLoading;
            }, 250);
        }

        private finalizeLoading(): void {
            this.tableIsLoading = false;
            this.tableLoading = false;
        }

        protected getFilters(filters: ITableDataFilter): any {
            return this.filters ? this.filters(filters) : filters;
        }

        public loadData(query: string): void {
            this.getDataFromApi(query);
        }

        public loadDataById(): void {
            this.getDataFromApi('', this.selectedId);
        }

        public getDataFromApi(query: string, id: number = 0): void {
            if (this.dataEndpoint !== '') {
                this.initLoading();

                const queryStr: string = query.toLowerCase();
                const filterObj: ITableDataFilter = this.getFilters({
                    search: queryStr,
                    id: id > 0 ? id : undefined,
                    includeData: this.includeData ?? false,
                });

                const itemCount = serviceDataStore.defaultData.filterableCount ?? 50;

                const reqData: IDataRequest = {
                    page: !this.fullLoad && (itemCount > 0) ? 1 : 0,
                    itemsPerPage: itemCount,
                    filter: JSON.stringify(filterObj)
                };

                serviceDataApi.getList(`search/${this.dataEndpoint}`, reqData).then((response: AxiosResponse) => {
                    if (response && (response.status == 200) && (response.data.items)) {
                        this.fSuggestedItem = response.data.suggested;
                        this.fAvailableItems = response.data.items;
                        this.filterItems('');
                        this.$emit('loaded', this.fFilteredItems);
                    }
                }).catch(() => {
                    this.finalizeLoading();
                }).finally(() => {
                    this.finalizeLoading();
                });
            } else {
                console.log('No dataEndpoint provided!');
            }
        }

        private filterItems(query: string): void {
            if (query !== '') {
                const queryStr: string = query.toLowerCase();
                this.initLoading();
                this.fFilteredItems = this.fAvailableItems.filter((item) => {
                    return (this.selectedId == item.id)
                        || (item.searchdata && item.searchdata.toLowerCase().indexOf(queryStr) > -1)
                        || (item.name.toLowerCase().indexOf(queryStr) > -1);
                });
                this.finalizeLoading();
            } else {
                this.fFilteredItems = this.fAvailableItems;
            }
        }

        public reloadData(): void {
            this.loadData('');
            this.remoteMethod('');
        }

        public getSelectedObject(): ISearchItem|undefined {
          const obj: ISearchItem|undefined = this.fFilteredItems.find((item) => {
            return (item.id === this.selectedId);
          })
          return obj;
        }

        private remoteMethod(query: string): void {
            if (this.fullLoad) {
                this.filterItems(query);
            } else {
                this.loadData(query);
            }
        }

        private get selectedId(): number|null {
            return this.fSelectedId;
        }

        private onSelect(): void {
            if (this.selectedId === -1) {
                this.fSelectedId = this.initialValue;
                this.$emit('onAddNew');
                return;
            }

            if (this.fSelectedId) {
                this.$emit('change', this.selectedId);
                this.$emit('changeObject', this.getSelectedObject());
            }
        }
    }
