import UserPermission, {AppModules} from "@common/access";

export default [
    {
        path: "/payment-methods",
        name: "PaymentMethods",
        component: () =>
            import(/* webpackChunkName: "payment-methods" */ "../views/administration/PaymentMethods/PaymentMethods.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/payment-methods/edit/:id",
        name: "PaymentMethodsEdit",
        component: () =>
            import(/* webpackChunkName: "payment-methods" */ "../views/administration/PaymentMethods/PaymentMethodsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/payment-methods/add",
        name: "PaymentMethodsInsert",
        component: () =>
            import(/* webpackChunkName: "payment-methods" */ "../views/administration/PaymentMethods/PaymentMethodsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        },
    },
    {
        path: "/vat-rates",
        name: "VatRates",
        component: () =>
            import(/* webpackChunkName: "vat-rates" */ "../views/administration/VatRates/VatRates.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/vat-rates/edit/:id",
        name: "VatRatesEdit",
        component: () =>
            import(/* webpackChunkName: "vat-rates" */ "../views/administration/VatRates/VatRatesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/vat-rates/add",
        name: "VatRatesInsert",
        component: () =>
            import(/* webpackChunkName: "vat-rates" */ "../views/administration/VatRates/VatRatesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        },
    },

    {
        path: "/transport-types",
        name: "TransportTypes",
        component: () =>
            import(/* webpackChunkName: "transport-types" */ "../views/administration/TransportTypes/TransportTypes.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/transport-types/edit/:id",
        name: "TransportTypesEdit",
        component: () =>
            import(/* webpackChunkName: "transport-types" */ "../views/administration/TransportTypes/TransportTypesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/transport-types/add",
        name: "TransportTypesInsert",
        component: () =>
            import(/* webpackChunkName: "transport-types" */ "../views/administration/TransportTypes/TransportTypesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/holidays",
        name: "Holidays",
        component: () =>
            import(/* webpackChunkName: "holidays" */ "../views/administration/Holidays/Holidays.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/holidays/edit/:id",
        name: "HolidaysEdit",
        component: () =>
            import(/* webpackChunkName: "holidays" */ "../views/administration/Holidays/HolidaysEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/holidays/add",
        name: "HolidaysInsert",
        component: () =>
            import(/* webpackChunkName: "holidays" */ "../views/administration/Holidays/HolidaysEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/document-types",
        name: "DocumentTypes",
        component: () =>
            import(/* webpackChunkName: "document-types" */ "../views/administration/DocumentTypes/DocumentTypes.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/document-types/edit/:id",
        name: "DocumentTypesEdit",
        component: () =>
            import(/* webpackChunkName: "document-types" */ "../views/administration/DocumentTypes/DocumentTypesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/document-types/add",
        name: "DocumentTypesInsert",
        component: () =>
            import(/* webpackChunkName: "document-types" */ "../views/administration/DocumentTypes/DocumentTypesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },

    {
        path: "/services",
        name: "Services",
        component: () =>
            import(/* webpackChunkName: "services" */ "../views/administration/Services/Services.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/services/edit/:id",
        name: "ServicesEdit",
        component: () =>
            import(/* webpackChunkName: "services" */ "../views/administration/Services/ServicesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/services/add",
        name: "ServicesInsert",
        component: () =>
            import(/* webpackChunkName: "services" */ "../views/administration/Services/ServicesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
];