import UserPermission from "@common/access";

export default [

    {
        path: "/pricing",
        name: "Pricing",
        component: () =>
            import(/* webpackChunkName: "pricing" */ "../views/administration/Pricing/Pricing.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.PRICE_LIST_READ,
        }
    },
    {
        path: "/pricing/edit/:id",
        name: "PricingEdit",
        component: () =>
            import(/* webpackChunkName: "pricing" */ "../views/administration/Pricing/PricingEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.PRICE_LIST_WRITE,
        }
    },
    {
        path: "/pricing/add",
        name: "PricingInsert",
        component: () =>
            import(/* webpackChunkName: "pricing" */ "../views/administration/Pricing/PricingEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.PRICE_LIST_WRITE,
        },
    },

    {
        path: "/pricing-search",
        name: "PriceSearch",
        component: () =>
            import(/* webpackChunkName: "pricing" */ "../views/administration/Pricing/PriceSearch.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.PRICE_LIST_READ,
        }
    },
    {
        path: "/pricing-min-price",
        name: "PricingMinPrice",
        component: () =>
            import(/* webpackChunkName: "pricing" */ "../views/administration/Pricing/PricingMinPrice.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/pricing-min-price/edit/:id",
        name: "PricingMinPriceEdit",
        component: () =>
            import(/* webpackChunkName: "pricing" */ "../views/administration/Pricing/PricingMinPriceEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/pricing-min-price/add",
        name: "PricingMinPriceInsert",
        component: () =>
            import(/* webpackChunkName: "pricing" */ "../views/administration/Pricing/PricingMinPriceEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        },
    },
]