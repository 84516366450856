import Customers from "@/views/administration/Customers/Customers.vue";
import UserPermission from "@common/access";
import CustomersEdit from "@/views/administration/Customers/CustomersEdit.vue";

export default [
    {
        path: "/customers",
        name: "Customers",
        component: Customers,
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.CUSTOMERS_READ,
        }
    },
    {
        path: "/customers/edit/:id",
        name: "CustomersEdit",
        component: CustomersEdit,
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.CUSTOMERS_WRITE,
        }
    },
    {
        path: "/customers/add",
        name: "CustomersInsert",
        component: CustomersEdit,
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.CUSTOMERS_WRITE,
        }
    },

    {
        path: "/customer-categories",
        name: "CustomerCategories",
        component: () =>
            import(/* webpackChunkName: "customercategories" */ "../views/administration/CustomerCategories/CustomerCategories.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/customer-categories/edit/:id",
        name: "CustomerCategoriesEdit",
        component: () =>
            import(/* webpackChunkName: "customercategories" */ "../views/administration/CustomerCategories/CustomerCategoriesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/customer-categories/add",
        name: "CustomerCategoriesInsert",
        component: () =>
            import(/* webpackChunkName: "customercategories" */ "../views/administration/CustomerCategories/CustomerCategoriesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
];