

import { Component, Vue } from "vue-property-decorator";
import MessageWindow from "@/views/Messages/MessageWindow.vue";
import {serviceDataApi, serviceSocket, storageService} from "@/services/service-container";
import {AxiosResponse} from "axios";

@Component({
  components: {
    MessageWindow
  },
})
export default class MessagesConversations extends Vue {
  private fContacts: IMsgPerson[] = [];
  private removeListenerId: number = 0;

  private closeMessage(contact: IMsgPerson): void {
    const index = this.fContacts.findIndex((elem) => {
      return (elem.id === contact.id);
    });
    if (index > -1) {
      this.fContacts.splice(index, 1);
      this.saveContacts();
    }
  }

  private saveContacts(): void {
    const arr = this.fContacts.map(elem => elem.id);
    storageService.setItem('msg_contacts', JSON.stringify(arr));
  }

  private loadContacts(): void {
    const contacts: number[] = JSON.parse(storageService.getItem('msg_contacts', '[]'));
    if (!contacts.length) return;

    const filterObj: any = {
      search: '',
      ids: contacts.slice(0, 2).join(',')
    };

    const reqData: IDataRequest = {
      page: 0,
      itemsPerPage: 0,
      filter: JSON.stringify(filterObj)
    };
    serviceDataApi.getList('messages', reqData).then((response: AxiosResponse) => {
      if (response && (response.status == 200) && (response.data.items)) {
        this.fContacts = response.data.items.slice(0, 2);
      }
    })
  }

  private async msgListener(obj: any): Promise<boolean> {
    if (obj.msg !== 'message') return false;

    if (!obj.userId) return false;

    const newContactId = obj.userId;
    const index = this.fContacts.findIndex((elem) => {
      return (elem.id === newContactId);
    });

    // okno jest juz widoczne
    if (index > -1) {
      return true;
    }

    const filterObj: any = {
      search: '',
      ids: [newContactId].join(',')
    };

    const reqData: IDataRequest = {
      page: 0,
      itemsPerPage: 0,
      filter: JSON.stringify(filterObj)
    };
    serviceDataApi.getList('messages', reqData).then((response: AxiosResponse) => {
      if (response && (response.status == 200) && (response.data.items)) {
        this.fContacts.push({
          ...response.data.items[0],
          isBlinking: true,
          isMinimized: true
        });
        this.saveContacts();
      }
    });

    return true;
  }

  mounted() {
    this.loadContacts();
    this.removeListenerId = serviceSocket.addListener(this.msgListener);
  }

  destroyed(){
    serviceSocket.removeListener(this.removeListenerId);
  }
}
