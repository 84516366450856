import {ServiceBase} from "@/services/service-base";
import Vue from "vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import SmsGatewayDialog from "@/views/administration/Sms/SmsGateway.vue";
import PasswordChangeDialog from "@/components/dialogs/PasswordChangeDialog.vue";

export default class DialogService extends ServiceBase {
    public appHook: Vue;

    public confirmDlg: ConfirmDialog;
    public smsDlg: SmsGatewayDialog;
    public passwordDlg: PasswordChangeDialog;
}