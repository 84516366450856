
    import { Component, Prop, Vue } from "vue-property-decorator";
    import FilterableSelect from "@/components/custom/FilterableSelect.vue";
    import {storageService} from "@/services/service-container";

    @Component({
        components: {
            FilterableSelect
        },
    })
    export default class UserSelect extends Vue {
        $refs!: {
          filterElem: FilterableSelect;
        };

        @Prop({ default: null })
        public value: number;

        @Prop({ default: false })
        public isDisabled: boolean;

        @Prop({ default: true })
        public isClearable: boolean;

        // 0 - all, 1 - with customer, 2 - without customer
        @Prop({ default: 0 })
        public withCustomer: number;

        @Prop({ default: '' })
        public withAccess: string;

        @Prop({ default: 'Users.SearchKeyword' })
        public placeholderId: string;

        @Prop({ default: false })
        private fullLoad: boolean;

        public filterMode: boolean = false;

        public focus(): void {
          this.$refs.filterElem.focus();
        }

        private getFilters(filter: ITableDataFilter): IUnknownObject {
          return {
            id: filter.id ?? undefined,
            search: filter.search ? filter.search : undefined,
            includeData: true,
            withAccess: this.withAccess.length ? this.withAccess : undefined,
            withCustomer: this.withCustomer > 0 ? this.withCustomer : undefined,
          };
        }

        public changeObject(data: ISearchItem): void {
          this.$emit('changeData', data?.data);
        }

        public userChanged(id: number): void {
            this.$emit('input', id);
        }

        public dataLoaded(data: ISearchItem[]): void {
          this.$emit('loaded', data);
        }

        public saveFilters(filterKey: string): void {
            if (this.value ?? 0 > 0) {
              storageService.setItem(`${filterKey}_flt_userId`, this.value.toString());
            } else {
              storageService.removeItem(`${filterKey}_flt_userId`);
            }
        }

        public loadFilters(filterKey: string): void {
            this.filterMode = true;
            const str = storageService.getItem(`${filterKey}_flt_userId`);
            if (str.length) {
                this.$emit('input', parseInt(str, 10));
            }
        }

        public clearFilter(): void {
            this.$emit('input', null);
        }
    }
