import Axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import { ISetupCache, setupCache } from 'axios-cache-adapter'
import {Configuration} from "@/common/config";
import {getRandomString} from "@/common/functions";
import router from '@/router';
import {serviceDataStore} from "@/services/service-container";
import {ServiceBase} from "@/services/service-base";

export default class ApiClientService extends ServiceBase {

    public subDomain: string = '';

    private cache: ISetupCache;
    private api: AxiosInstance;

    private fSessionId: string = '';
    // url of api to connect
    private fUrl: string = 'http://localhost:3000/api/';

    constructor() {
        super();
        this.initApiService();
    }

    public initApiService(): void {
        const domainSplit = window.location.hostname.split('.');
        this.subDomain = domainSplit.length > 2 ? domainSplit[0] : '';

        this.cache = setupCache({
            maxAge: 0 // 15 * 60 * 1000,
        });

        this.api = Axios.create({
            adapter: this.cache.adapter,
        });

        this.api.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (error.response.status === 403) {
                    // this.logAccessViolation(error);
                    router.push({ name: 'Login' });
                    serviceDataStore.logOut();
                } else {
                    throw error;
                }
            },
        );

        this.fUrl = Configuration.apiEndpoint;
    }

    private async logAccessViolation(error: AxiosError): Promise<void> {
        this.post('logs', {
            logType: 3,
            content: JSON.stringify({
                userId: serviceDataStore.userId,
                userName: serviceDataStore.getUserName(),
                route: router.currentRoute.fullPath,
                requestUri: error.request.responseURL
            }),
            description: `Access violation`
        });
    }

    public getUrl(endpoint: string): string {
        return this.fUrl + endpoint;
    }

    public getUploadHeaders(customHeaders: Record<string, string> = {}): Record<string, string> {
        const headers: IUnknownObject = {
            'Accept': 'application/vnd.enp.api+json;version=v1',
            'QueryHash': getRandomString(8),
            'Subdomain': this.subDomain,
            ...customHeaders,
        };
        if (this.getAccessToken() !== '') {
            return {
                ...headers,
                'access-token': this.getAccessToken(),
            };
        } else {
            return headers;
        }
    }

    public getHeaders(customHeaders: Record<string, string> = {}): Record<string, string> {
        const headers: IUnknownObject = {
            'Accept': 'application/vnd.enp.api+json;version=v1',
            'Content-Type': 'application/json; charset=utf-8',
            'QueryHash': getRandomString(8),
            'Subdomain': this.subDomain,
            ...customHeaders,
        };
        if (this.getAccessToken() !== '') {
            return {
                ...headers,
                'access-token': this.getAccessToken(),
            };
        } else {
            return headers;
        }
    }

    private convertTableOptionsToExportParams(options: IDataRequest): Record<string, QueryParamValue> {
        const res: Record<string, QueryParamValue> = {};
        res['page'] = -1;
        res['itemsPerPage'] = -1;
        if (options.sortBy && options.sortBy.length) {
            res['sortBy'] = options.sortBy;
        }
        if (options.sortDesc && options.sortDesc.length) {
            res['sortDesc'] = options.sortDesc.map(item => {
                return item ? 'true' : 'false';
            });
        }
        if (options.filter && options.filter.length) {
            res['filter'] = options.filter;
        }
        return res;
    }

    private convertTableOptionsToParams(options: IDataRequest): Record<string, QueryParamValue> {
        const res: Record<string, QueryParamValue> = {};
        res['page'] = options.page-1;
        res['itemsPerPage'] = options.itemsPerPage;
        if (options.sortBy && options.sortBy.length) {
            res['sortBy'] = options.sortBy;
        }
        if (options.sortDesc && options.sortDesc.length) {
            res['sortDesc'] = options.sortDesc.map(item => {
                return item ? 'true' : 'false';
            });
        }
        if (options.filter && options.filter.length) {
            res['filter'] = options.filter;
        }
        return res;
    }

    public getTableExportData<T, R = AxiosResponse<T>>(uri: string, params: IDataRequest, headers: IUnknown = {},): Promise<R> {
        try {
            const requestUrl: string = this.fUrl + uri;
            const getParams: Record<string, QueryParamValue> = this.transformQueryParams(this.convertTableOptionsToExportParams(params));
            return this.api.get(requestUrl, {
                params: {
                    'options': JSON.stringify(getParams)
                },
                headers: this.getHeaders(headers),
                validateStatus: (status) => {
                    return status >= 200 && status <= 304;
                },
            });
        } catch (err) {
            throw err;
        }
    }

    public getTableData<T, R = AxiosResponse<T>>(uri: string, params: IDataRequest, headers: IUnknown = {},): Promise<R> {
        try {
            const requestUrl: string = this.fUrl + uri;
            const getParams: Record<string, QueryParamValue> = this.transformQueryParams(this.convertTableOptionsToParams(params));
            return this.api.get(requestUrl, {
                params: {
                    'options': getParams ? JSON.stringify(getParams) : undefined
                },
                headers: this.getHeaders(headers),
                validateStatus: (status) => {
                    return status >= 200 && status <= 304;
                },
            });
        } catch (err) {
            throw err;
        }
    }

    public getSimpleData<T, R = AxiosResponse<T>>(uri: string, headers: IUnknown = {},): Promise<R> {
        try {
            const requestUrl: string = this.fUrl + uri;
            return this.api.get(requestUrl, {
                headers: this.getHeaders(headers),
                validateStatus: (status) => {
                    return status >= 200 && status <= 304;
                },
            });
        } catch (err) {
            throw err;
        }
    }

    public getObjectData<T, R = AxiosResponse<T>>(uri: string, params: ISingleDataRequest, headers: IUnknown = {},): Promise<R> {
        try {
            const requestUrl: string = this.fUrl + uri + '/' + params.id;
            return this.api.get(requestUrl, {
                headers: this.getHeaders(headers),
                validateStatus: (status) => {
                    return status >= 200 && status <= 304;
                },
            });
        } catch (err) {
            throw err;
        }
    }

    public get<T, R = AxiosResponse<T>>(
        uri: string, params: Record<string, QueryParamValue> = {}, // ignoreAuthError: boolean = false,
        headers: IUnknown = {},
    ): Promise<R> {
        try {
            const requestUrl: string = this.fUrl + uri;
            return this.api.get(requestUrl, {
                params: params ? this.transformQueryParams(params) : params,
                headers: this.getHeaders(headers),
                validateStatus: (status) => {
                    return status >= 200 && status <= 304;
                },
            });
        } catch (err) {
            throw err;
        }
    }

    public post<T, B, R = AxiosResponse<T>>(
        uri: string,
        payload?: B,
        params: Record<string, QueryParamValue> = {},
        headers: IUnknown = {},
    ): Promise<R> {
        try {
            const requestUrl: string = this.fUrl + uri;

            return this.api.post(requestUrl, payload, {
                params: params ? this.transformQueryParams(params) : params,
                headers: this.getHeaders(headers),
                validateStatus: (status) => {
                    return status >= 200 && status <= 304;
                },
            });
        } catch (err) {
            throw err;
        }
    }

    public patch<T, B, R = AxiosResponse<T>>(
        uri: string,
        payload?: B,
        params: Record<string, QueryParamValue> = {},
        headers: IUnknown = {},
    ): Promise<R> {
        try {
            const requestUrl: string = this.fUrl + uri;

            return this.api.patch(requestUrl, payload, {
                params: params ? this.transformQueryParams(params) : params,
                headers: this.getHeaders(headers),
                validateStatus: (status) => {
                    return status >= 200 && status <= 304;
                },
            });
        } catch (err) {
            throw err;
        }
    }

    public put<T, B, R = AxiosResponse<T>>(
        uri: string,
        payload?: B,
        params: Record<string, QueryParamValue> = {},
        headers: IUnknown = {},
    ): Promise<R> {
        try {
            const requestUrl: string = this.fUrl + uri;

            return this.api.put(requestUrl, payload, {
                params: params ? this.transformQueryParams(params) : params,
                headers: this.getHeaders(headers),
                validateStatus: (status) => {
                    return status >= 200 && status <= 304;
                },
            });
        } catch (err) {
            throw err;
        }
    }

    public deleteMultiple<T, R = AxiosResponse<T>>(uri: string, removeObj: IDataRecord[]): Promise<R> {
        try {
            const requestUrl: string = this.fUrl + uri + '?' + removeObj.map((item) => {
                return `id[]=${item.id}`;
            }).join('&');

            return this.api.delete(requestUrl, {
                headers: this.getHeaders(),
                validateStatus: (status) => {
                    return status >= 200 && status <= 304;
                },
            });
        } catch (err) {
            throw err;
        }
    }

    public delete<T, R = AxiosResponse<T>>(uri: string, id: number|string): Promise<R> {
        try {
            const requestUrl: string = this.fUrl + uri + '/' + id;

            return this.api.delete(requestUrl, {
                headers: this.getHeaders(),
                validateStatus: (status) => {
                    return status >= 200 && status <= 304;
                },
            });
        } catch (err) {
            throw err;
        }
    }

    private getAccessToken(): string {
        //if (this.fSessionId === '') {
            this.fSessionId = (localStorage.getItem('user_session') || '').toString();
        //}
        return this.fSessionId;
    }

    private transformQueryParams(params: Record<string, QueryParamValue>): Record<string, QueryParamValue> {
        Object.keys(params).forEach((param) => {
            const value: QueryParamValue = params[param];

            if (Array.isArray(value)) {
                params[param] = value.join(',');
            }
        });

        return params;
    }
}
