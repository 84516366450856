import UserPermission from "@common/access";

export default [
    {
        path: "/work-cards",
        name: "WorkCards",
        component: () =>
            import(/* webpackChunkName: "work-cards" */ "../views/administration/WorkCards/WorkCards.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.WORK_CARDS_READ,
        }
    },
    {
        path: "/work-cards/view/:id",
        name: "WorkCardsView",
        component: () =>
            import(/* webpackChunkName: "work-cards" */ "../views/administration/WorkCards/WorkCardsEdit.vue"),
        meta: {
            requiresAuth: true,
            viewMode: true,
            requiresAccess: UserPermission.WORK_CARDS_READ,
        }
    },
    {
        path: "/work-cards/edit/:id",
        name: "WorkCardsEdit",
        component: () =>
            import(/* webpackChunkName: "work-cards" */ "../views/administration/WorkCards/WorkCardsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.WORK_CARDS_WRITE,
        }
    },
    {
        path: "/work-cards/add",
        name: "WorkCardsInsert",
        component: () =>
            import(/* webpackChunkName: "work-cards" */ "../views/administration/WorkCards/WorkCardsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.WORK_CARDS_WRITE,
        }
    },

    {
        path: "/work-cards/open/:id",
        name: "WorkCardsOpen",
        component: () =>
            import(/* webpackChunkName: "work-cards" */ "../views/administration/WorkCards/WorkCardsOpen.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.WORK_CARDS_WRITE,
        }
    },

    {
        path: "/work-cards-status",
        name: "WorkCardsStatus",
        component: () =>
            import(/* webpackChunkName: "work-cards-status" */ "../views/administration/WorkCards/WorkCardsStatus.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/work-cards-status/edit/:id",
        name: "WorkCardsStatusEdit",
        component: () =>
            import(/* webpackChunkName: "work-cards-status" */ "../views/administration/WorkCards/WorkCardsStatusEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/work-cards-status/add",
        name: "WorkCardsStatusInsert",
        component: () =>
            import(/* webpackChunkName: "work-cards-status" */ "../views/administration/WorkCards/WorkCardsStatusEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
];