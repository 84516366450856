
import { Component, Vue } from "vue-property-decorator";
import changeData from "@/utils/changelog.json";
import {serviceDataStore, storageService} from "@/services/service-container";


@Component({
  components: {
  },
})
export default class ChangeLogDialog extends Vue {

  private dialog: boolean = false;

  private get change(): IChangeLog {
    return changeData[0];
  }

  private get dialogTitle(): string {
    return this.$t("Changelog.ChangesTitle").toString() + changeData[0].version;
  }

  mounted() {
    if (!serviceDataStore.isCustomer) {
      const versionSeen: number = parseInt(storageService.getItem('versionSeen', '0'), 10);
      if (changeData[0].id > versionSeen) {
        this.dialog = true;
      }
    }
  }

  created() {
    window.addEventListener("keyup", this.keyListener);
  }

  destroyed() {
    window.removeEventListener("keyup", this.keyListener);
  }

  private keyListener(event: KeyboardEvent) {
    if (!this.dialog) {
      return;
    }
    if (event.key === "Enter") {
      this.handleClose();
    } else if (event.key === "Escape") {
      this.handleClose();
    }
  }

  private handleClose(): void {
    this.dialog = false;
  }

  private handleLog(): void {
    this.dialog = false;
    storageService.setItem('versionSeen', this.change.id.toString());
    this.$router.push({ name: 'ChangeLog', params: {}} );
  }

  private handleOk(): void {
    this.dialog = false;
    storageService.setItem('versionSeen', this.change.id.toString());
  }
}

