
import {ServiceBase} from "@/services/service-base";
import Vue from "vue";
import {isNullOrUndefined} from "@common/functions";
import {serviceDataStore} from "@/services/service-container";

export default class StorageService extends ServiceBase {
    public appHook: Vue;

    public key(index: number): string|null {
        return localStorage.key(index);
    }

    public removeItem(key: string, useUser: boolean = true): void {
        const saveKey = useUser ? `${serviceDataStore.userId}__${key}` : key;
        localStorage.removeItem(saveKey);
    }

    public getUserKeys(): string[] {
        const resArray: string[] = [];
        const userId = serviceDataStore.userId;

        for (let ii = localStorage.length-1; ii>= 0; ii--) {
            const key = localStorage.key(ii) ?? '';
            if (key.startsWith(`${userId}__`)) {
                resArray.push(key);
            }
        }

        return resArray;
    }

    public setItem(key: string, value: string|null, useUser: boolean = true) {
        const saveKey = useUser ? `${serviceDataStore.userId}__${key}` : key;
        if (!isNullOrUndefined(value)) {
            localStorage.setItem(saveKey, value??'');
        } else {
            localStorage.removeItem(saveKey);
        }
    }

    public saveInt(key: string, value: number|null) {
        const userId = serviceDataStore.userId;
        const saveKey = `${userId}__${key}`;
        if (!isNullOrUndefined(value)) {
            localStorage.setItem(saveKey, value?.toString()??'0');
        } else {
            localStorage.removeItem(saveKey);
        }
    }

    public getItem(key: string, defaultValue: string = '', useUser: boolean = true): string {
        const saveKey = useUser ? `${serviceDataStore.userId}__${key}` : key;
        const str = localStorage.getItem(saveKey);
        if (str) return str;
        return defaultValue;
    }

    public loadInt(key: string, defaultValue: number|null = 0): number | null {
        const userId = serviceDataStore.userId;
        const saveKey = `${userId}__${key}`;
        const str = this.getItem(saveKey);
        if (str) {
            return parseInt(str, 10);
        }
        return defaultValue;
    }
}