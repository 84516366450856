export enum DataTableFormat {
    NONE = 0,
    BOOLEAN = 1,
    DATE = 2,
    CURRENCY = 3,
    USER = 4,
    CUSTOMER = 5,
    DATETIME = 6,
    FLOAT = 7,
}
export enum IDataColumnStyle {
    csBold
}

export enum ErrorCodes {
    LEAVE_CARD_YEARS = 1001,
    LEAVE_CARD_KIND_DAYS = 1002,
    LEAVE_CARD_CONTRACT_DAYS = 1003,
}

export enum InvoiceStatus {
    NEW = 0, // nowe
    CONFIRMED = 3, // potwierdzone
    EXPORTED = 4, // wyeksportowana
    INVOICED = 5, // zaplacone
    CANCELLED = 9, // anulowane
}
export enum InvoiceStatusColor {
    NEW = '#000000', // nowe
    CONFIRMED = '#122ea2', // potwierdzone
    INVOICED = '#208120', // zafakturowane
    CANCELLED = '#FF0000', // anulowane
}

export enum ReceptionStatus {
    NONE = 0,
    NEW = 1, // nowe
    READY = 3, // do odbioru
    RECEIVED = 5, // odebrane
    CANCELLED = 9, // anulowane
}
export enum QuotationStatus {
    NONE = 0,
    NEW = 1, // nowe
    CONFIRMED = 3, // potwierdzone
    INVOICED = 5, // zafakturowane
    CANCELLED = 9, // anulowane
}
export enum ReceptionStatusColor {
    NONE = '#000000', //
    NEW = '#000000', // nowe
    READY = '#122ea2', // gotowe do odbioru
    RECEIVED = '#208120', // odebrane
    CANCELLED = '#FF0000', // anulowane
}

export enum QuotationStatusColor {
    NONE = '#000000', //
    NEW = '#000000', // nowe
    CONFIRMED = '#122ea2', // potwierdzone
    INVOICED = '#208120', // zafakturowane
    CANCELLED = '#FF0000', // anulowane
}

export enum OrderStatus {
    NONE = 0,
    NEW = 1, // nowe
    CONFIRMED = 2, // potwierdzone
    PLANNED = 3, // zaplanowane
    IN_PROGRESS = 4, //  trakcie obrobki
    DONE = 5, // prace zakonczone
    READY_TO_BE_PICK_UP = 6, // gotowe do odbioru
    PICKED_UP = 7, // odebrane
    INVOICED = 8, // zafakturowane
    PAID = 9, // zaplacone
    CANCELED = 99 // anulowane
}

export enum OrderStatusColors {
    NONE = '',
    NEW = '#e7e74b',
    CONFIRMED = '#4747fc',
    PLANNED = '#808080',
    IN_PROGRESS = '#F38132',
    DONE = '#00FF00',
    READY_TO_BE_PICK_UP = '#808000',
    PICKED_UP = '#00FFFF',
    INVOICED = '#efe395',
    PAID = '#008000',
    CANCELED = '#800080'
}

// <= 500 - EMAIL, 500+ - SMS
export enum TemplateKind {
    NewOrder = 1,
    OrderStatus_CONFIRMED = 2,
    OrderStatus_PLANNED = 3,
    OrderStatus_IN_PROGRESS = 4,
    OrderStatus_DONE = 5,
    OrderStatus_READY_TO_BE_PICK_UP = 6,
    OrderStatus_PICKED_UP = 7,
    OrderStatus_INVOICED = 8,
    OrderStatus_PAID = 9,
    OrderStatus_CANCELED = 10,
    ErrornousOrderProduct = 50,
    Complaint = 60,
    ComplaintMessage = 61,
}

export enum WorkFrameStatus {
    NONE = 0,
    InPreparation = 1,
    Confirmed = 2,
}