
    import {Component} from "vue-property-decorator";
    import DataTable from "@/components/custom/DataTable.vue";
    import UserPermission from "@common/access";
    import GridEditPartial from "@/components/custom/GridEditPartial.vue";
    import CustomerUserEdit from "@/components/grids/customer/CustomerUserEdit.vue";
    import {DataTableFormat} from "@/common/enums";

    @Component({
        components: {
            DataTable,
            CustomerUserEdit
        },
    })
    export default class CustomerUsers extends GridEditPartial {

        protected getFilters(filters: ITableDataFilter): IUnknownObject {
            delete filters.search;
            return {
                customerId: this.parentId,
                ...filters,
            };
        }

        private formatRole(role: any): string {
            return role ? role.name : '';
        }

        private get getDataTableOptions(): IDataTableOptions {
            return {
                canInsert: true,
                canDelete: true,
                canEdit: true,
                canExport: false,
                multiSelectable: false,
                filterKey: 'CustomerUsersGrid',

                viewAccess: UserPermission.USERS_READ,
                editAccess: UserPermission.USERS_WRITE,
                deleteAccess: UserPermission.USERS_DELETE,
                exportAccess: UserPermission.USERS_EXPORT,

                tableEndpoint: 'customer-users',

                defaultfilterEnabled: false,

                columns: [
                    {
                        name: "id",
                        label: this.$t('Users.Id').toString(),
                        exported: true,
                        visible: false,
                        width: 100,
                    },
                    {
                        name: 'user.code',
                        label: this.$t('Users.Code').toString(),
                        width: 100,
                        visible: false,
                    },
                    {
                        name: 'user.name',
                        label: this.$t('Users.Name').toString(),
                        width: 200,
                    },
                    {
                        name: 'user.surname',
                        label: this.$t('Users.Surname').toString(),
                        width: 200,
                    },
                    {
                        name: 'user.email',
                        label: this.$t('Users.Email').toString(),
                    },
                    {
                        name: 'user.role',
                        orderBy: 'role.name',
                        format: this.formatRole,
                        label: this.$t('Users.Role').toString(),
                    },
                    {
                        name: 'isNotified',
                        label: this.$t('CustomerUsers.FieldIsNotified').toString(),
                        format: DataTableFormat.BOOLEAN,
                        width: 150
                    },
                    {
                        name: 'user.isLocked',
                        label: this.$t('Users.IsLocked').toString(),
                        format: DataTableFormat.BOOLEAN,
                        width: 130
                    }
                ]
            };
        }
    }
