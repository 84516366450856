
    import {Component} from "vue-property-decorator";
    import DataTable from "@/components/custom/DataTable.vue";
    import BaseView from "@/views/base/BaseView.vue";
    import UserPermission from "@common/access";
    import PageTitle from "@/components/PageTitle.vue";
    import CustomerCategorySelect from "@/components/select/CustomerCategorySelect.vue";

    @Component({
        components: {
            DataTable,
            PageTitle,
            CustomerCategorySelect,
        },
    })
    export default class Customers extends BaseView {
        public customerCategoryIdFilter: number|null = null;

        $refs!: {
            fltCustomerCategory: CustomerCategorySelect;
            dataTableComponent: DataTable;
        };

        private saveFilters(filterKey: string): void {
            this.$refs.fltCustomerCategory.saveFilters(filterKey);
        }

        private loadFilters(filterKey: string): void {
            this.$refs.fltCustomerCategory.loadFilters(filterKey);
        }

        private clearFilters(): void {
            this.$refs.fltCustomerCategory.clearFilter();
        }

        public getFilters(filters: ITableDataFilter): IUnknownObject {
            return {
                ...filters,
                categoryId: this.customerCategoryIdFilter ? this.customerCategoryIdFilter : undefined,
            };
        }

        public handleCustomerCategoryFilterChange(id: number): void {
            this.customerCategoryIdFilter = id;
            const cmp: DataTable = (this.$refs.dataTableComponent as DataTable);
            cmp.loadTableData();
        }

        public get getDataTableOptions(): IDataTableOptions {
            return {
                canInsert: true,
                canDelete: true,
                canEdit: true,
                canExport: true,

                viewAccess: UserPermission.CUSTOMERS_READ,
                editAccess: UserPermission.CUSTOMERS_WRITE,
                deleteAccess: UserPermission.CUSTOMERS_DELETE,
                exportAccess: UserPermission.CUSTOMERS_EXPORT,

                logIdentifier: "CUSTOMERS",
                actionsWidth: 80,
                viewRoute: 'Customers',
                insertRoute: 'CustomersInsert',
                editRoute: 'CustomersEdit',
                tableEndpoint: 'customers',

                columns: [
                    {
                        name: "id",
                        label: this.$t('Customers.FieldId').toString(),
                        exported: true,
                        visible: false,
                        width: 100,
                    },
                    {
                        name: "code",
                        label: this.$t('Customers.FieldCode').toString(),
                        width: 140
                    },
                    {
                        name: "externalId",
                        label: this.$t('Customers.FieldExternalId').toString(),
                        width: 140,
                        visible: false
                    },
                    {
                        name: "name",
                        label: this.$t('Customers.FieldName').toString()
                    },
                    {
                        name: "ownName",
                        label: this.$t('Customers.FieldOwnName').toString(),
                        visible: false
                    },
                    {
                        name: "nip",
                        label: this.$t('Customers.FieldNip').toString(),
                        width: 120
                    },
                    {
                      name: "regon",
                      label: this.$t('Customers.FieldRegon').toString(),
                      width: 120,
                      visible: false
                    },
                    {
                        name: "postCode",
                        label: this.$t('Customers.FieldPostCode').toString(),
                        width: 120
                    },
                    {
                      name: "city",
                      label: this.$t('Customers.FieldCity').toString(),
                      width: 160
                    },
                    {
                      name: "address",
                      label: this.$t('Customers.FieldAddress').toString(),
                    },
                    {
                        name: "phone",
                        label: this.$t('Customers.FieldPhone').toString(),
                        width: 120
                    },
                    {
                        name: "fax",
                        label: this.$t('Customers.FieldFax').toString(),
                        width: 120,
                        visible: false
                    },
                    {
                        name: "email",
                        label: this.$t('Customers.FieldEmail').toString(),
                        width: 220,
                        visible: false
                    },
                    {
                        name: "tags",
                        label: this.$t('Customers.FieldTags').toString(),
                        width: 250,
                        visible: false,
                        format: this.tagsField,
                    },
                ]
            };
        }

        private tagsField(value: string): string {
            const tags = value ? value.toString().trim().split(',') : [];
            let tagStr  = "";
            tags.forEach((tag) => {
                if (tag !== '') {
                    tagStr += '<span class="el-tag el-tag--light">' + tag + '</span>';
                }
            });
            return tagStr;
        }
    }

