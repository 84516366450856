
    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import {AxiosResponse} from "axios";
    import {Debounce} from "vue-debounce-decorator";
    import ExportFormatDialog from "@/components/dialogs/ExportFormatDialog.vue";
    import {exportAsCSV, exportAsExcel} from "@/utils/excel";
    import {ElTable} from "element-ui/types/table";
    import {replaceAll} from "@/common/functions";
    import moment from "moment";
    import DataTableSettingsDialog, {
      IDataTableSettingsDialogResult
    } from "@/components/dialogs/DataTableSettingsDialog.vue";
    import {dialogService, serviceDataApi, serviceDataStore, storageService} from "@/services/service-container";
    import {IDataColumnStyle} from "@/common/enums";
    import contextMenu from 'vue-context-menu';
    import InputDialog from "@/components/dialogs/InputDialog.vue";
    import RecordInfoDialog from "@/components/dialogs/RecordInfoDialog.vue";
    import UserPermission from "@common/access";
    import DataTablePagination from "@/components/custom/DataTablePagination.vue";

    @Component({
        components: {
            DataTablePagination,
            RecordInfoDialog,
            DataTableSettingsDialog,
            ExportFormatDialog,
            InputDialog,
            contextMenu,
        },
    })
    export default class DataTable extends Vue {
        @Prop({ required: true })
        private options: IDataTableOptions;

        @Prop({ default: false })
        private disabled: boolean;

        @Prop({})
        private rowClass: RowClassCallbackMethod;

        @Prop({})
        private filters: FilterCallbackMethod;

        @Prop({})
        private onSaveFilters: FilterActionCallbackMethod;

        @Prop({})
        private onLoadFilters: FilterActionCallbackMethod;

        @Prop({})
        private onClearFilters: FilterActionCallbackMethod;

        @Prop({})
        private canLoad: CanLoadCallbackMethod;

        @Prop({ default: null })
        private onAddClick: InsertCallbackMethod;

        @Prop({ default: null })
        private onEditClick: EditCallbackMethod;

        @Prop({ default: null })
        private onViewClick: EditCallbackMethod;

        @Prop({ default: null })
        private onRowClick: RowClickCallbackMethod;

        @Prop({ default: null })
        private onCanEdit: CanEditClickCallbackMethod;

        @Prop({ default: null })
        private onCanDelete: CanEditClickCallbackMethod;

        @Prop({ default: null })
        private onSelectionChange: SelectionChangeCallbackMethod;

        @Prop({ default: null })
        private gridHeader: string;

        @Prop({ default: true })
        protected loadOnStart: boolean;

        @Prop({ default: null })
        protected queryParams: {[key: string]: string};

        @Prop({ default: false })
        protected showSummary: boolean;

        @Prop({ default: null })
        protected summaryMethod: any;

        private tableSearchExternal: boolean = false;
        private tableSearch: string = '';
        @Watch('tableSearch')
        @Debounce(250)
        onSearchPropertyChanged(): void {
            if (this.tableSearchExternal) {
              this.tableSearchExternal = false;
              return;
            }
            this.tableOptions.page = 1;
            this.loadTableData();
        }

        private filterKey: string = '';
        private adminAccess: boolean = false;
        private viewAccess: boolean = true;
        private insertAccess: boolean = true;
        private editAccess: boolean = true;
        private deleteAccess: boolean = true;
        private exportAccess: boolean = true;
        private visibleActionLabels: boolean = false;
        private tableLoading: boolean = false;
        private tableLoadEnabled: boolean = true;
        private tableIsLoading: boolean = false;
        private tableCount: number = 0;
        private tableData: any[] = [];
        private actionsWidth: number = 80;
        private multipleSelection: any[] = [];
        private refeshCounter: number = -1;
        private refreshIntervalId: number = 0;
        private showHeader: boolean = true;
        private paginationEnabled: boolean = true;
        private isPrepared: boolean = false;

        private contextScope: any;

        private fUserFilters: IUserFilter[] = [];

        private fObserver: IntersectionObserver;
        private configurableColumns: boolean = false;

        public tableOptions: IDataRequest = {
            page: 1,
            loadedPage: 1,
            itemsPerPage: 15,
            sortBy: [],
            sortDesc: [],
        };

        $refs!: {
            dataTableEl: ElTable;
            ctxMenu: contextMenu;
            searchInput: HTMLInputElement;
            inpDlg: InputDialog;
            exportDlg: ExportFormatDialog;
            settingsDlg: DataTableSettingsDialog;
            recordDlg: RecordInfoDialog;
        };

        public getColumns(): IDataTableColumn[] {
          return this.options.columns;
        }

        public getColumn(idx: number): IDataTableColumn|null {
          let ind: number = idx;
          if (this.isMultiSelectable) {
            ind--;
          }
          if (this.idFieldVisible) {
            ind--;
          }
          if (this.lpFieldVisible) {
            ind--;
          }
          let ii = -1;
          for (const column of this.options.columns) {
            if (column.visible) {
              ii++;
            }
            if (ii === ind) {
              return column;
            }
          }

          return null;
        }

        private openContext(scope: any): void {
          if (this.$refs.ctxMenu) {
            this.contextScope = scope;
            this.$refs.ctxMenu.open();
          }
        }

        private get canShowLogs(): boolean {
          return this.adminAccess;
        }

        private removeFilter(filter: IUserFilter): void {
            serviceDataApi.delete('user-filters', filter.id ?? 0).then((response: AxiosResponse) => {
              if (response && (response.status == 200)) {
                const ind: number = this.fUserFilters?.indexOf(filter) ?? -1;
                if (ind >= 0) {
                  this.fUserFilters?.splice(ind, 1);
                }
              }
            });
        }

        private openLogs(): void {
          const searchTxt = `${this.options.logIdentifier}/%/${this.contextScope.row.id}`;
          this.$router.push({ name: 'Logs', query: { description: searchTxt } });
        }

        private openRecordInfo(): void {
          serviceDataApi.getSimpleData(`${this.options.tableEndpoint}/record-info/${this.contextScope.row.id}`).then((response: AxiosResponse) => {
            if (response && (response.status == 200)) {
              const dialog: RecordInfoDialog = (this.$refs.recordDlg as RecordInfoDialog);
              dialog.openDialog(response.data);
            } else {
              this.$notify({
                title: this.$t('DataRequest.ErrorTitle').toString(),
                type: 'error',
                duration: 5000,
                message: response && response.data.error ? response.data.error : this.$t('DataRequest.UnknownError').toString(),
                position: 'bottom-right',
              });
            }
            this.finalizeLoading();
          }).catch(() => {
            this.finalizeLoading();
          }).finally(() => {
            this.finalizeLoading();
          });

        }

        protected clearFilters(reload: boolean = true): void {
          this.tableSearchExternal = true;
          this.tableLoadEnabled = false;
          try {
            this.tableSearch = '';
            if (this.onClearFilters) {
              const fltKey = 'flt_' + this.filterKey;
              this.onClearFilters(fltKey);
            }
          } finally {
            this.tableLoadEnabled = true;
            if (reload) {
              this.loadTableData();
            }
          }
        }

        protected loadUserFilters(): void {
          if (serviceDataStore.isCustomer) {
            return;
          }
          const filterObj: any = {
            search: '',
            filterKey: this.filterKey,
          };

          const reqData: IDataRequest = {
            page: 0,
            itemsPerPage: 0,
            filter: JSON.stringify(filterObj)
          };

          serviceDataApi.getList('user-filters', reqData).then((response: AxiosResponse) => {
            if (response && (response.status == 200) && (response.data.items)) {
              this.fUserFilters = response.data.items;
            }
          });
        }

        private get showPagination(): boolean {
          return this.paginationEnabled && (this.tableCount > this.tableOptions.itemsPerPage);
        }

        protected loadFilters(): void {
          const fltKey = 'flt_' + this.filterKey;
          this.tableSearch = this.options.defaultFilterValue ?? storageService.getItem(`${fltKey}_search`, '');
          if (this.onLoadFilters) {
            this.onLoadFilters(fltKey);
          }

          // numer strony
          if (this.paginationEnabled) {
            this.tableOptions.page = parseInt(storageService.getItem(`${fltKey}_page`, '1'), 10);
          }
        }

        protected loadOptions(): void {
          const options = storageService.getItem(`${this.filterKey}_options`);
          if (options.length) {
            const optionsObject: IDataTableStorageOptions = JSON.parse(options);
            this.options.itemsPerPage = optionsObject.itemsPerPage ?? 15;
            if (optionsObject.multiSelectable) {
              this.options.multiSelectable = true;
            }
          }
        }

        protected saveOptions(): void {
            const saveData = {
              multiSelectable: this.options.multiSelectable,
              itemsPerPage: this.tableOptions.itemsPerPage
            };
            storageService.setItem(`${this.filterKey}_options`, JSON.stringify(saveData));
        }

        protected evaluateLp(index: number): number {
            return ((this.tableOptions.loadedPage - 1) * this.tableOptions.itemsPerPage) + index + 1;
        }

        protected columnStyle(column: IDataTableColumn): any {
          const styles: string[] = [];
          if (column.style && (IDataColumnStyle.csBold in column.style)) {
            styles.push('font-weight: bold');
          }
          return column.style ? styles.join(';') : undefined
        }

        protected columnSortable(column: IDataTableColumn): string|undefined {
          return (typeof column.sortable === 'undefined') || Boolean(column.sortable) ? 'custom' : undefined;
        }

        protected columnLabel(column: IDataTableColumn): string {
            return column.customLabel && column.customLabel.length ? column.customLabel : column.label;
        }

        protected booleanValue(value: boolean): string {
            return value ? '<i class="fa fa-check"></i>' : '<i class="fa fa-times"></i>';
        }

        protected dateValue(field: string, format: string = "YYYY-MM-DD"): string {
            return field ? moment(field).format(format) : '';
        }

        protected dateTimeValue(field: string, format: string = "YYYY-MM-DD HH:mm"): string {
            return field ? moment(field).format(format) : '';
        }

        protected currencyValue(value: number): string {
            return ((value !== null) && (value !== 0)) ? (value / 100).toFixed(2) : '';
        }

      protected floatValue(value: number): string {
        return (value !== null) ? (value / 100).toFixed(2) : '';
      }

        protected userValue(user: any): string {
            return user ? `${user.name} ${user.surname}` : '';
        }

        protected customerValue(customer: any): string {
            return customer?.name ?? '';
        }

        protected getColumnValue(row: any, column: string): string {
            if (column.indexOf('.') > 0) {
                const cols: string[] = column.split('.');
                let obj = row;
                cols.forEach(col => {
                  obj = obj ? obj[col] : null;
                });
                return obj ?? '';
            } else {
                return row[column];
            }
        }

        protected saveColumns(): void {
          const columnSettings = JSON.stringify(this.options.columns);
          storageService.setItem(`${this.filterKey}_cols`, columnSettings);
        }

        protected loadColumnSettings(): void {
            const cols = storageService.getItem(`${this.filterKey}_cols`);
            if (cols.length) {
              const columnSettings: IDataTableColumn[] = JSON.parse(cols);

              this.options.columns.forEach((col, index) => {
                const collSett = columnSettings.find((sett) => sett.name === col.name);
                if (collSett) {
                  col.width = collSett.width ?? col.width;
                  col.label = collSett.label ?? col.label;
                  col.customLabel = collSett.customLabel ?? col.customLabel;
                  col.visible = collSett.visible ?? col.visible;
                  col.index = collSett.index ?? col.index ?? index;
                }
              });
              this.options.columns.sort((col1, col2) => {
                if (col1.index > col2.index) return 1;
                if (col1.index < col2.index) return -1;
                return 0;
              });
            }
            this.isPrepared = true;
        }

        private get columnSettings(): IDataTableColumn[] {
          return this.options.columns;
        }

        protected loadUsrFilter(event: MouseEvent, filter: IUserFilter): void {
          if (event.ctrlKey) {
            this.removeFilter(filter);
            return;
          }
          this.clearFilters(false);
          this.tableLoadEnabled = false;
          try {
            const data = JSON.parse(atob(filter.filter));
            const keys = Object.keys(data);
            for (let i = 0; i < keys.length; ++i) {
              const key = keys[i];
              const value = data[key];
              storageService.setItem(key, value);
            }
            this.tableSearchExternal = true;
            this.loadFilters();
          } finally {
            this.tableLoadEnabled = true;
            this.loadTableData();
          }
        }

        protected saveUserFilter(): void {
          const dialog: InputDialog = (this.$refs.inpDlg as InputDialog);
          if (!dialog) {
            return;
          }
          const title = this.$t('common.filterName').toString();
          dialog.openDialog(title).then((result: string) => {
            if (result && result.length) {
              const userId = serviceDataStore.userId;
              const fltKey = `${userId}__flt_${this.filterKey}`;
              const filterData = {};

              const keys = storageService.getUserKeys();
              keys.forEach((key) => {
                if (key && key.includes(fltKey)) {
                  filterData[key] = storageService.getItem(key);
                }
              })

              const newFilter = {
                filterName: result,
                filterKey: this.filterKey,
                filter: btoa(JSON.stringify(filterData))
              };
              serviceDataApi.postData('user-filters', newFilter).then((response: AxiosResponse) => {
                if (response && (response.status == 200)) {
                  this.fUserFilters.push(response.data);
                  this.$notify({
                    title: '',
                    type: 'success',
                    duration: 5000,
                    message: this.$t('common.FilterSaved').toString(),
                    position: 'bottom-right'
                  });
                }
              });
            }
          });
        }

        protected saveFilters(): void {
            const fltKey = 'flt_' + this.filterKey;
            storageService.setItem(`${fltKey}_search`, this.tableSearch);
            if (this.onSaveFilters) {
              this.onSaveFilters(fltKey);
            }
        }

        protected savePage(): void {
            const fltKey = 'flt_' + this.filterKey;
            // numer strony
            storageService.setItem(`${fltKey}_page`, (this.paginationEnabled ? this.tableOptions.page : -1).toString());
        }

        private keyListener(event: KeyboardEvent) {
            if (event.key === "Insert") {
                if (this.insertEnabled) {
                    this.insertItem();
                }
            }
        }

        // whether edit windows should open modally
        private get modalEdit(): boolean {
          return this.options.modalEdit ?? false;
        }

        private loadAccess(): void {
          const bEditAccess = (!this.options.editAccess || serviceDataStore.hasRight(this.options.editAccess));
          this.adminAccess = !serviceDataStore.isCustomer && serviceDataStore.hasRight(UserPermission.ADMIN_USER);
          this.viewAccess = (this.options.canView ?? false) && ((this.options.viewRoute !== undefined) || (this.onViewClick !== null)) && (!this.options.viewAccess || serviceDataStore.hasRight(this.options.viewAccess));
          this.editAccess = !this.disabled && this.options.canEdit && ((this.options.editRoute !== undefined) || (this.onEditClick !== null)) && bEditAccess;
          this.deleteAccess = !this.disabled && this.options.canDelete && (!this.options.deleteAccess || serviceDataStore.hasRight(this.options.deleteAccess));
          this.insertAccess = !this.disabled && this.options.canInsert && ((this.options.insertRoute !== undefined) || (this.onAddClick !== null)) && bEditAccess;
          this.exportAccess = this.options.canExport && (!this.options.exportAccess || serviceDataStore.hasRight(this.options.exportAccess));
        }

        private initialize() {
          this.filterKey = this.options.filterKey ?? replaceAll(this.options.tableEndpoint, '-', '_');
          this.loadOptions();
          this.loadColumnSettings();
          this.actionsWidth = this.options.actionsWidth ? this.options.actionsWidth : 120;
          this.loadAccess();

          this.showHeader = this.options.showHeader ?? true;
          this.configurableColumns = this.options.configurableColumns ?? false;
          this.paginationEnabled = this.options.paginationEnabled ?? true;
          this.tableOptions.itemsPerPage = this.options.itemsPerPage ?? 15;

          if (this.options.defaultSort) {
            this.tableOptions.sortBy?.push(this.options.defaultSort.prop);
            this.tableOptions.sortDesc?.push(this.options.defaultSort.order === 'descending');
          }
        }

        created() {
          window.addEventListener("keyup", this.keyListener);
          this.initialize();
        }

        destroyed() {
          window.removeEventListener("keyup", this.keyListener);
        }

        mounted(): void {
            this.setHeaders();
            this.loadUserFilters();

            this.tableSearchExternal = true;
            this.tableLoadEnabled = false;
            try {
              this.loadFilters();
            } finally {
              this.tableLoadEnabled = true;
            }

            if (this.loadOnStart) {
                this.loadTableData();
            }

            if (this.options.refreshInterval && (this.options.refreshInterval > 1)) {
                this.refreshIntervalId = setInterval(() => {
                    if (!this.tableIsLoading && this.canLoadData()) {
                        if (this.refeshCounter < 0) {
                            this.refeshCounter = this.options.refreshInterval ?? -1;
                        }
                        this.refeshCounter--;
                        if (this.refeshCounter === 0) {
                            this.loadTableData();
                            this.refeshCounter = this.options.refreshInterval ?? -1;
                        }
                    }
                }, 1000);
            }
            if (this.$refs.searchInput) {
                this.$refs.searchInput.focus();
            }

            setTimeout(() => {
              this.tableSearchExternal = false;
            }, 300);
        }

        public beforeDestroy(): void {
            if (this.refreshIntervalId !== 0) {
                clearInterval(this.refreshIntervalId);
            }
        }

        private get btnClass(): string {
            return (this.options.miniButtons ?? false) ? 'table-btn-mini' : '';
        }

        public clearSelection(): void {
            if (this.$refs.dataTableEl) {
                (this.$refs.dataTableEl as ElTable).clearSelection();
            }
        }

        public getSelected(): any[] {
            return this.multipleSelection;
        }

        private handleSelectionChange(val: any) {
            this.multipleSelection = val;
            if (this.onSelectionChange) {
                this.onSelectionChange(val);
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private getRowClass(data: any): string {
            return this.rowClass ? this.rowClass(data) : '';
        }

        private dragHeaderEnd(width: number, oldWidth: number, column: any): void {
            const collSett = this.options.columns.find((sett) => sett.name === column.property);
            if (collSett) {
                collSett.width = width;
                this.saveColumns();
            }
        }

        private handleRowClick(row: any, col: any): void {
            if (!col || !col.label) {
                return;
            }
            let res = false;
            if (this.onRowClick) {
                res = this.onRowClick(row, col);
            }
            if (!res) {
                if (this.editEnabled(row)) {
                    this.editItem(row);
                } else if (this.viewEnabled(row)) {
                    this.viewItem(row);
                }
            }
        }

        private pageChange(page: number): void {
            this.tableOptions.page = page;
            this.loadTableData();
        }

        protected onSelectperPage(perPage: number): void {
          this.tableOptions.itemsPerPage = perPage;
          this.tableOptions.page = 1;
          this.saveOptions();
          this.loadTableData();
        }

        private get multiSelected(): boolean {
            return this.multipleSelection.length > 0;
        }

        private get isMultiSelectable(): boolean {
            return this.options.multiSelectable ?? false;
        }

        private get lpFieldVisible(): boolean {
            return this.options.LpVisible ?? true;
        }

        private get idFieldVisible(): boolean {
            return this.options.IdVisible ?? false;
        }

        private get filterManagement(): boolean {
          return this.options.filterManagement ?? true;
        }

        private get filterEnabled(): boolean {
            return this.options.defaultfilterEnabled ?? true;
        }

        private get insertEnabled(): boolean {
            return this.insertAccess;
        }

        private get refreshEnabled(): boolean {
            return (this.options.refreshEnabled ?? true);
        }

        private get dropdownOperations(): boolean {
          return this.options.dropdownOperations ?? true;
        }

        private handleDropCommand(command: ICommandAction): void {
          if (!command.row) return;
          if (command.action === 'delete') {
            this.deleteItem(command.row);
          } else {
            this.$emit('dropCommand', command);
          }
        }

        private get hasRowActions(): boolean {
          return this.options.hasRowActions ?? true;
        }

        private get hasActionsColumn(): boolean {
          return this.options.hasActionsColumn ?? true;
        }

        private get actionsColWidth(): number {
          return this.dropdownOperations ? 120 : this.actionsWidth;
        }

        private get exportEnabled(): boolean {
            return this.options.canExport && this.exportAccess && (this.options.columns !== undefined);
        }

        private handleDropClick(row: any): void {
          if (this.editEnabled(row)) {
            this.editItem(row);
          } else if (this.viewEnabled(row)) {
            this.viewItem(row);
          }
        }

        private dropButtonText(item: IDataRecord): string {
          if (this.editEnabled(item)) {
            return this.$t('common.Edit').toString();
          } else if (this.viewEnabled(item)) {
            return this.$t('common.View').toString();
          } else {
            return '...';
          }
        }

        private editEnabled(item: IDataRecord): boolean {
            let canEdit = this.options.canEdit && ((this.options.editRoute !== undefined) || (this.onEditClick !== null))
                && (item.id > 0) && this.editAccess;
            canEdit = canEdit && ((this.onCanEdit === null) || this.onCanEdit(item));
            return canEdit;
        }

        private viewEnabled(item: IDataRecord): boolean {
            return (item.id > 0) && this.viewAccess;
        }

        private deleteEnabled(item: IDataRecord): boolean {
            let canDelete = this.options.canDelete && (item.id > 0) && this.deleteAccess;
            canDelete = canDelete && ((this.onCanDelete === null) || this.onCanDelete(item));
            return canDelete;
        }

        protected setHeaders(): void {
            //
        }

        private insertItem(): void {
            if (this.onAddClick) {
                this.onAddClick();
            } else if (this.modalEdit) {
                this.$emit('actions', {
                  actionName: 'INSERT', id: 0
                });
            } else {
                this.$router.push({ name: this.options.insertRoute, params: {}, query: this.queryParams });
            }
        }

        private viewItem(item: IDataRecord): void {
          if (this.onViewClick !== null) {
            this.onViewClick(item.id, item);
          } else if (this.modalEdit) {
            this.$emit('actions', {
              actionName: 'VIEW', id: item.id
            });
          } else {
            this.$router.push({name: this.options.viewRoute, params: {id: item.id.toString()}});
          }
        }

        private editItem(item: IDataRecord): void {
            if (this.onEditClick !== null) {
                this.onEditClick(item.id, item);
            } else if (this.modalEdit) {
                this.$emit('actions', {
                  actionName: 'EDIT', id: item.id
                });
            } else {
                this.$router.push({ name: this.options.editRoute, params: { id: item.id.toString() } });
            }
        }

        protected async openColumnsConfig(event: MouseEvent): Promise<void> {
            event.preventDefault();
            const dialog: DataTableSettingsDialog = (this.$refs.settingsDlg as DataTableSettingsDialog);
            if (!dialog) {
                return;
            }
            dialog.openDialog(this.options.columns, this.options).then((result: IDataTableSettingsDialogResult) => {
                if (result) {
                    this.options.multiSelectable = result.options.multiSelectable;
                    this.options.columns?.forEach((col, index) => {
                        const collSett = result.columns.find((sett) => sett.name === col.name);
                        if (collSett) {
                            col.width = collSett.width ?? col.width;
                            col.label = collSett.label ?? col.label;
                            col.visible = collSett.visible ?? col.visible;
                            col.index = collSett.index ?? col.index ?? index;
                        }
                    });
                    this.saveOptions();
                    this.saveColumns();
                    location.reload();
                } else {
                    storageService.removeItem(`${this.filterKey}_cols`);
                    location.reload();
                }
            });
        }

        private deleteAllSelected(): void {
            if (!this.deleteEnabled({id: 1})) {
                return;
            }
            dialogService.confirmDlg.openDeleteDialog().then((confirmed: boolean) => {
                if (confirmed) {
                    const sel = this.getSelected();
                    serviceDataApi.deleteMultiple(this.options.tableEndpoint, sel).then((response: AxiosResponse) => {
                        if (response && (response.status == 200)) {
                            this.$notify({
                                title: '',
                                type: 'success',
                                duration: 5000,
                                message: this.$t('DataRequest.RecordDeleted').toString(),
                                position: 'bottom-right'
                            });
                            this.loadTableData();
                        } else {
                            this.$notify({
                                title: '',
                                type: 'error',
                                duration: 5000,
                                message: this.$t('DataRequest.RecordDeleteErr').toString(),
                                position: 'bottom-right',
                            });
                            this.loadTableData();
                        }
                    });
                }
            });
        }

        protected async deleteItem(item: IDataRecord): Promise<void> {
            if (!this.deleteEnabled(item)) {
                return;
            }
            dialogService.confirmDlg.openDeleteDialog().then((confirmed: boolean) => {
                if (confirmed) {
                    serviceDataApi.delete(this.options.tableEndpoint, item.id).then((response: AxiosResponse) => {
                        if (response && (response.status == 200)) {
                            this.$notify({
                                title: '',
                                type: 'success',
                                duration: 5000,
                                message: this.$t('DataRequest.RecordDeleted').toString(),
                                position: 'bottom-right'
                            });
                            this.loadTableData();
                        } else {
                            let messageStr: string = '';
                            if (response && response.data && response.data.errorStr) {
                              messageStr = this.$t(response.data.errorStr).toString()
                            } else {
                              messageStr = this.$t('DataRequest.RecordDeleteErr').toString();
                            }
                            this.$notify({
                                title: '',
                                type: 'error',
                                duration: 5000,
                                message: messageStr,
                                position: 'bottom-right',
                            });
                        }
                    }).catch((err) => {
                      let messageStr: string = '';
                      if (err.response && err.response.data && err.response.data.errorStr) {
                        messageStr = this.$t(err.response.data.errorStr).toString()
                      } else {
                        messageStr = this.$t('DataRequest.RecordDeleteErr').toString();
                      }
                      this.$notify({
                        title: '',
                        type: 'error',
                        duration: 5000,
                        message: messageStr,
                        position: 'bottom-right',
                      });
                    });
                }
            });
        }

        public handleSortChange(orderData: ITableOrderData): void {
            this.tableOptions.sortBy = [];
            this.tableOptions.sortDesc = [];

            if (!orderData.prop || !orderData.order) {
                this.loadTableData();
                return;
            }

            const collSett = this.options.columns.find((sett) => sett.name === orderData.prop);
            if (collSett) {
                let asc = orderData.order !== "ascending";
                if (Array.isArray(collSett.orderBy)) {
                  collSett.orderBy.forEach((item) => {
                    this.tableOptions.sortBy?.push(item);
                    this.tableOptions.sortDesc?.push(asc);
                  });
                } else {
                  this.tableOptions.sortBy.push(collSett.orderBy ?? orderData.prop);
                  this.tableOptions.sortDesc.push(asc);
                }
            }

            this.loadTableData();
        }

        protected canLoadData(): boolean {
            if (!this.tableLoadEnabled) {
              return false;
            }
            return this.canLoad ? this.canLoad() : true;
        }

        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        protected getFilters(filters: any): any {
            return this.filters ? this.filters(filters) : filters;
        }

        public refreshClick(event: MouseEvent): void {
            event.preventDefault();
            this.loadTableData();
        }

        public exportClick(event: MouseEvent): void {
            event.preventDefault();
            if (!this.exportAccess || !this.options.columns) {
                return;
            }

            // format choice
            const dialog: ExportFormatDialog = (this.$refs.exportDlg as ExportFormatDialog);
            dialog.openDialog().then((format: string) => {
                if (format !== '') {
                    this.exportTableData(format);
                }
            });
        }

        private initLoading(): void {
            this.tableIsLoading = true;
            setInterval(() => {
                this.tableLoading = this.tableIsLoading;
            }, 250);
        }

        private finalizeLoading(): void {
            this.tableIsLoading = false;
            this.tableLoading = false;
        }

        public async exportTableData(format: string): Promise<void> {
            if (!this.exportAccess) {
                console.warn('No export access rights.');
                return;
            }
            if (!this.options.columns) {
                console.warn('No export columns defined.');
                return;
            }

            this.initLoading();

            const filterObj: any = this.getFilters({
                search: this.tableSearch ? this.tableSearch : undefined
            });

            const reqData: IDataRequest = {
                ...this.tableOptions,
                filter: JSON.stringify(filterObj)
            };

            serviceDataApi.getExportList(this.options.tableEndpoint, reqData).then((response: AxiosResponse) => {
                if (response && (response.status == 200)) {
                    const exportData: any[] = response.data.items;

                    if (format === 'XLS') {
                        exportAsExcel(this.options.columns ?? [], exportData, this.options.tableEndpoint);
                    } else if (format === 'CSV') {
                        exportAsCSV(this.options.columns ?? [], exportData, this.options.tableEndpoint + '.csv');
                    }

                } else {
                    this.$notify({
                        title: this.$t('DataRequest.ErrorTitle').toString(),
                        type: 'error',
                        duration: 5000,
                        message: response && response.data.error ? response.data.error : this.$t('DataRequest.UnknownError').toString(),
                        position: 'bottom-right',
                    });
                }
                this.finalizeLoading();
            }).catch(() => {
                this.finalizeLoading();
            });
        }

        private loadImage(el: Element): void {
          el.classList.remove("lazy");
          const imageElement = el as HTMLImageElement;
          if (imageElement) {
            imageElement.addEventListener("load", () => {
              setTimeout(() => el.classList.remove("lazy"), 100);
            });
            // imageElement.addEventListener("error", () => console.log("error"));
            imageElement.src = imageElement.dataset.src ?? '';
          }
        }

        private handleIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver): void {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.loadImage(entry.target);
              observer.unobserve(entry.target);
            }
          });
        }

        private createObserver(): void {
          const options: IntersectionObserverInit = {
            root: null,
            threshold: 0
          };
          this.fObserver = new IntersectionObserver(this.handleIntersect, options);
          document.querySelectorAll("img.lazy").forEach((el) => {
            this.fObserver.observe(el);
          });
        }

        private loadTableDataEvent(tableData: any[]): void {
            this.$emit('onLoadData', tableData);
            if (this.options.enableLazyLoad) {
                this.$nextTick(() => {
                    this.createObserver();
                });
            }
        }

        public loadTableData(callbackMethod?: ICallback, forceReload: boolean = false): void {
            if (!forceReload && this.tableIsLoading) {
                return;
            }
            if (!forceReload && !this.canLoadData()) {
                return;
            }

            this.initLoading();

            const filterObj: ITableDataFilter = this.getFilters({
                search: this.tableSearch ? this.tableSearch : undefined
            });

            const reqData: IDataRequest = {
                ...this.tableOptions,
                page: this.paginationEnabled ? this.tableOptions.page : -1,
                filter: JSON.stringify(filterObj)
            };

            serviceDataApi.getList(this.options.tableEndpoint, reqData).then((response: AxiosResponse) => {
                if (response && (response.status == 200)) {
                    this.saveFilters();
                    this.savePage();

                    this.tableOptions.loadedPage = this.tableOptions.page;
                    this.tableData = response.data.items;
                    this.tableCount = response.data.total;

                    this.loadTableDataEvent(this.tableData);
                    if (callbackMethod) {
                      callbackMethod();
                    }
                    if (this.options.refreshInterval && (this.options.refreshInterval > 1)) {
                        this.refeshCounter = this.options.refreshInterval ?? -1;
                    }
                } else {
                    this.$notify({
                        title: this.$t('DataRequest.ErrorTitle').toString(),
                        type: 'error',
                        duration: 5000,
                        message: response && response.data.error ? response.data.error : this.$t('DataRequest.UnknownError').toString(),
                        position: 'bottom-right',
                    });
                }
                this.finalizeLoading();
            }).catch(() => {
                this.finalizeLoading();
            }).finally(() => {
              this.finalizeLoading();
              if (this.paginationEnabled) {
                const expectedCount = (this.tableOptions.page-1) * this.tableOptions.itemsPerPage;
                // jezeli zwrocona ilosc jest mniejsza od spodziewanej, dajemy page 1
                if (this.tableCount < expectedCount) {
                  this.tableOptions.page = 1;
                  this.loadTableData(callbackMethod, true);
                }
              }
            });
        }

    }

