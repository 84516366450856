import UserPermission, {AppModules} from "@common/access";

export default [
    {
        path: "/guests",
        name: "Guests",
        component: () =>
            import(/* webpackChunkName: "guests" */ "../views/guests/Guests.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.GUESTS],
            requiresAccess: UserPermission.GUESTS_READ,
        }
    },
    {
        path: "/guests/edit/:id",
        name: "GuestsEdit",
        component: () =>
            import(/* webpackChunkName: "guests" */ "../views/guests/GuestsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.GUESTS],
            requiresAccess: UserPermission.GUESTS_WRITE,
        }
    },
    {
        path: "/guests/add",
        name: "GuestsInsert",
        component: () =>
            import(/* webpackChunkName: "guests" */ "../views/guests/GuestsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.GUESTS],
            requiresAccess: UserPermission.GUESTS_WRITE,
        }
    },
];