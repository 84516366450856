import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import AdminMain from "@/views/AdminMain.vue";
import guardRoutes, {getGuardedRoutes} from "@/router/routeGuard";
import smsRoutes from "@/router/smsRoutes";
import leaveCardsRoutes from "@/router/leaveCardsRoutes";
import banksRoutes from "@/router/banksRoutes";
import guestsRoutes from "@/router/guestsRoutes";
import cardRoutes from "@/router/cardRoutes";
import carsRoutes from "@/router/carsRoutes";
import crmRoutes from "@/router/crmRoutes";
import complaintRoutes from "@/router/complaintRoutes";
import currencyRoutes from "@/router/currencyRoutes";
import workCardsRoutes from "@/router/workCardsRoutes";
import productRoutes from "@/router/productRoutes";
import customerRoutes from "@/router/customerRoutes";
import coreRoutes from "@/router/coreRoutes";
import structureRoutes from "@/router/structureRoutes";
import keysRoutes from "@/router/keysRoutes";
import stocksRoutes from "@/router/stocksRoutes";
import pricingRoutes from "@/router/pricingRoutes";
import dictionariesRoutes from "@/router/dictionariesRoutes";
import usersRoutes from "@/router/usersRoutes";
import orderRoutes from "@/router/orderRoutes";
import workRoutes from "@/router/workRoutes";
import invoicesRoutes from "@/router/invoicesRoutes";
import receptionRoutes from "@/router/receptionRoutes";
import reportRoutes from "@/router/reportRoutes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [];

// ----------------------------------------------------------------------------------------------------------------

routes.push(...coreRoutes);

routes.push(...reportRoutes);
routes.push(...invoicesRoutes);
routes.push(...receptionRoutes);
routes.push(...workRoutes);
routes.push(...orderRoutes);
routes.push(...usersRoutes);
routes.push(...stocksRoutes);
routes.push(...pricingRoutes);
routes.push(...dictionariesRoutes);
routes.push(...keysRoutes);
routes.push(...structureRoutes);
routes.push(...customerRoutes);
routes.push(...productRoutes);
routes.push(...workCardsRoutes);
routes.push(...currencyRoutes);
routes.push(...complaintRoutes);
routes.push(...crmRoutes);
routes.push(...carsRoutes);
routes.push(...cardRoutes);
routes.push(...guestsRoutes);
routes.push(...banksRoutes);
routes.push(...smsRoutes);
routes.push(...leaveCardsRoutes);

// ----------------------------------------------------------------------------------------------------------------


routes.push({
  path: '*',
  name: 'Default',
  component: AdminMain,
  meta: {
    requiresAuth: true,
  }
});

// ----------------------------------------------------------------------------------------------------------------


const guardedRoutes: RouteConfig[] = getGuardedRoutes(routes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: guardedRoutes,
});

// Meta Handling
router.beforeEach(guardRoutes);

export default router;
