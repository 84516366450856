
    import {Component, Watch} from "vue-property-decorator";
    import draggable from 'vuedraggable'
    import DataTable from "@/components/custom/DataTable.vue";
    import BaseView from "@/views/base/BaseView.vue";
    import PageTitle from "@/components/PageTitle.vue";
    import Scheduler from "@/components/Scheduler.vue";
    import {serviceDataApi, serviceDataStore, storageService} from "@/services/service-container";
    import { AxiosResponse } from "axios";
    import PlaceSelect from "@/components/select/PlaceSelect.vue";
    import contextMenu from "vue-context-menu";
    import moment from "moment";
    import {Debounce} from "vue-debounce-decorator";
    import {Configuration} from "@/common/config";
    import OrderIndicator from "@/components/OrderIndicator.vue";
    import {exportAsCSV, exportAsExcel} from "@/utils/excel";
    import ExportFormatDialog from "@/components/dialogs/ExportFormatDialog.vue";
    import {DataTableFormat} from "@/common/enums";

    @Component({
        components: {
            DataTable,
            PageTitle,
            Scheduler,
            PlaceSelect,
            draggable,
            contextMenu,
            OrderIndicator,
            ExportFormatDialog
        },
    })
    export default class WorkScheduler extends BaseView {
        $refs!: {
            ctxMenu: contextMenu;
            exportDlg: ExportFormatDialog;
        }

        private tableSearch: string = '';
        @Watch('tableSearch')
        @Debounce(250)
        onSearchPropertyChanged(): void {
          this.filterFreeItems();
        }

        private freeKey: number = 0;
        private plannedKey: number = 0;
        private selectedCount: number = 0;
        private plannedSelectedCount: number = 0;
        private fCurrentPlaceId: number|null = null;

        private plannedPerPage: number = 50;
        private plannedPage = 0;

        private notPlannedPerPage: number = 50;
        private notPlannedPage = 1;

        private fPriorities: IOrderPriority[] = [];

        private lastSortName: string = 'priority';
        private lastSortDir = -1;
        private fFreeGroups: IOrderItemGroup[] = [];
        private fPlaceItems: IOrderItemServiceDetails[] = [];
        private fFreeItems: IOrderItemServiceDetails[] = [];
        private fAllFreeItems: IOrderItemServiceDetails[] = [];

        private drag: boolean = false;

        private tableIsLoading: boolean = false;
        private tableLoading: boolean = false;
        private contextScope: IOrderItemServiceDetails;

        private changePriority(prio: IOrderPriority): void {
          if (this.contextScope && this.contextScope.orderItemId && this.contextScope.orderItem
              && (this.contextScope.orderItem.orderPriorityId !== prio.id)) {
            serviceDataApi.postData(`scheduler/priority`, {
              orderItemId: this.contextScope.id,
              orderPriorityId: prio.id
            }).then((response: AxiosResponse) => {
              if (response && (response.status == 200)) {
                if (this.contextScope.orderItem) {
                  this.contextScope.orderItem.orderPriorityId = prio.id ?? 0;
                  this.contextScope.orderItem.orderPriority = prio;
                }
              }
            });
          }
        }

        private loadPriorities(): void {
          const reqData: IDataRequest = {
            page: 0,
            itemsPerPage: 0,
            filter: JSON.stringify({})
          };

          serviceDataApi.getList(`search/order-priorities-search`, reqData).then((response: AxiosResponse) => {
            if (response && (response.status == 200) && (response.data.items)) {
              this.fPriorities = response.data.items;
            }
          })
        }

        private dateFormat(dateObj: Date): string {
          return moment(dateObj).format("YYYY-MM-DD");
        }

        private productStep(item: IOrderItemServiceDetails): string {
          return item.product ? `<b>${item.product.code}</b><br>${item.product.externalCode}` : '';
        }

        private product1(item: IOrderItemServiceDetails): string {
          return item.orderItem?.product1 ? `<b>${item.orderItem?.product1.code}</b><br>${item.orderItem?.product1.externalCode}` : '';
        }

        private openContext(item: IOrderItemServiceDetails): void {
          this.contextScope = item;
          this.$refs.ctxMenu.open();
        }

        private moveFront(): void {
          if (this.contextScope) {
            const fromIndex = this.fPlaceItems.indexOf(this.contextScope);
            const toIndex = 0;
            const element = this.fPlaceItems.splice(fromIndex, 1)[0];
            this.fPlaceItems.splice(toIndex, 0, element);
            this.savePlan();
          }
        }

        private moveBack(): void {
          if (this.contextScope) {
            const fromIndex = this.fPlaceItems.indexOf(this.contextScope);
            const toIndex = this.fPlaceItems.length - 1;
            const element = this.fPlaceItems.splice(fromIndex, 1)[0];
            this.fPlaceItems.splice(toIndex, 0, element);
            this.savePlan();
          }
        }

        private moveToPlanFront(): void {
            this.checkMultiDrops(null, 0);
            this.savePlan();
        }

        private moveToPlanBack(): void {
            this.checkMultiDrops(null, this.fPlaceItems.length);
            this.savePlan();
        }

        private removeFromPlan(): void {
            if (this.contextScope) {
                const fromIndex = this.fPlaceItems.indexOf(this.contextScope);
                const element = this.fPlaceItems.splice(fromIndex, 1)[0];
                element.tempIsSelected = false;
                this.fFreeItems.push(element);
                this.saveRemoved(this.contextScope);
            }

            const itemsToMove: IOrderItemServiceDetails[] = [];
            for (const item of this.fPlaceItems) {
              if (item.tempIsSelected && (!this.contextScope || (item.id !== this.contextScope.id))) {
                itemsToMove.push(item);
              }
            }
            this.fPlaceItems = this.fPlaceItems.filter(item => !item.tempIsSelected);
            itemsToMove.forEach((item) => {
              item.tempIsSelected = false;
              this.fFreeItems.push(item);
              this.saveRemoved(item);
            });

            this.plannedSelectedCount = 0;
            this.plannedKey += 1;
            this.freeKey += 1;
            /*
            this.fPlaceItems.forEach((item) => {
              if (item.tempIsSelected) {
                const fromIndex = this.fPlaceItems.indexOf(item);
                if (fromIndex >= 0) {
                  const element = this.fPlaceItems.splice(fromIndex, 1)[0];
                  element.tempIsSelected = false;
                  this.fFreeItems.push(element);
                }
              }
            });*/
            this.applyOrderBy();
        }

        private recountSelected(): void {
          this.selectedCount = 0;
          this.plannedSelectedCount = 0;
          this.fFreeItems.forEach((item) => {
            this.selectedCount += item.tempIsSelected ? 1 : 0;
          });
          this.fPlaceItems.forEach((item) => {
            this.plannedSelectedCount += item.tempIsSelected ? 1 : 0;
          });
        }

        private dragChangeHandle(params: any): void {
          if (params.added) {
            params.added.element.tempIsSelected = false;
            this.recountSelected();
            this.checkMultiDrops(params.added.element);
            this.savePlan();
          }
          if (params.removed) {
            params.removed.element.tempIsSelected = false;
            this.recountSelected();
            this.saveRemoved(params.removed.element);
          }
          if (params.moved) {
            this.recountSelected();
            this.savePlan();
          }
        }

        private savePlan(): void {
          this.fPlaceItems.forEach((item, index) => {
            const data = {
              planPlaceId: this.fCurrentPlaceId,
              planDate: new Date(),
              planSeq: index,
            }
            serviceDataApi.putData(`scheduler/${item.id}`, data);
          });
        }

        private saveRemoved(item: IOrderItemServiceDetails): void {
          const data = {
            planPlaceId: null,
            planDate: null,
            planSeq: 0,
          }
          serviceDataApi.putData(`scheduler/${item.id}`, data);
        }

        private checkMultiDrops(element: IOrderItemServiceDetails|null, defaultPos: number = 0): void {
          const itemsToMove: IOrderItemServiceDetails[] = [];
          for (const item of this.fFreeItems) {
            if (item.tempIsSelected && (!element || (item.id !== element.id))) {
              itemsToMove.push({
                ...item,
                tempIsSelected: false
              });
            }
          }

          const ind = element ? this.fPlaceItems.indexOf(element) : defaultPos;
          itemsToMove.forEach((item) => {
            this.fPlaceItems.splice(ind, 0, item);
          });

          this.fFreeItems = this.fFreeItems.filter(item => !item.tempIsSelected);
          this.selectedCount = 0;
        }

        private plannedRowSelect(item: IOrderItem): void {
          item.tempIsSelected = !item.tempIsSelected;
          this.plannedSelectedCount += item.tempIsSelected ? 1 : -1;
          this.plannedKey += 1;
        }

        private rowSelect(item: IOrderItem): void {
          item.tempIsSelected = !item.tempIsSelected;
          this.selectedCount += item.tempIsSelected ? 1 : -1;
          this.freeKey += 1;
        }

        private colClass(name: string): string {
          return this.lastSortName === name ? 'is-sorted' : '';
        }

        private plannedRowClass(item: IOrderItem): string {
          const arr: string[] = ['item-row'];
          if (item.tempIsSelected) {
            arr.push('is-selected');
          }
          return arr.join(' ');
        }

        private rowClass(item: IOrderItem): string {
          const arr: string[] = ['item-row'];
          if (item.tempSortKind % 2 === 0) arr.push('item-odd'); else arr.push('item-row item-even');
          if (item.tempIsSelected) {
            arr.push('is-selected');
          }
          return arr.join(' ');
        }

        private get dragOptions(): any {
            return {
                animation: 200,
                group: "columns",
                disabled: false,
                ghostClass: "ghost"
            };
        }

        private orderDir(field: string): number {
          if (this.lastSortName === field) {
            return this.lastSortDir;
          } else {
            return 0;
          }
        }

        private orderBy(name: string): void {
          if (this.lastSortName === name) {
            this.lastSortDir = this.lastSortDir * -1;
          } else {
            this.lastSortName = name;
            this.lastSortDir = 1;
          }
          this.applyOrderBy();
          this.applySortValue()
        }

        private applyOrderBy(): void {
          this.fFreeItems.sort((col1: IOrderItemServiceDetails, col2: IOrderItemServiceDetails) => {
            if (this.lastSortName === 'customer') {
              if (col1.orderItem?.customer?.name > col2.orderItem?.customer?.name) return this.lastSortDir;
              if (col1.orderItem?.customer?.name < col2.orderItem?.customer?.name) return this.lastSortDir * -1;
            } else if (this.lastSortName === 'externalNumber') {
              const name1 = col1.orderItem?.externalNumber ?? '';
              const name2 = col2.orderItem?.externalNumber ?? '';
              if (name1 > name2) return this.lastSortDir;
              if (name1 < name2) return this.lastSortDir * -1;
            } else if (this.lastSortName === 'note') {
              const name1 = col1.orderItem?.note ?? '';
              const name2 = col2.orderItem?.note ?? '';
              if (name1 > name2) return this.lastSortDir;
              if (name1 < name2) return this.lastSortDir * -1;
            } else if (this.lastSortName === 'name') {
              const name1 = col1.orderItem?.name ?? '';
              const name2 = col2.orderItem?.name ?? '';
              if (name1 > name2) return this.lastSortDir;
              if (name1 < name2) return this.lastSortDir * -1;
            } else if (this.lastSortName === 'service') {
              const service1 = col1.orderItem?.service?.name ?? '';
              const service2 = col2.orderItem?.service?.name ?? '';
              if (service1 > service2) return this.lastSortDir;
              if (service1 < service2) return this.lastSortDir * -1;
            } else if (this.lastSortName === 'ingredient') {
              const prio1 = col1.orderItem?.product1?.code ?? 0;
              const prio2 = col2.orderItem?.product1?.code ?? 0;
              if (prio1 > prio2) return this.lastSortDir;
              if (prio1 < prio2) return this.lastSortDir * -1;
            } else if (this.lastSortName === 'priority') {
              const prio1 = col1.orderItem?.orderPriority?.value ?? 0;
              const prio2 = col2.orderItem?.orderPriority?.value ?? 0;
              if (prio1 > prio2) return this.lastSortDir;
              if (prio1 < prio2) return this.lastSortDir * -1;
            } else if (this.lastSortName === 'amount') {
              const am1 = col1.orderItem?.amount??0;
              const am2 = col2.orderItem?.amount??0;
              if (am1 > am2) return this.lastSortDir;
              if (am1 < am2) return this.lastSortDir * -1;
            } else if (this.lastSortName === 'ral') {
              const prod1A = col1.product?.code ?? '';
              const prod1B = col2.product?.code ?? '';
              if (prod1A > prod1B) return this.lastSortDir;
              if (prod1A < prod1B) return this.lastSortDir * -1;
            } else if (this.lastSortName === 'ral1') {
              const prod1A = col1.orderItem?.product1?.code ?? '';
              const prod1B = col2.orderItem?.product1?.code ?? '';
              if (prod1A > prod1B) return this.lastSortDir;
              if (prod1A < prod1B) return this.lastSortDir * -1;
            } else if (this.lastSortName === 'ral2') {
              const prod2A = col1.orderItem?.product2?.code ?? '';
              const prod2B = col2.orderItem?.product2?.code ?? '';
              if (prod2A > prod2B) return this.lastSortDir;
              if (prod2A < prod2B) return this.lastSortDir * -1;
            } else if (this.lastSortName === 'ral3') {
              const prod3A = col1.orderItem?.product3?.code ?? '';
              const prod3B = col2.orderItem?.product3?.code ?? '';
              if (prod3A > prod3B) return this.lastSortDir;
              if (prod3A < prod3B) return this.lastSortDir * -1;
            } else if (this.lastSortName === 'orderDate') {
              const prod3A = this.dateFormat(col1.orderItem?.orderDate ?? new Date());
              const prod3B = this.dateFormat(col2.orderItem?.orderDate ?? new Date());
              if (prod3A > prod3B) return this.lastSortDir;
              if (prod3A < prod3B) return this.lastSortDir * -1;
            }

            return 0;
          });
        }

        private printScheduler(): void {
          let language = storageService.getItem('user_lang', 'pl');
          if ((language !== 'en') && (language !== 'pl')) {
            language = 'en';
          }
          const pdfUri = `${Configuration.apiEndpoint}scheduler/pdf/${this.fCurrentPlaceId}/${language}?access_token=${serviceDataStore.getSessionId()}`;
          window.open(pdfUri, '');
        }

        private refreshBtnClick(): void {
          this.notPlannedPage = 1;
          this.loadFreeItems();
        }

        private loadMoreNotPlanned(): void {
          this.notPlannedPage += 1;
          this.loadFreeItems();
        }

        private handlePlaceChange(): void {
          this.initLoading();
          try {
            this.loadPlannedItems();
            this.notPlannedPage = 1;
            this.loadFreeItems();
          } finally {
            this.finalizeLoading();
          }
        }

        private handleDropClick(): void {
          this.printScheduler();
        }

        private handleDropCommand(command: string): void {
          if (command === 'export') {
            this.exportPlanned();
          }
        }

        private initLoading(): void {
            this.tableIsLoading = true;
            setInterval(() => {
                this.tableLoading = this.tableIsLoading;
            }, 250);
        }

        private finalizeLoading(): void {
            this.tableIsLoading = false;
            this.tableLoading = false;
        }

        public mounted(): void {
            this.notPlannedPage = 1;
            this.initLoading();
            try {
                this.loadPriorities();
                this.loadFreeItems();
            } finally {
                this.finalizeLoading();
            }
        }

        private loadPlannedItems() {
          const filterObj = {
            planPlaceId: this.fCurrentPlaceId,
            search: ''
          };

          const reqData: IDataRequest = {
            page: this.plannedPage,
            itemsPerPage: this.plannedPerPage,
            sortBy: ['planSeq'],
            sortDesc: [false],
            filter: JSON.stringify(filterObj)
          };

          serviceDataApi.getList('scheduler/planned', reqData).then((response: AxiosResponse) => {
            if (response && (response.status == 200) && (response.data.items)) {
              this.fPlaceItems = response.data.items;
            }
          });
        }

        private getOrderValue(item: IOrderItemServiceDetails): any {
          if (this.lastSortName === 'customer') {
            return item.orderItem?.customer?.name ?? '';
          } else if (this.lastSortName === 'note') {
            return item.orderItem?.note;
          } else if (this.lastSortName === 'name') {
            return item.orderItem?.name;
          } else if (this.lastSortName === 'service') {
            return item.orderItem?.service?.name ?? '';
          } else if (this.lastSortName === 'externalNumber') {
            return item.orderItem?.externalNumber ?? '';
          } else if (this.lastSortName === 'priority') {
            return item.orderItem?.orderPriority?.value ?? 0;
          } else if (this.lastSortName === 'amount') {
            return item.orderItem?.amount ?? 0;
          } else if (this.lastSortName === 'ingredient') {
            return item.orderItem?.product1?.code ?? '';
          } else if (this.lastSortName === 'ral') {
            return item.product?.code ?? '';
          } else if (this.lastSortName === 'ral1') {
            return item.orderItem?.product1?.code ?? '';
          } else if (this.lastSortName === 'ral2') {
            return item.orderItem?.product2?.code ?? '';
          } else if (this.lastSortName === 'ral3') {
            return item.orderItem?.product3?.code ?? '';
          } else if (this.lastSortName === 'orderDate') {
            return this.dateFormat(item.orderItem?.orderDate ?? new Date());
          }
        }

        private applySortValue(): void {
          this.applyOrderBy();

          this.fFreeGroups = [];
          let lastVal = null;
          let lastGroup: IOrderItemGroup|null = null;
          let valKind = 0;
          for (let ii=0; ii<=this.fFreeItems.length-1; ii++) {
            const item = this.fFreeItems[ii];

            const val = this.getOrderValue(item);

            if (val === lastVal) {
              item.tempSortKind = valKind;
            } else {
              valKind += 1;
              lastVal = val;
              lastGroup = {
                groupName: val,
                groupVal: lastVal,
                items: [],
              };
              this.fFreeGroups.push(lastGroup);

              item.tempSortKind = valKind
            }

            lastGroup?.items.push(item);
          }
        }

        private filterFreeItems() {
          this.fFreeItems = this.fAllFreeItems.filter((item) => {
            return ((this.tableSearch.length === 0) || item.tempSearchData.indexOf(this.tableSearch.toLowerCase()) >= 0);
          });
        }

        private fillSearchData(): void {
          this.fAllFreeItems.forEach((item: IOrderItemServiceDetails) => {
            item.tempSearchData = `${item.orderItem?.customer?.name} ${item.orderItem?.numberTxt} ${item.orderItem?.externalNumber}
              ${item.orderItem?.orderPriority?.name} ${item.orderItem?.note} ${item.orderItem?.name}
              ${item.orderItem?.orderDate ? this.dateFormat(item.orderItem?.orderDate) : ''} ${item.name} ${item.orderItem?.service?.name}
              ${item.orderItem?.productProfile?.name}
              ${item.product?.code} ${item.product?.externalCode} ${item.product?.name}
              ${item.orderItem?.product1?.code} ${item.orderItem?.product1?.name}
              ${item.orderItem?.product2?.code} ${item.orderItem?.product2?.name}
              ${item.orderItem?.product3?.code} ${item.orderItem?.product3?.name}`.toLowerCase();
          });
        }

        private loadFreeItems() {
            const filterObj = {
                placeId: this.fCurrentPlaceId,
                search: undefined
            };

            const reqData: IDataRequest = {
                page: this.notPlannedPage,
                itemsPerPage: this.notPlannedPerPage,
                filter: JSON.stringify(filterObj)
            };

            if (this.notPlannedPage === 1) {
              this.fAllFreeItems = [];
            }

            serviceDataApi.getList('scheduler/items', reqData).then((response: AxiosResponse) => {
                if (response && (response.status == 200) && (response.data.items)) {
                    this.fAllFreeItems.push(...response.data.items);
                    this.applySortValue();
                    this.fillSearchData()
                    this.filterFreeItems();
                }
            });
        }

        public exportPlanned(): void {
          // format choice
          const dialog: ExportFormatDialog = (this.$refs.exportDlg as ExportFormatDialog);
          dialog.openDialog().then((format: string) => {
            if (format !== '') {
              this.exportTableData(format);
            }
          });
        }

        public async exportTableData(format: string): Promise<void> {
          this.initLoading();

          const filterObj = {
            planPlaceId: this.fCurrentPlaceId,
            search: ''
          };

          const reqData: IDataRequest = {
            page: 0,
            itemsPerPage: 0,
            sortBy: ['planSeq'],
            sortDesc: [false],
            filter: JSON.stringify(filterObj)
          };

          const expCols = [{
              name: 'orderItem.customer.name',
              label: this.$t('OrderItems.FieldCustomer').toString(),
              exported: true,
              visible: true,
            }, {
              name: 'orderItem.externalNumber',
              label: this.$t('Orders.FieldExternalNumber').toString(),
              exported: true,
              visible: true,
            }, {
              name: 'product.code',
              label: this.$t('OrderItems.IngredientCode').toString(),
              exported: true,
              visible: true,
            }, {
              name: 'product.externalCode',
              label: this.$t('OrderItems.IngredientExternalCode').toString(),
              exported: true,
              visible: true,
            }, {
              name: 'orderItem.orderDate',
              label: this.$t('OrderItems.OrderDate').toString(),
              format: DataTableFormat.DATE,
              exported: true,
              visible: true,
              processor: (data: string) => moment(data).format("YYYY-MM-DD") ?? '',
            }, {
              name: 'orderItem.service.name',
              label: this.$t('OrderItems.FieldService').toString(),
              exported: true,
              visible: true,
            }, {
              name: 'name',
              label: this.$t('OrderItems.FieldStep').toString(),
              exported: true,
              visible: true,
            }, {
              name: 'orderItem.name',
              label: this.$t('OrderItems.FieldName').toString(),
              exported: true,
              visible: true,
            }, {
              name: 'orderItem.amount',
              label: this.$t('OrderItems.FieldAmount').toString(),
              exported: true,
              visible: true,
            }, {
              name: 'orderItem.product1.code',
              label: this.$t('OrderItems.Ingredient1Code').toString(),
              exported: true,
              visible: true,
            }, {
              name: 'orderItem.product1.externalCode',
              label: this.$t('OrderItems.Ingredient1ExternalCode').toString(),
              exported: true,
              visible: true,
            }, {
              name: 'orderItem.note',
              label: this.$t('OrderItems.FieldNote').toString(),
              exported: true,
              visible: true,
            }
          ];

          serviceDataApi.getList('scheduler/planned', reqData).then((response: AxiosResponse) => {
            if (response && (response.status == 200) && (response.data.items)) {
              const exportData: any[] = response.data.items;

              if (format === 'XLS') {
                exportAsExcel(expCols, exportData, 'scheduler');
              } else if (format === 'CSV') {
                exportAsCSV(expCols, exportData, 'scheduler.csv');
              }
            } else {
              this.$notify({
                title: this.$t('DataRequest.ErrorTitle').toString(),
                type: 'error',
                duration: 5000,
                message: response && response.data.error ? response.data.error : this.$t('DataRequest.UnknownError').toString(),
                position: 'bottom-right',
              });
            }
            this.finalizeLoading();
          }).catch(() => {
            this.finalizeLoading();
          });

        }
    }

