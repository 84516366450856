import WorkScheduler from "@/views/administration/Schedule/WorkSchedule.vue";
import UserPermission from "@common/access";
import ShiftScheduler from "@/views/administration/Schedule/ShiftSchedule.vue";

export default [

    {
        path: "/shifts",
        name: "Shifts",
        component: () =>
            import(/* webpackChunkName: "shifts" */ "../views/administration/Shifts/Shifts.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/shifts/edit/:id",
        name: "ShiftsEdit",
        component: () =>
            import(/* webpackChunkName: "shifts" */ "../views/administration/Shifts/ShiftsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/shifts/add",
        name: "ShiftsInsert",
        component: () =>
            import(/* webpackChunkName: "shifts" */ "../views/administration/Shifts/ShiftsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/shift-users/:userId?",
        name: "ShiftUsers",
        component: () =>
            import(/* webpackChunkName: "shift-users" */ "../views/administration/ShiftUsers/ShiftUsers.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.WORK_CARDS_READ,
        }
    },
    {
        path: "/shift-users/edit/:id",
        name: "ShiftUsersEdit",
        component: () =>
            import(/* webpackChunkName: "shift-users" */ "../views/administration/ShiftUsers/ShiftUsersEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.WORK_CARDS_WRITE,
        }
    },
    {
        path: "/shift-users/add",
        name: "ShiftUsersInsert",
        component: () =>
            import(/* webpackChunkName: "shift-users" */ "../views/administration/ShiftUsers/ShiftUsersEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.WORK_CARDS_WRITE,
        },
    },
    {
        path: "/work-schedule",
        name: "WorkSchedule",
        component: WorkScheduler,
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.ORDERS_WRITE,
        },
    },
    {
        path: "/schedule",
        name: "ShiftSchedule",
        component:  ShiftScheduler,
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.ORDERS_WRITE,
        },
    },

    {
        path: "/work-hour-calendar",
        name: "WorkHourCalendar",
        component: () =>
            import(/* webpackChunkName: "reports" */ "../views/reports/WorkHourCalendar.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.REPORTS,
        },
    },
    {
        path: "/work-hour-report",
        name: "WorkHourReport",
        component: () =>
            import(/* webpackChunkName: "reports" */ "../views/reports/WorkHourReport.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.REPORTS,
        },
    },
];