
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class MenuItem extends Vue {
    @Prop()
    item: IMenuConfig;

    private get hasCounter(): boolean {
      return Boolean(this.item && this.item.counterName && (this.item.counterName.length > 0) && (this.counterValue > 0));
    }

    private get counterValue(): number {
      if (!this.item.counterName) {
        return 0;
      }
      if (Array.isArray(this.item.counterName)) {
        let sum = 0;
        this.item.counterName.forEach((item) => {
          sum += this.$store.getters.getCounter(item);
        });
        return sum;
      } else {
        return this.$store.getters.getCounter(this.item.counterName);
      }
    }
}
