

import {Component, Prop, Vue} from "vue-property-decorator";
import moment from "moment/moment";
import {replaceEmotesDef} from "@common/functions";
import {serviceDataStore} from "@/services/service-container";

@Component({
  components: {}
})
export default class MessageItem extends Vue {

  @Prop({ default: null })
  public message: IMessage;

  private selfUserId: number = 0;

  mounted() {
    this.selfUserId = serviceDataStore.getUserData().id;
  }

  private onMsgClick(): void {
    this.message.isTimeVisible = !this.message.isTimeVisible;
  }

  private replaceEmotes(msg: string): string {
    return replaceEmotesDef(msg);
  }

  private getTime(msg: IMessage): string {
    const today = moment(new Date()).format("YYYY-MM-DD");
    return moment(msg.sendDate).format("YYYY-MM-DD HH:mm:ss").replace(today, '').trim();
  }
}
