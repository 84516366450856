
import { Component, Vue } from "vue-property-decorator";
import {errorService, serviceDataApi} from "@/services/service-container";
import {AxiosResponse} from "axios";

@Component({
  components: {
  },
})
export default class SmsGatewayDialog extends Vue {

  private dialog: boolean = false;
  private dialogPhone: string = "";
  private dialogMessage: string = "";
  private dialogTitle = "";
  private dialogIcon: string = "fa fa-mobile";
  private dialogResolve: any;
  private dialogReject: any;
  private tableLoading: boolean = false;
  private tableIsLoading: boolean = false;
  private userId: number = 0;
  private customerId: number = 0;

  public formRules = {
    dialogPhone: [{ required: true, min: 9, max: 12, message: this.$t('SmsGateway.ErrInputPhone').toString(), trigger: 'blur' }],
    dialogMessage: [{ required: true, min: 1, max: 255, message: this.$t('SmsGateway.ErrInputSms').toString(), trigger: 'blur' }],
  };

  mounted() {
    this.dialogTitle = this.$t("SmsGateway.PageTitle").toString();
  }

  private focusOnStart(focusOnStartRefName: string = 'dialogPhone'): void {
    if (!focusOnStartRefName) return;

    const element = (this.$refs[focusOnStartRefName] as Vue);
    if (!element) return;

    const input = element.$el.querySelector<HTMLInputElement>('input');
    if (input) {
      input.focus();
      return;
    }

    const txtArea = element.$el.querySelector<HTMLInputElement>('textarea');
    if (txtArea) {
      txtArea.focus();
    }
  }

  created() {
    window.addEventListener("keyup", this.keyListener);
  }

  destroyed() {
    window.removeEventListener("keyup", this.keyListener);
  }

  private keyListener(event: KeyboardEvent) {
    if (!this.dialog) {
      return;
    }
    if (event.key === "Enter") {
      this.sendSms();
    } else if (event.key === "Escape") {
      this.cancel();
    }
  }

  public open(phone: string = '', message: string = '', userId: number = 0, customerId: number = 0): Promise<boolean> {
    this.dialog = true;
    this.dialogPhone = phone;
    this.dialogMessage = message;
    this.userId = userId;
    this.customerId = customerId;
    this.$nextTick(() => {
      this.focusOnStart(phone.length ? 'dialogMessage' : 'dialogPhone');
    });
    return new Promise((resolve, reject) => {
      this.dialogResolve = resolve;
      this.dialogReject = reject;
    });
  }

  private handleClose(): void {
    this.dialogResolve(false);
    this.dialog = false;
  }

  private initLoading(): void {
    this.tableIsLoading = true;
    setInterval(() => {
      this.tableLoading = this.tableIsLoading;
    }, 250);
  }

  private finalizeLoading(): void {
    this.tableIsLoading = false;
    this.tableLoading = false;
  }

  private sendSms(): void {
    this.initLoading();
    const msg = this.dialogMessage.trim().slice(0, 255);
    serviceDataApi.postData('sms-sent', {
      phone: this.dialogPhone,
      sms: msg,
      userId: this.userId ? this.userId : undefined,
      customerId: this.customerId ? this.customerId : undefined,
    }).then((response: AxiosResponse) => {
      if (response && (response.status == 200) && response.data) {
        this.$notify({
          title: '',
          type: 'success',
          duration: 5000,
          message: this.$t('SmsGateway.SmsSent').toString(),
          position: 'bottom-right'
        });
        this.dialogPhone = '';
        this.dialogMessage = '';
        this.dialogResolve(true);
        this.dialog = false;
      }
    }).catch((err) => {
      this.finalizeLoading();
      errorService.errorHandle(err);
    }).finally(() => {
      this.finalizeLoading();
    });
  }

  private cancel(): void {
    this.dialogResolve(false);
    this.dialog = false;
  }
}

