import UserPermission from "@common/access";

export default [
    {
        path: "/crm",
        name: "Crm",
        component: () =>
            import(/* webpackChunkName: "crm" */ "../views/administration/Crm/Crm.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.CUSTOMER_CRM_READ,
        }
    },
    {
        path: "/crm/edit/:id",
        name: "CrmEdit",
        component: () =>
            import(/* webpackChunkName: "crm" */ "../views/administration/Crm/CrmEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.CUSTOMER_CRM_WRITE,
        }
    },
    {
        path: "/crm/add",
        name: "CrmInsert",
        component: () =>
            import(/* webpackChunkName: "crm" */ "../views/administration/Crm/CrmEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.CUSTOMER_CRM_WRITE,
        },
    },

    {
        path: "/crm-events",
        name: "CrmEvents",
        component: () =>
            import(/* webpackChunkName: "crm" */ "../views/administration/Crm/CrmEvents.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/crm-events/edit/:id",
        name: "CrmEventsEdit",
        component: () =>
            import(/* webpackChunkName: "crm" */ "../views/administration/Crm/CrmEventsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/crm-events/add",
        name: "CrmEventsInsert",
        component: () =>
            import(/* webpackChunkName: "crm" */ "../views/administration/Crm/CrmEventsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        },
    },

    {
        path: "/crm-categories",
        name: "CrmCategories",
        component: () =>
            import(/* webpackChunkName: "crm" */ "../views/administration/Crm/CrmCategories.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/crm-categories/edit/:id",
        name: "CrmCategoriesEdit",
        component: () =>
            import(/* webpackChunkName: "crm" */ "../views/administration/Crm/CrmCategoriesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/crm-categories/add",
        name: "CrmCategoriesInsert",
        component: () =>
            import(/* webpackChunkName: "crm" */ "../views/administration/Crm/CrmCategoriesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        },
    },
];