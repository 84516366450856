
import {Component, Prop, Vue} from "vue-property-decorator";
import PageTitle from "@/components/PageTitle.vue";

@Component({
  components: {
    PageTitle
  },
})
export default class PageEditTitle extends Vue {
  @Prop({})
  private title: string;

  @Prop({})
  private help: string;

  @Prop({})
  private link: string;

  @Prop({})
  private icon: string;

  @Prop({ default: false })
  private isModal: boolean;

  @Prop({ default: false })
  private saveEnabled: boolean;

  @Prop({ default: false })
  private saveState: boolean;

  private get saveAndExitEnabled(): boolean {
    return this.saveEnabled; // && !this.isModal;
  }

  public get closeBtnVisible(): boolean {
    return true; // (this.modalViewMode !== '');
  }

  private submitDataForm(mode: number = 0): void {
    this.$emit('submit', mode);
  }

  private closeModalForm(): void {
    this.$emit('close');
  }
}
