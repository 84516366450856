import UserPermission, {AppModules} from "@common/access";

export default [
    {
        path: "/car-documents",
        name: "CarDocuments",
        component: () =>
            import(/* webpackChunkName: "car-documents" */ "../views/cars/CarDocuments.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.CARS_READ,
        }
    },
    {
        path: "/car-documents/edit/:id",
        name: "CarDocumentsEdit",
        component: () =>
            import(/* webpackChunkName: "car-documents" */ "../views/cars/CarDocumentsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.CARS_WRITE,
        }
    },
    {
        path: "/car-documents/add",
        name: "CarDocumentsInsert",
        component: () =>
            import(/* webpackChunkName: "car-documents" */ "../views/cars/CarDocumentsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.CARS_WRITE,
        }
    },
    {
        path: "/car-transits",
        name: "CarTransits",
        component: () =>
            import(/* webpackChunkName: "car-transits" */ "../views/cars/CarTransits.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.CARS_READ,
        }
    },
    {
        path: "/car-transits/edit/:id",
        name: "CarTransitsEdit",
        component: () =>
            import(/* webpackChunkName: "car-transits" */ "../views/cars/CarTransitsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.CARS_WRITE,
        }
    },
    {
        path: "/car-transits/add",
        name: "CarTransitsInsert",
        component: () =>
            import(/* webpackChunkName: "car-transits" */ "../views/cars/CarTransitsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.CARS_WRITE,
        }
    },
    {
        path: "/car-dictionary/:dictionary?",
        name: "CarDictionary",
        component: () =>
            import(/* webpackChunkName: "car-dictionary" */ "../views/cars/CarDictionary.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/car-dictionary/edit/:id",
        name: "CarDictionaryEdit",
        component: () =>
            import(/* webpackChunkName: "car-dictionary" */ "../views/cars/CarDictionaryEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/car-dictionary/add",
        name: "CarDictionaryInsert",
        component: () =>
            import(/* webpackChunkName: "car-dictionary" */ "../views/cars/CarDictionaryEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/cars",
        name: "Cars",
        component: () =>
            import(/* webpackChunkName: "cars" */ "../views/cars/Cars.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.CARS_READ,
        }
    },
    {
        path: "/cars/edit/:id",
        name: "CarsEdit",
        component: () =>
            import(/* webpackChunkName: "cars" */ "../views/cars/CarsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.CARS_WRITE,
        }
    },
    {
        path: "/cars/add",
        name: "CarsInsert",
        component: () =>
            import(/* webpackChunkName: "cars" */ "../views/cars/CarsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresModule: [AppModules.CARS],
            requiresAccess: UserPermission.CARS_WRITE,
        }
    },
];