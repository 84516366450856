
import {Component, Vue} from "vue-property-decorator";
import Hamburger from "@/components/custom/Hamburger.vue";
import LangSelect from "@/components/custom/LangSelect.vue";
import {getImgUrl} from "@/common/functions";
import {Configuration} from "@/common/config";
import GlobalSearch from "@/components/custom/GlobalSearch.vue";
import Shortcuts from "@/components/custom/Shortcuts.vue";
import {serviceDataStore, serviceSocket, storageService} from "@/services/service-container";
import UserPermission from "@common/access";
import LogoutTimer from "@/components/custom/LogoutTimer.vue";

@Component({
  components: {
    Hamburger,
    GlobalSearch,
    LangSelect,
    Shortcuts,
    LogoutTimer
  },
})
export default class AdminAppBar extends Vue {

  private logoutTimer: number = 0;
  private hasLogoutTimer: boolean = false;
  private showShortcuts: boolean = false;
  private hasAdmin: boolean = false;

  mounted() {
    const config = JSON.parse(storageService.getItem('local_config', '{"LOGOUT_TIME":0}'));
    this.hasAdmin = serviceDataStore.hasRight(UserPermission.ADMIN_USER);
    this.logoutTimer = config.LOGOUT_TIME;
    this.hasLogoutTimer = this.logoutTimer > 0;
  }

  public get isCustomer(): boolean {
    return serviceDataStore.isCustomer;
  }

  private menuToggle(): void {
    this.$emit('onMenuToggle');
  }

  private get userName(): string {
    return serviceDataStore.getUserName();
  }

  private get avatarImg(): string {
    const user: ILoginUserData | null = serviceDataStore.getUserData();
    if (user && (user.avatar || '' !== '')) {
      return Configuration.serverAddress + user.avatar;
    }
    return getImgUrl('users/avatar-m.jpg');
  }

  private logout() {
    serviceDataStore.logOut();
    serviceSocket.disconnect();
    this.$router.push({ name: 'Login' });
  }
}
