
    import { Component, Vue } from 'vue-property-decorator'
    import {serviceDataStore} from "@/services/service-container";

    @Component
    export default class LangSelect extends Vue {

        private currentLang: string = '';

        mounted(): void {
           this.currentLang = serviceDataStore.getLang();
        }

        private get currentLangFlag(): string {
            if (this.currentLang === 'en') {
                return require('@/assets/lang/flag-uk.png');
            } else if (this.currentLang === 'pl') {
                return require('@/assets/lang/flag-pl.png');
            } else {
                return require('@/assets/lang/flag-pl.png');
            }
        }

        private handleSetLanguage(lang: string) {
            if ((lang !== 'en') && (lang !== 'pl')) {
                lang = 'pl';
            }
            this.currentLang = lang;
            serviceDataStore.setLang(lang);
            this.$i18n.locale = lang;
            document.documentElement.lang = lang;
            this.$notify({
                title: '',
                duration: 5000,
                message: this.$t('Language.LanguageChanged').toString(),
                position: 'bottom-right',
                type: 'success'
            })
        }
    }
