
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component({
  components: {
  },
})
export default class DataTablePagination extends Vue {
  @Prop({ default: 0 })
  public tableCount: number;

  @Prop({ default: 0 })
  public itemsPerPage: number;

  @Prop({ default: -1 })
  private currentPage: number;

  @Prop({ default: true })
  private showPerPage: boolean;

  @Watch('itemsPerPage')
  onItemsPerPagePageChange(): void {
    this.perPage = this.itemsPerPage;
  }

  @Watch('currentPage')
  onCurrentPageChange(): void {
    this.page = this.currentPage;
  }

  private page: number = -1;

  private perPage: number = 0;

  private pageChange(pageN: number): void {
    this.page = pageN;
    this.$emit('page-change', this.page);
  }

  mounted() {
    this.page = this.currentPage;
    this.perPage = this.itemsPerPage;
  }

}

