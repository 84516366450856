
    import {Component, Vue} from "vue-property-decorator";
    import draggable from 'vuedraggable'

    export interface IDataTableSettingsDialogResult {
      columns: IDataTableColumn[];
      options: IDataTableOptions;
    }

    @Component({
        components: {
            draggable
        },
    })
    export default class DataTableSettingsDialog extends Vue {

        private dialog: boolean = false;
        private dialogResolve: any;
        private dialogReject: any;

        public fMultiSelect: boolean = false;
        public fOptions: IDataTableOptions = {} as IDataTableOptions;
        public columns: IDataTableColumn[] = [];

        public openDialog(columns: IDataTableColumn[], options: IDataTableOptions): Promise<IDataTableSettingsDialogResult> {
            this.dialog = true;
            this.fOptions = options;
            this.fMultiSelect = options.multiSelectable ?? false;
            columns.forEach((col) => {
                col.customLabel = col.customLabel ?? col.label;
                col.visible = col.visible ?? true;
            });
            this.columns = columns;
            return new Promise((resolve, reject) => {
                this.dialogResolve = resolve;
                this.dialogReject = reject;
            });
        }

        private handleReset(): void {
            this.dialogResolve(null);
            this.dialog = false;
        }

        private handleClose(): void {
            this.dialogReject();
            this.dialog = false;
        }

        private handleAccept(): void {
            this.columns.forEach((col, index) => {
                col.index = index;
            });
            this.fOptions.multiSelectable = this.fMultiSelect;
            this.dialogResolve({
              columns: this.columns,
              options: this.fOptions
            });
            this.dialog = false;
        }
    }

