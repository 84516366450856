
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })
    export default class ExportFormatDialog extends Vue {

        private dialog: boolean = false;
        private dialogConfirmButton = "";
        private dialogResolve: any;
        private dialogReject: any;

        mounted(): void {
            this.dialogConfirmButton = this.$t("common.Confirm").toString();
        }

        public openDialog(): Promise<string> {
            this.dialog = true;
            return new Promise((resolve, reject) => {
                this.dialogResolve = resolve;
                this.dialogReject = reject;
            });
        }

        private handleClose(): void {
            this.dialogResolve('');
            this.dialog = false;
        }

        private formatReturn(format: string): void {
            this.dialogResolve(format);
            this.dialog = false;
        }
    }

