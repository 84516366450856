

// GLOBALLY REGISTERED COMPONENTS
import Vue from "vue";
import InputNumber from "@/components/InputNumber.vue";
import Hint from "@/components/custom/Hint.vue";

Vue.component('el-input-number', InputNumber);
Vue.component('hint-block', Hint);

Vue.component('CompaniesEdit', () => import("./views/administration/Companies/CompaniesEdit.vue"));

Vue.component('BoxStockAlert', () => import("@/components/main/BoxStockAlert.vue"));
Vue.component('BoxNewUsers', () => import("@/components/main/BoxNewUsers.vue"));
Vue.component('BoxNewOrders', () => import("@/components/main/BoxNewOrders.vue"));
Vue.component('BoxDate', () => import("@/components/main/BoxDate.vue"));
Vue.component('BoxCounters', () => import("@/components/main/BoxCounters.vue"));
Vue.component('BoxCountersNewOrders', () => import("@/components/main/BoxCountersNewOrders.vue"));
Vue.component('BoxCountersNewOrderItems', () => import("@/components/main/BoxCountersNewOrderItems.vue"));
Vue.component('BoxCountersInvoiceCount', () => import("@/components/main/BoxCountersInvoiceCount.vue"));
Vue.component('BoxCountersInvoiceSum', () => import("@/components/main/BoxCountersInvoiceSum.vue"));
Vue.component('BoxOrdersGraph', () => import("@/components/main/BoxOrdersPriorityGraph.vue"));
Vue.component('BoxOrdersIncGraph', () => import("@/components/main/BoxOrdersStatusGraph.vue"));
Vue.component('BoxCurrencyRate', () => import("@/components/main/BoxCurrencyRate.vue"));
Vue.component('BoxTodos', () => import("@/components/main/BoxTodos.vue"));
Vue.component('BoxAlerts', () => import("@/components/main/BoxAlerts.vue"));
Vue.component('BoxNotifications', () => import("@/components/main/BoxNotifications.vue"));
Vue.component('BoxWorkCards', () => import("@/components/main/BoxWorkCards.vue"));
