import UserPermission from "@common/access";

export default [
    {
        path: "/object-management",
        name: "ObjectManagement",
        component: () =>
            import(/* webpackChunkName: "object-management" */ "../views/structure/ObjectManagement.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.KEYS_CHANGE,
        }
    }
];