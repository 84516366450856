
  import {isValidUsername} from "@/common/functions";
  import { AxiosResponse } from 'axios';
  import {Component, Vue} from "vue-property-decorator";
  import {serviceDataApi, serviceDataStore} from "@/services/service-container";
  import store from "@/stores/counters";
  import PasswordChangeDialog from "@/components/dialogs/PasswordChangeDialog.vue";

  @Component({
    components: {
      PasswordChangeDialog
    },
  })
  export default class Login extends Vue {
    private loading = false;
    private passwordType = "password";
    private loginForm = {
      username: '',
      password: ''
    };

    private loginRules = {
      username: [{ required: true, trigger: 'blur', min: 2, message: this.$t('Login.IncorrectUsername').toString() }],
      password: [{ required: true, trigger: 'blur', validator: this.validatePassword }]
    };

    $refs!: {
      usernameInput: HTMLInputElement;
      passwordInput: HTMLInputElement;
      loginForm: HTMLFormElement;
      passwordDlg: PasswordChangeDialog;
    };

    private get logoSrc(): string {
      return `/logo.png`;
    }

    private get appName(): string {
      const app = this.$t('app.name').toString();
      return serviceDataApi.subDomain.length ? `${app}: ${serviceDataApi.subDomain}` : `${app}`;
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    private validateUsername(rule: any, value: string, callback: Function): void {
      if (!isValidUsername(value)) {
        callback(new Error('Nie prawidłowy login'))
      } else {
        callback()
      }
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    private validatePassword(rule: any, value: string, callback: Function): void {
      if (value.length < 6) {
        callback(new Error('Nie prawidłowe hasło'))
      } else {
        callback()
      }
    }

    private resizeHandler() {
      if (window.innerWidth <= 992) {
        return;
      }
      const elem: HTMLElement | null = document.querySelector('#formWrapper');
      if (elem) {
        const margin: number = ((window.innerHeight - elem.clientHeight) / 2);
        elem.style.marginTop = `${margin}px`;
      }
    }

    mounted(): void {
      this.resizeHandler();
      window.addEventListener("resize",  this.resizeHandler);

      if (this.loginForm.username === "") {
        this.$refs.usernameInput.focus();
      } else if (this.loginForm.password === "") {
        this.$refs.passwordInput.focus();
      }
    }

    private handleLogin(): void {
      this.$refs.loginForm.validate((valid: boolean) => {
        if (valid) {
          this.loading = true;

          serviceDataApi.tryLogin(this.loginForm.username, this.loginForm.password).then((response: AxiosResponse) => {
            if (response && response.data.status && response.data.user && (response.data.user.isActive === 1)) {
              serviceDataStore.setSessionId(response.data.token);
              serviceDataStore.setUserData(response.data.user);
              serviceDataStore.setCompanyData(response.data.company ?? {});
              serviceDataStore.setUserPermissions(response.data.permissions ?? []);
              serviceDataStore.setModules(response.data.modules ?? []);
              if (response.data.config) {
                store.dispatch('setConfig', response.data.counters);
              }
              if (response.data.counters) {
                store.commit('messageCount', {
                  count: response.data.counters.messages
                })
                store.commit('leaveCardsCount', {
                  count: response.data.counters.leaveCards
                })
              }

              if (response.data.user.loginReset) {
                this.$refs.passwordDlg.openDialog(response.data.user.id).then(() => {
                  window.location.href = '/admin';
                }).catch(() => {
                  window.location.href = '/admin';
                });
              } else {
                window.location.href = '/admin';
              }

              // this.$router.push({ path: 'admin' });

              this.loading = false;
            } else {
              this.$notify({
                title: 'Logowanie',
                type: 'error',
                duration: 5000,
                message: 'Nie prawidłowy login lub hasło',
                position: 'bottom-right',
              });
              this.loading = false;
            }
          }).catch(() => {
            this.$notify({
              title: 'Logowanie',
              type: 'error',
              duration: 5000,
              message: 'Nie prawidłowy login lub hasło',
              position: 'bottom-right',
            });
            this.loading = false;
          });

        } else {
          return false;
        }
      });
    }
  }
