
    import {Component} from "vue-property-decorator";
    import DataTable from "@/components/custom/DataTable.vue";
    import UserPermission from "@common/access";
    import {DataTableFormat} from "@/common/enums";
    import GridEditPartial from "@/components/custom/GridEditPartial.vue";
    import CustomerNoteEdit from "@/components/grids/customer/CustomerNoteEdit.vue";

    @Component({
        components: {
            DataTable,
            CustomerNoteEdit
        },
    })
    export default class CustomerNotes extends GridEditPartial {

        protected getFilters(filters: ITableDataFilter): IUnknownObject {
            delete filters.search;
            return {
                customerId: this.parentId,
                ...filters,
            };
        }

        private get getDataTableOptions(): IDataTableOptions {
            return {
                canInsert: true,
                canDelete: true,
                canEdit: true,
                canExport: false,
                multiSelectable: false,
                filterKey: 'CustomerNotesGrid',

                viewAccess: UserPermission.CUSTOMER_NOTES_READ,
                editAccess: UserPermission.CUSTOMER_NOTES_WRITE,
                deleteAccess: UserPermission.CUSTOMER_NOTES_DELETE,
                exportAccess: UserPermission.CUSTOMER_NOTES_READ,

                tableEndpoint: 'customer-notes',

                defaultfilterEnabled: false,

                columns: [
                    {
                        name: "id",
                        label: this.$t('CustomerNotes.FieldId').toString(),
                        exported: true,
                        visible: false,
                        width: 100,
                    },
                    {
                        name: "dateTo",
                        label: this.$t('CustomerNotes.FieldDateTo').toString(),
                        width: 160,
                        format: DataTableFormat.DATETIME,
                        visible: true
                    },
                    {
                        name: "note",
                        label: this.$t('CustomerNotes.FieldNote').toString(),
                        visible: true
                    },
                ]
            };
        }
    }
