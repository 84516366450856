import { saveAs } from 'file-saver'
import zipcelx from "zipcelx";
import moment from "moment/moment";

export const exportAsExcel = (exportColumns: IDataTableColumn[], data: any[], filename: string) => {
    const headData = exportColumns.map((col) => ({
        value: col.label,
        type: "string",
    }));

    const bodyData = data.map((item) =>
        exportColumns.map((col) => {
            let value;
            if (col.name.indexOf('.') > 0) {
                const cols: string[] = col.name.split('.');
                let obj = item;
                cols.forEach(col => {
                    obj = obj ? obj[col] : null;
                });
                value = obj ?? '';

            } else {
                value = item[col.name];
            }

            if (value === null) {
                value = '';
            }
            if (col.processor) {
                value = col.processor(value, item);
            } else if (typeof col.format === 'function') {
                value = col.format(value, item);
            } else if (Number.isInteger(col.format)) {
                if (col.format === 2) { // data
                    value = dateValue(value);
                } else if (col.format === 3) { // currency
                    value = currencyValue(value);
                }
            }
            return ({ value, type: typeof value })
        })
    );

    const config = {
        filename: filename,
        sheet: { data: [headData, ...bodyData] },
    };
    zipcelx(config);
};

function currencyValue(field: string): string {
    return field ? (Number.parseInt(field) / 100).toFixed(2) : '';
}

function dateValue(field: string, format: string = "YYYY-MM-DD"): string {
    return field ? moment(field).format(format) : '';
}

export const exportAsCSV = (exportColumns: IDataTableColumn[], data: any[], filename: string) => {
    const lines: string[] = [];

    let headLine: string = 'Lp';
    exportColumns?.forEach((col) => {
        if (headLine.length) headLine += ',';
        headLine += `"${col.label}"`;
    });
    lines.push(headLine);

    data.forEach((row, index) => {
        let dataLine: string = `"${index+1}"`;
        exportColumns?.forEach((col) => {
            if (dataLine.length) dataLine += ',';
            let val;
            if (col.name.indexOf('.') > 0) {
                const cols: string[] = col.name.split('.');
                let obj = row;
                cols.forEach(col => {
                    obj = obj ? obj[col] : null;
                });
                val = obj ?? '';

            } else {
                val = row[col.name];
            }
            if (val === null) {
                val = '';
            }
            if (col.processor) {
                val = col.processor(val, row);
            } else if (typeof col.format === 'function') {
                val = col.format(val, row);
            } else if (Number.isInteger(col.format)) {
                if (col.format === 2) { // data
                    val = dateValue(val);
                } else if (col.format === 3) { // currency
                    val = currencyValue(val);
                }
            }
            dataLine += `"${val}"`;
        });
        lines.push(dataLine);
    });

    saveAs(new Blob([lines.join('\n')], { type: 'application/octet-stream' }), filename)
};
