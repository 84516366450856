
    import {Component} from "vue-property-decorator";
    import DataTable from "@/components/custom/DataTable.vue";
    import UserPermission from "@common/access";
    import GridEditPartial from "@/components/custom/GridEditPartial.vue";
    import CustomerCategoriesListEdit from "@/components/grids/customer/CustomerCategoriesListEdit.vue";

    @Component({
        components: {
            DataTable,
            CustomerCategoriesListEdit
        },
    })
    export default class CustomerCategoriesGrid extends GridEditPartial {

        protected getFilters(filters: ITableDataFilter): IUnknownObject {
            delete filters.search;
            return {
                customerId: this.parentId,
                ...filters,
            };
        }

        private get getDataTableOptions(): IDataTableOptions {
            return {
                canInsert: true,
                canDelete: true,
                canEdit: true,
                canExport: false,
                multiSelectable: false,
                filterKey: 'CustomerCategoriesGrid',

                viewAccess: UserPermission.CUSTOMERS_READ,
                editAccess: UserPermission.CUSTOMERS_WRITE,
                deleteAccess: UserPermission.CUSTOMERS_DELETE,
                exportAccess: UserPermission.CUSTOMERS_READ,

                tableEndpoint: 'customer-categories-list',

                defaultfilterEnabled: false,

                columns: [
                    {
                        name: "id",
                        label: this.$t('CustomerCategories.FieldId').toString(),
                        exported: true,
                        visible: false,
                        width: 100,
                    },
                    {
                        name: "category",
                        label: this.$t('CustomerCategories.FieldName').toString(),
                        visible: true,
                        format: this.categoryName,
                        orderBy: 'category.name',
                        processor: (data) => data?.name ?? ''
                    },
                ]
            };
        }

        private categoryName(value: any): string {
            return value?.name ?? '';
        }
    }
