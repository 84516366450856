
    import {Component, Vue} from "vue-property-decorator";
    import moment from "moment";

    @Component({
    components: {
    },
})
export default class BaseView extends Vue {
    scope: any;

    private usePrintuRI: boolean = false;

    public formatFieldDate(field: string, format: string = "YYYY-MM-DD HH:mm"): string {
        const currentDate: string = moment(field).format(format);
        return currentDate;
    }

    public getPrintUri(): string {
        return window.location.protocol + '//' + window.location.host + this.$router.currentRoute.fullPath + '?print=1';
    }

    public printPage(): void {
        const printUri: string = this.getPrintUri();
        if (this.usePrintuRI && printUri.length) {
            const printWindow = window.open(printUri, '', 'height=400,width=400');
            if (printWindow) {
                printWindow.print();
            }
        } else {
            window.print();
        }
    }

    public currencyValue(value: number): string {
        return value ? (value / 100).toFixed(2) : '';
    }
}

