import UserPermission from "@common/access";

export default [{
    path: "/leave-card-kinds",
    name: "LeaveCardKinds",
    component: () =>
        import(/* webpackChunkName: "leave-card-kinds" */ "../views/administration/LeaveCardKinds/LeaveCardKinds.vue"),
    meta: {
        requiresAuth: true,
        requiresAccess: UserPermission.DICTIONARIES_READ,
    }
},
    {
        path: "/leave-card-kinds/edit/:id",
        name: "LeaveCardKindsEdit",
        component: () =>
            import(/* webpackChunkName: "leave-card-kinds" */ "../views/administration/LeaveCardKinds/LeaveCardKindsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/leave-card-kinds/add",
        name: "LeaveCardKindsInsert",
        component: () =>
            import(/* webpackChunkName: "leave-card-kinds" */ "../views/administration/LeaveCardKinds/LeaveCardKindsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/user-leave-cards",
        name: "UserLeaveCards",
        component: () =>
            import(/* webpackChunkName: "user-leave-cards" */ "../views/Users/UserLeaveCards.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.LEAVE_CARD_READ,
        }
    },
    {
        path: "/user-leave-cards/edit/:id",
        name: "UserLeaveCardsEdit",
        component: () =>
            import(/* webpackChunkName: "user-leave-cards" */ "../views/Users/UserLeaveCardsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.LEAVE_CARD_WRITE,
        }
    },
    {
        path: "/user-leave-cards/view/:id",
        name: "UserLeaveCardsView",
        component: () =>
            import(/* webpackChunkName: "user-leave-cards" */ "../views/Users/UserLeaveCardsEdit.vue"),
        meta: {
            viewMode: true,
            requiresAuth: true,
            requiresAccess: UserPermission.LEAVE_CARD_WRITE,
        }
    },
    {
        path: "/user-leave-cards/add",
        name: "UserLeaveCardsInsert",
        component: () =>
            import(/* webpackChunkName: "user-leave-cards" */ "../views/Users/UserLeaveCardsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.LEAVE_CARD_WRITE,
        }
    },
];