import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import {escapeDiacritics, replaceAll} from "@/common/functions";
import {ServiceBase} from "@/services/service-base";
import {serviceDataApi} from "@/services/service-container";
import moment from "moment/moment";

export default class PrintersService extends ServiceBase {
    private zplScriptStock: string = '';

    private async loadStockScript(): Promise<void> {
        const reqData: IDataRequest = {
            page: 0,
            itemsPerPage: 10,
            filter: JSON.stringify({
                search: 'ZPL_SCRIPT_CARD_STOCK',
                includeData: true
            })
        };
        const response = await serviceDataApi.getList('search/settings-search', reqData);
        if (response && (response.status == 200) && (response.data.items)) {
            const settings: any[] = response.data.items;
            if (settings.length) {
                this.zplScriptStock = settings[0].data.value;
            }
        }
    }

    private async checkScript(): Promise<void> {
        if (!this.zplScriptStock.length) {
            await this.loadStockScript();
        }
    }

    private getStockCardScript(stock: IStock): string {
        let zplScript: string = this.zplScriptStock;

        zplScript = replaceAll(zplScript, '{barcode}', stock.barcode);
        zplScript = replaceAll(zplScript, '{storage}', escapeDiacritics(stock.storage?.name || ''));
        zplScript = replaceAll(zplScript, '{companyCode}', stock.storage?.company?.code || '');
        zplScript = replaceAll(zplScript, '{companyName}', escapeDiacritics(stock.storage?.company?.name || ''));
        zplScript = replaceAll(zplScript, '{companyNip}', stock.storage?.company?.nip || '');

        zplScript = replaceAll(zplScript, '{boxNumber}', stock.boxNumber);
        zplScript = replaceAll(zplScript, '{serialNumber}', stock.serialNumber);
        zplScript = replaceAll(zplScript, '{invoiceNumber}', stock.invoiceNumber);

        zplScript = replaceAll(zplScript, '{productCode}', stock.product?.code || '');
        zplScript = replaceAll(zplScript, '{productName}', escapeDiacritics(stock.product?.name || ''));
        zplScript = replaceAll(zplScript, '{productExternalCode}', stock.product?.externalCode || '');
        zplScript = replaceAll(zplScript, '{productBarcode}', stock.product?.barcode || '');
        zplScript = replaceAll(zplScript, '{productGloss}', stock.product?.productGloss?.name || '');
        zplScript = replaceAll(zplScript, '{productClass}', stock.product?.productClass?.name || '');

        zplScript = replaceAll(zplScript, '{producerName}', escapeDiacritics(stock.product?.producer?.name || ''));
        if (stock.dateTo) {
            const expirationDate: string = moment(stock.dateTo).format("YYYY-MM-DD");
            zplScript = replaceAll(zplScript, '{expirationDate}', expirationDate);
        } else {
            zplScript = replaceAll(zplScript, '{expirationDate}', '');
        }

        return zplScript;
    }

    public async printerStatus(): Promise<IPrinterStatus> {
        const res = {
            printerConfigured: false,
            isReadyToPrint: false,
            errors: ''
        }

        const browserPrint = new ZebraBrowserPrintWrapper();
        try {
            const defaultPrinter = await browserPrint.getDefaultPrinter();
            res.printerConfigured = Boolean(defaultPrinter);
            if (defaultPrinter) {
                browserPrint.setPrinter(defaultPrinter);
                const printerStatus = await browserPrint.checkPrinterStatus();
                res.isReadyToPrint = printerStatus.isReadyToPrint;
                res.errors = printerStatus.errors;
            }
        } catch (error: any) {
            console.log("Error/s", error);
            res.errors = error;
        }

        return res;
    }

    public async printStockCard(stock: IStock): Promise<{status: boolean; error: string}> {
        await this.checkScript();

        const browserPrint = new ZebraBrowserPrintWrapper();
        try {
            const defaultPrinter = await browserPrint.getDefaultPrinter();
            browserPrint.setPrinter(defaultPrinter);
            const printerStatus = await browserPrint.checkPrinterStatus();

            if (printerStatus.isReadyToPrint) {
                const zplScript: string = this.getStockCardScript(stock);
                if (zplScript.length <= 0) {
                    console.warn("Error: No ZPL script defined");
                    return {
                        status: false,
                        error: "Error: No ZPL script defined"
                    };
                }
                await browserPrint.print(zplScript);
                return {
                    status: true,
                    error: '',
                };
            } else {
                console.warn("Error/s", printerStatus.errors);
                return {
                    status: false,
                    error: printerStatus.errors
                };
            }
        } catch (error: any) {
            console.log("Error/s", error);
            return {
                status: false,
                error: error.toString()
            };
        }
    }
}