import { render, staticRenderFns } from "./MessageWindow.vue?vue&type=template&id=39c76bee&scoped=true&"
import script from "./MessageWindow.vue?vue&type=script&lang=ts&"
export * from "./MessageWindow.vue?vue&type=script&lang=ts&"
import style0 from "./MessageWindow.vue?vue&type=style&index=0&id=39c76bee&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39c76bee",
  null
  
)

export default component.exports