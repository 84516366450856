import { Locales } from "./locales";

import en from "./partials/en/common.json";
import smsEn from "./partials/en/sms.json";
import objectManagmentEn from "./partials/en/objectManagment.json";

import pl from "./partials/pl/common.json";
import smsPl from "./partials/pl/sms.json";
import objectManagmentPl from "./partials/pl/objectManagment.json";

export const messages = {
    [Locales.EN]: {
        ...en,
        ...smsEn,
        ...objectManagmentEn
    },
    [Locales.PL]: {
        ...pl,
        ...smsPl,
        ...objectManagmentPl
    }
};

export const defaultLocale = Locales.PL;
export let currentLocale = Locales.PL;

let language = (localStorage.getItem('user_lang') || 'pl').toString();
if ((language !== 'en') && (language !== 'pl')) {
    language = 'en';
}

if (language === 'pl') {
    currentLocale = Locales.PL;
}  else {
    currentLocale = Locales.EN;
}

