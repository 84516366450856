import {AxiosResponse} from "axios";
import {ServiceBase} from "@/services/service-base";
import {serviceDataApi, serviceDataStore} from "@/services/service-container";
import UserPermission from "@common/access";

export interface ISearchType {
    type: string;
    icon?: string;
    route: string;
    access: UserPermission;
}
export interface ISearchTypeResults {
    type: string;
    icon?: string;
    route: string;
    data: ISearchItem[];
}
export type ISearchCallback = (data: ISearchTypeResults) => void;

export default class SearchService extends ServiceBase {
    private searchTypes: ISearchType[] = [
        {
            type: 'customers-search',
            route: 'customers',
            icon: 'fa fa-fw fa-address-card-o',
            access: UserPermission.CUSTOMERS_READ,
        },
        {
            type: 'products-search',
            route: 'products',
            icon: 'fa fa-fw fa-shopping-cart',
            access: UserPermission.PRODUCTS_READ,
        },
        {
            type: 'users-search',
            route: 'users',
            icon: 'fa fa-fw fa-users',
            access: UserPermission.USERS_READ,
        },
        {
            type: 'work-cards-search',
            route: 'work-cards',
            icon: 'fa fa-fw fa-briefcase',
            access: UserPermission.WORK_CARDS_READ,
        },
        {
            type: 'orders-search',
            route: 'order-items',
            icon: 'fa fa-fw fa-shopping-basket',
            access: UserPermission.ORDERS_READ,
        },
        {
            type: 'invoices-search',
            route: 'invoices',
            icon: 'fa fa-fw fa-file-text-o',
            access: UserPermission.INVOICES_READ,
        },
        {
            type: 'quotations-search',
            route: 'quotations',
            icon: 'fa fa-fw fa-money',
            access: UserPermission.QUOTATIONS_READ,
        },
        {
            type: 'complaints-search',
            route: 'complaints',
            icon: 'fa fa-fw fa-exclamation-triangle',
            access: UserPermission.COMPLAINTS_READ,
        },
        {
            type: 'receptions-search',
            route: 'receptions',
            icon: 'fa fa-fw fa-truck',
            access: UserPermission.RECEPTION_READ,
        },
        {
            type: 'buildings-search',
            route: 'buildings',
            icon: 'fa fa-fw fa-building',
            access: UserPermission.BUILDINGS_READ,
        },
        {
            type: 'keys-search',
            route: 'keys',
            icon: 'fa fa-fw fa-key',
            access: UserPermission.KEYS_READ,
        },
        {
            type: 'card-search',
            route: 'cards',
            icon: 'fa fa-fw fa-credit-card-alt',
            access: UserPermission.CARD_READ,
        },
        {
            type: 'user-work-search',
            route: 'user-work',
            icon: 'fa fa-fw fa-id-badge',
            access: UserPermission.USER_WORK_READ,
        },
        {
            type: 'user-leave-cards-search',
            route: 'user-leave-cards',
            icon: 'fa fa-fw fa-id-badge',
            access: UserPermission.LEAVE_CARD_READ,
        },
    ];

    public async initSearch(searchStr: string, callback: ISearchCallback, itemsPerPage: number = 10): Promise<void> {
        this.searchTypes.forEach((key) => {
            if (serviceDataStore.hasRight(key.access)) {
                this.getDataFromApi(searchStr, key, callback, itemsPerPage);
            }
        });
    }

    public getDataFromApi(searchStr: string, key: ISearchType, callback: ISearchCallback, itemsPerPage: number = 10): void {
        const queryStr: string = searchStr.toLowerCase();

        const reqData: IDataRequest = {
            page: 1,
            itemsPerPage,
            filter: JSON.stringify({
                search: queryStr,
                includeData: true,
                labelMode: 2,
            })
        };

        serviceDataApi.getList(`search/${key.type}`, reqData).then((response: AxiosResponse) => {
            if (response && (response.status == 200) && response.data.items && response.data.items.length) {
                callback({
                    type: key.type,
                    icon: key.icon,
                    route: key.route,
                    data: response.data.items
                });
            }
        });
    }
}
