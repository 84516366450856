
    import {Component, Prop, Vue} from "vue-property-decorator";

    @Component({
        components: {
        },
    })
    export default class PageTitle extends Vue {
        @Prop({})
        private title: string;

        @Prop({})
        private help: string;

        @Prop({})
        private link: string;

        @Prop({})
        private icon: string;

        @Prop({ default: false })
        private isModal: boolean;
    }
