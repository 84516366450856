import {ServiceBase} from "@/services/service-base";
import {AxiosError, AxiosResponse} from "axios";
import Vue from "vue";
import {NotificationPosition} from "element-ui/types/notification";

export default class ErrorService extends ServiceBase {
    public appHook: Vue;
    private isAdmin: boolean = false;

    private notePosition: NotificationPosition = 'bottom-right';

    constructor() {
        super();
        this.isAdmin = ((localStorage.getItem('logEnabled') ?? '0') === '1');
    }

    public adminLog(log: string, ...data: any): void {
        if (this.isAdmin) {
            console.log(log, data);
        }
    }

    public responseHandle(source: AxiosResponse): void {
        if (source.data) {
            this.handleErrorMessage(source.data);
        }
    }

    public errorHandle(source: AxiosError): void {
        if (source.response?.data) {
            this.handleErrorMessage(source.response?.data);
        }
    }

    private handleErrorMessage(data: any): void {
        let messageStr: string = '';
        let messageDetails: string = '';

        if (data && data.errorDetails) {
            messageDetails = `: ${data.errorDetails}`;
        }

        if (data && data.errorStr) {
            messageStr = this.appHook.$t(data.errorStr).toString()
        }

        this.appHook.$notify({
            title: this.appHook.$t('common.DataSaveError').toString(),
            duration: 5000,
            message: `${messageStr}${messageDetails}`,
            position: this.notePosition,
            type: 'error'
        });
    }

}