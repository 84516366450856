import UserPermission from "@common/access";

export default [

    {
        path: "/currency",
        name: "Currency",
        component: () =>
            import(/* webpackChunkName: "currency" */ "../views/administration/Currency/Currency.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/currency/edit/:id",
        name: "CurrencyEdit",
        component: () =>
            import(/* webpackChunkName: "currency" */ "../views/administration/Currency/CurrencyEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/currency/add",
        name: "CurrencyInsert",
        component: () =>
            import(/* webpackChunkName: "currency" */ "../views/administration/Currency/CurrencyEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },

    {
        path: "/currency-rate",
        name: "CurrencyRate",
        component: () =>
            import(/* webpackChunkName: "currency" */ "../views/administration/Currency/CurrencyRate.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/currency-rate/edit/:id",
        name: "CurrencyRateEdit",
        component: () =>
            import(/* webpackChunkName: "currency" */ "../views/administration/Currency/CurrencyRateEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/currency-rate/add",
        name: "CurrencyRateInsert",
        component: () =>
            import(/* webpackChunkName: "currency" */ "../views/administration/Currency/CurrencyRateEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
];