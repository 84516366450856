
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {
  },
})
export default class OrderIndicator extends Vue {

  @Prop({ default: 0 })
  public order: number;
}

