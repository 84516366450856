import {Route, RouteConfig} from "vue-router";
import {RouteConfigSingleView} from "vue-router/types/router";
import UserPermission, {AppModules} from "@common/access";
import {serviceDataStore} from "@/services/service-container";

type RouteAuthorized = RouteConfigSingleView & { requiresAccess?: UserPermission, requiresModule?: AppModules[] }

export default function guardRoutes(to: Route, from: Route, next: any) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const hasSession = (localStorage.getItem('user_session') == null)
            && (localStorage.getItem('user_session_time') == null);
        if (hasSession) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        } else {
            // check session time
            const sesTimestamp = localStorage.getItem('user_session_time') || '';
            const dt1 = new Date().getTime();
            const dt2 = Number.parseInt(sesTimestamp, 10);
            const diffS = (dt1 - dt2) / 1000;
            const diffM = Math.abs(Math.round(diffS / 60))
            // token na backend - TOKEN_VALIDITY_HOURS - 1H
            if (diffM >= 60) {
                next({
                    path: '/login',
                    params: { nextUrl: to.fullPath }
                })
            }
            next()
        }
    } else {
        next()
    }
}

export async function checkAuthorization(to: Route, from: Route, next: any, access?: UserPermission|UserPermission[], modules?: AppModules[]): Promise<void> {
    if (!access) {
        next();
        return;
    }

    if (modules && modules.length) {
        if (!serviceDataStore.isAnyModuleEnabled(modules)) {
            console.log('User has no access to this module!', access);
            next({ name: '403' });
            return;
        }
    }

    if ((Array.isArray(access) && serviceDataStore.hasAnyRights(access)) || !serviceDataStore.hasRight(access as UserPermission)) {
        console.log('User has no access!', access);
        next({ name: '403' });
        return;
    }

    next();
}

export function getGuardedRoutes(routes: Array<RouteConfig>): Array<RouteConfig> {
    return routes.map((route: RouteAuthorized) => {
        return {
            path: route.path,
            name: route.name,
            component: route.component,
            meta: route.meta,
            beforeEnter: (to, from, next) => {
                return checkAuthorization(to, from, next, route.requiresAccess, route.requiresModule);
            }
        };
    });
}