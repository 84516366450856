import Forbidden from "@/views/Forbidden.vue";
import Login from "@/views/Login.vue";
import UserPermission from "@common/access";
import GlobalSearchView from "@/views/administration/GlobalSearchView.vue";
import AdminMain from "@/views/AdminMain.vue";

export default [

    {
        path: "/403",
        name: "403",
        component: Forbidden,
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/admin',
        name: 'Admin',
        component: AdminMain,
        meta: {
            requiresAuth: true,
        }
    },

    {
        path: '/messages',
        name: 'MessagesView',
        component: () =>
            import(/* webpackChunkName: "messages" */ "../views/Messages/Messages.vue"),
        meta: {
            viewMode: true,
            requiresAuth: true,
            requiresAccess: UserPermission.MESSAGES_READ_WRITE,
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false,
            simpleLayout: true,
        }
    },
    {
        path: '/remindpassword',
        name: 'PasswordRemind',
        component: () =>
            import(/* webpackChunkName: "register" */ "../views/Users/PasswordRemind.vue"),
        meta: {
            requiresAuth: false,
            simpleLayout: true,
        }
    },
    {
        path: '/resetpassword',
        name: 'PasswordReset',
        component: () =>
            import(/* webpackChunkName: "register" */ "../views/Users/PasswordReset.vue"),
        meta: {
            requiresAuth: false,
            simpleLayout: true,
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () =>
            import(/* webpackChunkName: "register" */ "../views/Users/Register.vue"),
        meta: {
            requiresAuth: false,
            simpleLayout: true,
        }
    },
    {
        path: '/register-confirm',
        name: 'RegisterConfirm',
        component: () =>
            import(/* webpackChunkName: "register" */ "../views/Users/RegisterConfirm.vue"),
        meta: {
            requiresAuth: false,
            simpleLayout: true,
        }
    },
    {
        path: '/forbidden',
        name: 'Forbidden',
        component: Forbidden,
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: "/about",
        name: "About",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    },
    {
        path: "/changelog",
        name: "ChangeLog",
        component: () =>
            import(/* webpackChunkName: "changelog" */ "../views/ChangeLog.vue"),
    },
    {
        path: "/backups",
        name: "Backups",
        component: () =>
            import(/* webpackChunkName: "backups" */ "../views/administration/SuperUser/Backups.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.ADMIN_USER,
        },
    },
    {
        path: "/sql",
        name: "SqlEditor",
        component: () =>
            import(/* webpackChunkName: "admin" */ "../views/administration/SuperUser/SqlEditor.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.SUPER_USER,
        },
    },

    {
        path: "/fixes",
        name: "Fixes",
        component: () =>
            import(/* webpackChunkName: "admin" */ "../views/administration/SuperUser/Fixes.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.SUPER_USER,
        },
    },
    {
        path: "/imports",
        name: "Imports",
        component: () =>
            import(/* webpackChunkName: "imports" */ "../views/administration/Import.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DATA_IMPORT,
        }
    },

    {
        path: '/local-settings',
        name: 'LocalConfig',
        component: () =>
            import(/* webpackChunkName: "settings" */ "../views/administration/Settings/LocalConfig.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.SETTINGS_READ_WRITE,
        }
    },
    {
        path: '/settings-view',
        name: 'SettingsView',
        component: () =>
            import(/* webpackChunkName: "settings" */ "../views/administration/Settings/SettingsView.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.SETTINGS_READ_WRITE,
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () =>
            import(/* webpackChunkName: "settings" */ "../views/administration/Settings/Settings.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.SETTINGS_READ_WRITE,
        }
    },
    {
        path: "/settings/edit/:id",
        name: "SettingsEdit",
        component: () =>
            import(/* webpackChunkName: "settings" */ "../views/administration/Settings/SettingsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.SETTINGS_READ_WRITE,
        }
    },
    {
        path: "/settings/add",
        name: "SettingsInsert",
        component: () =>
            import(/* webpackChunkName: "settings" */ "../views/administration/Settings/SettingsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.SETTINGS_READ_WRITE,
        }
    },
    {
        path: "/global-search",
        name: "GlobalSearch",
        component:  GlobalSearchView,
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: "/modules",
        name: "Modules",
        component: () =>
            import(/* webpackChunkName: "modules" */ "../views/administration/Modules/Modules.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.SUPER_USER,
        }
    },
    {
        path: "/modules/edit/:id",
        name: "ModulesEdit",
        component: () =>
            import(/* webpackChunkName: "modules" */ "../views/administration/Modules/ModulesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.SUPER_USER,
        }
    },
    {
        path: "/modules/add",
        name: "ModulesInsert",
        component: () =>
            import(/* webpackChunkName: "modules" */ "../views/administration/Modules/ModulesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.SUPER_USER,
        },
    },
    {
        path: "/user-filters",
        name: "UserFilters",
        component: () =>
            import(/* webpackChunkName: "user-filters" */ "../views/administration/UserFilters/UserFilters.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.USERS_READ,
        }
    },
    {
        path: "/user-filters/edit/:id",
        name: "UserFiltersEdit",
        component: () =>
            import(/* webpackChunkName: "user-filters" */ "../views/administration/UserFilters/UserFiltersEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.USERS_WRITE,
        }
    },
    {
        path: "/user-filters/add",
        name: "UserFiltersInsert",
        component: () =>
            import(/* webpackChunkName: "user-filters" */ "../views/administration/UserFilters/UserFiltersEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.USERS_WRITE,
        },
    },

    {
        path: "/alerts",
        name: "Alerts",
        component: () =>
            import(/* webpackChunkName: "alerts" */ "../views/administration/Alerts/Alerts.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.ALERTS_READ,
        }
    },
    {
        path: "/alerts/edit/:id",
        name: "AlertsEdit",
        component: () =>
            import(/* webpackChunkName: "alerts" */ "../views/administration/Alerts/AlertsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.ALERTS_WRITE,
        }
    },
    {
        path: "/alerts/add",
        name: "AlertsInsert",
        component: () =>
            import(/* webpackChunkName: "alerts" */ "../views/administration/Alerts/AlertsEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.ALERTS_WRITE,
        },
    },

    {
        path: "/templates",
        name: "Templates",
        component: () =>
            import(/* webpackChunkName: "templates" */ "../views/administration/Templates/Templates.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_READ,
        }
    },
    {
        path: "/templates/edit/:id",
        name: "TemplatesEdit",
        component: () =>
            import(/* webpackChunkName: "templates" */ "../views/administration/Templates/TemplatesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        }
    },
    {
        path: "/templates/add",
        name: "TemplatesInsert",
        component: () =>
            import(/* webpackChunkName: "templates" */ "../views/administration/Templates/TemplatesEdit.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.DICTIONARIES_WRITE,
        },
    },

    {
        path: '/logs',
        name: 'Logs',
        component: () =>
            import(/* webpackChunkName: "logs" */ "../views/administration/Logs/Logs.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.LOGS_READ,
        }
    },
    {
        path: '/logs/view/:id',
        name: 'LogsView',
        component: () =>
            import(/* webpackChunkName: "logs" */ "../views/administration/Logs/LogView.vue"),
        meta: {
            viewMode: true,
            requiresAuth: true,
            requiresAccess: UserPermission.LOGS_READ,
        }
    },
    {
        path: '/maillogs',
        name: 'MailLogs',
        component: () =>
            import(/* webpackChunkName: "logs" */ "../views/administration/Logs/MailLogs.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.MAILLOGS_READ,
        }
    },
    {
        path: '/maillogs/view/:id',
        name: 'MailLogsView',
        component: () =>
            import(/* webpackChunkName: "logs" */ "../views/administration/Logs/MailLogView.vue"),
        meta: {
            requiresAuth: true,
            requiresAccess: UserPermission.MAILLOGS_READ,
        }
    },

    {
        path: "/zebra-status",
        name: "ZebraStatus",
        component: () =>
            import(/* webpackChunkName: "admin" */ "../views/administration/ZebraStatus.vue"),
    }
]